import React, { FC } from "react";
import {
  ListProps,
  Datagrid,
  TextField,
  FilterProps,
  Filter,
  SelectInput,
  usePermissions,
} from "react-admin";
import DateField from "../../fields/DateField";
import { getPermissions, Resources, PermissionStatus } from "../../permissions";
import List from "../List";

const ListFilter = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SelectInput
      source="resource"
      options={{ style: { minWidth: "12rem" } }}
      choices={Object.keys(Resources).map((key) => ({
        id: key,
        name: key,
      }))}
    />
  </Filter>
);

const AccessList: FC<ListProps> = (props) => {
  const { permissions } = usePermissions();
  const { canUpdate, canCreate } = getPermissions(
    permissions,
    Resources.Access,
  );

  return (
    <List
      {...props}
      filters={<ListFilter />}
      aside={
        <PermissionStatus
          canCreate={canCreate}
          canUpdate={canUpdate}
          resourceNamePlural="permissions"
          resourceName="permission"
        />
      }
    >
      <Datagrid rowClick={canUpdate ? "edit" : ""}>
        <DateField source="updated" />
        <TextField source="type" />
        <TextField source="resource" />
        <TextField source="role" />
      </Datagrid>
    </List>
  );
};

export default AccessList;
