import React, { Component, useState } from "react";

import { useGetManyReference } from "react-admin";
import { Alert, AlertTitle } from "@mui/material";

import AuditTimelineLoaded from "./AuditTimelineLoaded";
import AuditTimelineLoading from "./AuditTimelineLoading";
import AuditTimelineEmpty from "./AuditTimelineEmpty";
import AuditEvent from "./AuditEvent";

interface ViewProps {
  items?: AuditEvent[];
  page: number;
  setPage: (page: number) => void;
  totalItems?: number;
  loadedOnce: boolean;
  loading?: boolean;
  error?: any;
}

interface ViewState {
  events: AuditEvent[];
  latestId: string | null;
}

export class AuditTimelineView extends Component<ViewProps, ViewState> {
  state = {
    events: [],
    latestId: null,
  };

  componentDidMount() {
    this.setState({ events: this.props.items || [] });
  }

  static getDerivedStateFromProps(props: ViewProps, state: ViewState) {
    const { items } = props;
    if (items && items.length > 0) {
      const latestId = items[items.length - 1].id;
      if (latestId !== state.latestId) {
        return {
          events: state.events.concat(...items),
          latestId,
        };
      }
    }

    return null;
  }

  handleLoadMore = () => {
    this.props.setPage(this.props.page + 1);
  };

  render() {
    const { events } = this.state;
    const { totalItems, loadedOnce, error, loading } = this.props;
    if (error) {
      return (
        <Alert severity="error">
          <AlertTitle>Failed to load audit trail</AlertTitle>
          {error.toString()}
        </Alert>
      );
    }
    return !loadedOnce ? (
      <AuditTimelineLoading />
    ) : events.length > 0 ? (
      <AuditTimelineLoaded
        events={events}
        total={totalItems}
        loading={loading}
        handleLoadMore={this.handleLoadMore}
      />
    ) : (
      <AuditTimelineEmpty />
    );
  }
}

interface AuditTimelineProps {
  reference: string;
  target: any;
}

const AuditTimeline = (props: AuditTimelineProps) => {
  const [page, setPage] = useState<number>();
  const [loadedOnce, setLoadedOnce] = useState<boolean>();
  const { data, total, isLoading, error } = useGetManyReference(
    "UnconfiguredAuditTrail",
    {
      target: props.reference,
      id: props.target,
      pagination: { page: page || 1, perPage: 10 },
      sort: { field: "created", order: "DESC" },
    },
  );

  if (!loadedOnce && !isLoading) {
    setLoadedOnce(true);
  }

  return (
    <AuditTimelineView
      items={data && Object.values(data)}
      setPage={setPage}
      page={page || 1}
      totalItems={total}
      loading={isLoading}
      loadedOnce={loadedOnce || false}
      error={error}
    />
  );
};

export default AuditTimeline;
