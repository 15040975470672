import React, { FC } from "react";
import {
  Edit,
  EditProps,
  SimpleForm,
  // useGetOne,
  useRecordContext,
} from "react-admin";
import { OrgIntegrationTitleProps } from "./types";
import FormContents from "./FormContents";
import ChangeCommentToolbar from "../../common/ChangeCommentToolbar";

const OrgIntegrationTitle: FC<OrgIntegrationTitleProps> = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <span>
      {record.name} on {record.integrationTypeName}
    </span>
  );
};

const OrgIntegrationEdit: FC<EditProps> = (props) => {
  return (
    <Edit redirect="edit" {...props} title={<OrgIntegrationTitle />}>
      <SimpleForm
        toolbar={
          <ChangeCommentToolbar create={false} label="Customer Integration" />
        }
        warnWhenUnsavedChanges
      >
        <FormContents create={false} />
      </SimpleForm>
    </Edit>
  );
};

export default OrgIntegrationEdit;
