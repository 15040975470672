import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { Auth } from "@aws-amplify/auth";

import fragmentMatcher from "./fragmentMatcher";

export type Client = AWSAppSyncClient<NormalizedCacheObject>;

export default (): Client =>
  new AWSAppSyncClient({
    url: window.awsParameters.graphqlUrl,
    region: window.awsParameters.region,
    disableOffline: true,
    cacheOptions: {
      fragmentMatcher,
      dataIdFromObject: function () {
        // disable caching
        return null;
      },
    },
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: (): Promise<string> =>
        Auth.currentSession()
          .then((session) => session.getAccessToken().getJwtToken())
          .catch(() => ""),
    },
  });
