import React from "react";
import { TopToolbar, ListButton } from "react-admin";

const EditActions = (props: any) => (
  <TopToolbar className={props.className}>
    {props.hasList && <ListButton resource={props.basePath} />}
  </TopToolbar>
);

export default EditActions;
