import { ValidateResult } from "react-hook-form";
import { Schema, Field, Rule, MaybeEmptyPartial } from "../types";

function isEmpty(value: string | null | undefined): boolean {
  return value === "" || value === undefined || value === null;
}

function fieldError(fields: MaybeEmptyPartial<Field>[]): boolean {
  return (
    fields.some((field: MaybeEmptyPartial<Field>) => isEmpty(field.source)) ||
    fields.some((field: MaybeEmptyPartial<Field>) =>
      isEmpty(field.destinationFieldName),
    )
  );
}
function ruleError(rules: MaybeEmptyPartial<Rule>[]): boolean {
  return (
    rules.some((rule: MaybeEmptyPartial<Rule>) => isEmpty(rule.fieldName)) ||
    rules.some((rule: MaybeEmptyPartial<Rule>) =>
      isEmpty(rule.comparisonOperator),
    )
  );
}
export default function validateSchemaEditor(
  schemas: Schema[],
): ValidateResult {
  // Validate precedence is unique
  if (
    new Set(schemas.map((schema: Schema) => schema.precedence)).size !==
    schemas.length
  ) {
    return "Each schema must have a unique precedence value.";
  }
  // Validate required field and rule inputs
  if (
    !schemas
      .map((schema: Schema) => schema.precedence)
      .every((precedence) => !isNaN(precedence) && precedence !== null) ||
    !schemas
      .map((schema: Schema) => schema.name)
      .every((name) => !!name && name !== "") ||
    fieldError(
      ([] as Field[]).concat(
        ...schemas.map((schema: Schema) => schema.fields ?? []),
      ),
    ) ||
    ruleError(
      ([] as Rule[]).concat(
        ...schemas.map((schema: Schema) => schema.rules ?? []),
      ),
    )
  ) {
    return "Required field missing.";
  }

  return false;
}
