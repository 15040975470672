import React from "react";
import MuiAvatar from "@mui/material/Avatar";
import { withStyles, WithStyles } from "@mui/styles";
import md5 from "blueimp-md5";

import { DashboardUser as User } from "@advarra/connect-graphql/types";

const styles = {
  avatar: {
    width: 25,
    height: 25,
  },
};

interface Props extends WithStyles<typeof styles> {
  user?: User;
}

export const AvatarView = ({ user, classes }: Props): JSX.Element => (
  <MuiAvatar
    className={classes.avatar}
    alt={user ? user.username : "unknown"}
    src={
      user && user.email
        ? `https://www.gravatar.com/avatar/${md5(user.email)}?d=retro`
        : undefined
    }
  >
    {user ? user.username[0].toUpperCase() : undefined}
  </MuiAvatar>
);

const Avatar = withStyles(styles)(AvatarView);

export default Avatar;
