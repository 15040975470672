import React, {
  FC,
  useCallback,
  Fragment,
  useState,
  useEffect,
  isValidElement,
  cloneElement,
  createElement,
} from "react";
import classnames from "classnames";
import {
  ArrayField,
  NumberField,
  Datagrid,
  TextField,
  useListContext,
  RecordContextProvider,
  useRecordContext,
} from "react-admin";
import {
  DatagridProps,
  DatagridRowProps,
  DatagridBodyProps,
} from "ra-ui-materialui";
import { TableRow, TableCell, Button } from "@mui/material";
import DateField from "../../fields/DateField";
import { SectionTitle } from "../OrgIntegration/Create";
import { ExpandRowButton } from "react-admin";
import { DatagridCell } from "react-admin";
import { DatagridBody } from "react-admin";
import * as API from "@advarra/connect-graphql/lib/types";
import { MappingSchema } from "@advarra/connect-graphql/types";

const RecordCountField = (props: any): JSX.Element | null => {
  const record = useRecordContext();
  if (!record) {
    return null;
  }
  return <span>{(record[props.source] || []).length}</span>;
};

// much copied from react-admin here
const computeNbColumns = (
  expand: boolean,
  children: React.ReactNode,
  hasBulkActions: boolean,
) =>
  expand
    ? 1 + // show expand button
      (hasBulkActions ? 1 : 0) + // checkbox column
      React.Children.toArray(children).filter((child) => !!child).length // non-null children
    : 0; // we don't need to compute columns if there is no expand panel;

interface CustomExpandProps {
  onExpanded: (id: string | number | undefined) => void;
  expanded: { [key: string]: boolean };
}

export const MyDatagridRow: FC<DatagridRowProps & CustomExpandProps> = ({
  record,
  resource,
  style,
  id,
  onExpanded,
  expanded,
  hasBulkActions,
  expand,
  children,
}) => {
  const [nbColumns, setNbColumns] = useState(
    computeNbColumns(!!expand, children, !!hasBulkActions),
  );
  useEffect(() => {
    // Fields can be hidden dynamically based on permissions;
    // The expand panel must span over the remaining columns
    // So we must recompute the number of columns to span on
    const newNbColumns = computeNbColumns(!!expand, children, !!hasBulkActions);
    if (newNbColumns !== nbColumns) {
      setNbColumns(newNbColumns);
    }
  }, [expand, nbColumns, children, hasBulkActions]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (onExpanded) onExpanded(id);
      event.stopPropagation();
    },
    [id, onExpanded],
  );
  return (
    <RecordContextProvider value={record}>
      <TableRow key={id}>
        <TableCell padding="none" className={style?.expandIconCell}>
          <ExpandRowButton
            classes={style}
            expanded={record?.id ? expanded[record?.id] : false}
            onClick={handleClick}
            expandContentId={`${id}-expand`}
          />
        </TableCell>
        {React.Children.map(children, (field, index) =>
          isValidElement(field) ? (
            <DatagridCell
              key={`${id}-${(field.props as any).source || index}`}
              className={classnames(`column-${(field.props as any).source}`)}
              {...{ field, resource }}
            />
          ) : null,
        )}
      </TableRow>
      {expand && record?.id && expanded[record?.id] && (
        <TableRow key={`${id}-expand`} id={`${id}-expand`}>
          <TableCell colSpan={nbColumns}>
            {isValidElement(expand)
              ? cloneElement(expand, {
                  // @ts-ignore
                  record,
                  resource,
                  id: String(id),
                })
              : // @ts-ignore
                createElement(expand, {
                  // @ts-ignore
                  record,
                  // @ts-ignore
                  resource,
                  id: String(id),
                })}
          </TableCell>
        </TableRow>
      )}
    </RecordContextProvider>
  );
};

const MyDatagridBody: FC<DatagridBodyProps & CustomExpandProps> = ({
  onExpanded,
  expanded,
  ...props
}) => {
  return (
    <DatagridBody
      {...props}
      row={<MyDatagridRow onExpanded={onExpanded} expanded={expanded} />}
    />
  );
};

export const AttributeMappingDatagrid: FC<DatagridProps> = ({ ...props }) => {
  const { data } = useListContext();
  const ids = data.map(({ id }) => id);

  const [currentSort, setSort] = useState<{
    field: keyof API.MappingSchema;
    order: "ASC" | "DESC";
  }>({
    field: "precedence",
    order: "ASC",
  });

  const [expanded, updateExpanded] = useState<{ [key: string]: boolean }>({});

  const handleExpanded = useCallback(
    (id: string | number | undefined) => {
      if (id !== undefined) {
        updateExpanded((e) => ({ ...e, [id]: !e[id] }));
      }
    },
    [updateExpanded],
  );

  const handleExpandAll = useCallback(() => {
    updateExpanded((e) => {
      const u = { ...e };

      data.forEach(({ id }) => {
        u[id] = true;
      });
      return u;
    });
  }, [updateExpanded, ids]);

  const handleCollapseAll = useCallback(() => {
    updateExpanded(() => ({}));
  }, [updateExpanded]);

  const sortedIds = getIdsBySort(ids, data, currentSort);

  return (
    <Fragment>
      <Button color="secondary" onClick={handleCollapseAll}>
        collapse all
      </Button>
      <Button color="secondary" onClick={handleExpandAll}>
        expand all
      </Button>
      <Datagrid
        {...props}
        bulkActionButtons={false}
        selectedIds={sortedIds}
        sort={currentSort}
        setSort={({ field, order }) => {
          return setSort({
            field: field as keyof MappingSchema,
            order: order as "ASC" | "DESC",
          });
        }}
        body={
          <MyDatagridBody onExpanded={handleExpanded} expanded={expanded} />
        }
      />
    </Fragment>
  );
};

export const SchemaDetails = (props: any): JSX.Element => (
  <div
    style={{
      padding: "1rem",
      marginLeft: "4rem",
    }}
  >
    <SectionTitle label="Rules" />
    <ArrayField {...props} source="rules">
      <Datagrid bulkActionButtons={false} data-testid="rules">
        <TextField source="fieldName" />
        <TextField source="comparisonOperator" />
        <TextField source="value" />
        <DateField source="created" />
      </Datagrid>
    </ArrayField>

    <br />

    <SectionTitle label="Fields" />
    <ArrayField {...props} source="fields">
      <Datagrid bulkActionButtons={false} data-testid="fields">
        <TextField source="source" />
        <TextField source="sourceType" />
        <TextField source="destinationFieldName" />
        <DateField source="created" />
      </Datagrid>
    </ArrayField>
  </div>
);

const AttributesViewer: FC<DatagridRowProps> = (props) => {
  return (
    <ArrayField {...props} source="schemas">
      <AttributeMappingDatagrid expand={<SchemaDetails />}>
        <NumberField source="precedence" />
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="type" />
        <RecordCountField source="rules" />
        <RecordCountField source="fields" />
      </AttributeMappingDatagrid>
    </ArrayField>
  );
};

export default AttributesViewer;

export function getIdsBySort(
  ids: (string | number)[] | undefined,
  data: API.MappingSchema[] | undefined,
  currentSort: { field: keyof API.MappingSchema; order: "ASC" | "DESC" },
) {
  let sortedIds = ids;

  if (data) {
    const rs = data;

    rs.sort((a: API.MappingSchema, b: API.MappingSchema): number => {
      if ((a[currentSort.field] || 0) < (b[currentSort.field] || 0)) {
        return currentSort.order === "ASC" ? -1 : 1;
      }
      if (a[currentSort.field] === b[currentSort.field]) {
        return 0;
      }
      return currentSort.order === "ASC" ? 1 : -1;
    });

    sortedIds = rs.map((r: API.MappingSchema) => r.id);
  }
  return sortedIds;
}
