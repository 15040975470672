import React, { FC } from "react";
import {
  TextField,
  Datagrid,
  ReferenceField,
  Filter,
  TextInput,
  SelectInput,
  FilterProps,
  ListProps,
  NumberField,
  FunctionField,
  usePermissions,
} from "react-admin";
import { Card as MuiCard, CardContent } from "@mui/material";
import { withStyles } from "@mui/styles";

import * as API from "@advarra/connect-graphql/lib/types";

import DateField from "../../fields/DateField";
import { getPermissions, Resources, PermissionStatus } from "../../permissions";
import List from "../List";
import BetterDateTimeInput from "../../inputs/BetterDateTimeInput";

import {
  CreatedFilter,
  EnvironmentExcludeFilter,
  ResolvedFilter,
  StatusFilter,
  UpdatedFilter,
} from "./filters";
import CustomerIntegrationInput from "../../common/CustomerIntegrationInput";
import SupportUserInput from "../../common/SupportUserInput";
import TicketLinkField from "./TicketLinkField";

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      marginRight: "1em",
      flexShrink: 0,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

const FilterSidebar: FC<{ canUpdate: boolean }> = ({ canUpdate }) => (
  <>
    <Card>
      <CardContent>
        <PermissionStatus
          canUpdate={canUpdate}
          canCreate={true}
          resourceNamePlural="errors"
          resourceName="error"
        />
      </CardContent>
    </Card>
    <Card>
      <CardContent>
        <CreatedFilter />
        <UpdatedFilter />
        <ResolvedFilter />
        <StatusFilter />
        <EnvironmentExcludeFilter />
      </CardContent>
    </Card>
  </>
);

const convertStringToNumber = (value: string) => {
  const float = parseFloat(value);
  return isNaN(float) ? null : float;
};

export const ListFilter = (
  props: Omit<FilterProps, "children">,
): JSX.Element => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <BetterDateTimeInput source="created_gte" label="Created on or after" />
    <BetterDateTimeInput source="created_lte" label="Created on or before" />
    <BetterDateTimeInput source="updated_gte" label="Updated on or after" />
    <BetterDateTimeInput source="updated_lte" label="Updated on or before" />
    <BetterDateTimeInput source="resolved_gte" label="Resolved on or after" />
    <BetterDateTimeInput source="resolved_lte" label="Resolved on or before" />
    <TextInput
      source="ticketNumber"
      type="number"
      parse={convertStringToNumber}
    />

    <SupportUserInput source="assignee" reference="User" />
    <SelectInput
      source="status"
      choices={[
        { id: "NEW", name: "NEW" },
        { id: "INVESTIGATING", name: "INVESTIGATING" },
        { id: "RESOLVED", name: "RESOLVED" },
        { id: "DO NOT FIX", name: "DO NOT FIX" },
        { id: "ON HOLD", name: "ON HOLD" },
      ]}
    />
    <SelectInput
      source="category"
      choices={[
        { id: "SERVICE", name: "SERVICE" },
        { id: "PARTNER_API", name: "PARTNER_API" },
        { id: "AWS_INVOCATION", name: "AWS_INVOCATION" },
        { id: "CODE", name: "CODE" },
        { id: "TRANSACTION", name: "TRANSACTION" },
        { id: "UNKNOWN", name: "UNKNOWN" },
      ]}
    />
    <TextInput source="message" />
    <TextInput source="message_not" />
    <CustomerIntegrationInput
      label="Customer Integration"
      source="customerIntegrationId"
      reference="OrgIntegration"
    />
    <CustomerIntegrationInput
      label="Exclude Customer Integration"
      source="customerIntegrationId_not"
      reference="OrgIntegration"
    />
    <SelectInput
      choices={[
        { id: "production", name: "Production" },
        { id: "test", name: "Test" },
      ]}
      label="Environment"
      source="environment"
    />
    <TextInput source="integrationId" label="Connector Type" />
    <TextInput source="transactionId" />
    <TextInput source="documentId" label="DOC_ID" />
    <TextInput source="proId" label="PRO_ID" />
    <TextInput source="ssuId" label="SSU_ID" />
  </Filter>
);

const ErrorList: FC<ListProps> = (props) => {
  const { permissions } = usePermissions();
  const { canUpdate } = getPermissions(permissions, Resources.Error);

  return (
    <List
      {...props}
      title="Errors"
      filters={<ListFilter />}
      bulkActionButtons={false}
      sort={{ field: "created", order: "DESC" }}
      aside={<FilterSidebar canUpdate={canUpdate} />}
    >
      <Datagrid rowClick={canUpdate ? "edit" : ""} bulkActionButtons={false}>
        <TextField source="id" sortable={true} label="ID" />

        <TextField source="assignee" sortable={false} />
        <TicketLinkField source="ticketNumber" sortable={false} />

        <DateField source="created" sortable={true} />
        <DateField source="updated" sortable={true} />
        <DateField source="resolved" sortable={true} />
        <TextField source="status" sortable={false} />
        <TextField source="category" sortable={false} />
        <TextField source="message" sortable={false} />
        <ReferenceField
          sortable={false}
          label="Customer Integration"
          source="customerIntegrationId"
          reference="OrgIntegration"
        >
          <FunctionField<API.OrgIntegration>
            render={(record) =>
              record ? (
                <span>
                  {record.name} ({record.integrationTypeId})
                </span>
              ) : null
            }
          />
        </ReferenceField>

        <TextField source="transactionId" sortable={false} />

        <TextField source="documentId" label="DOC_ID" sortable={false} />
        <TextField source="proId" label="PRO_ID" sortable={false} />
        <TextField source="ssuId" label="SSU_ID" sortable={false} />

        <NumberField source="commentCount" sortable={false} />
        <FunctionField<API.Error>
          source="reoccuranceCount"
          render={(record) => (record?.reoccuranceCount || 0) + 1}
          label="Count"
          title="Number of times this error has occurred"
          sortable={true}
        />
      </Datagrid>
    </List>
  );
};

export default ErrorList;
