import React from "react";
import Paper from "@mui/material/Paper";

import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles<Theme>({
  table: {
    maxWidth: 700,
  },
});

export default function UpdatePINameCustomerCTMSConfirmationDialog({
  from,
  to,
  onConfirmation,
}: {
  from: string[];
  to: string;
  onConfirmation: () => void;
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    onConfirmation();
  };

  const classes = useStyles();

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Update PI Name
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Update PI Names?</DialogTitle>
        {from.length > 0 && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to make the following changes?
            </DialogContentText>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {from.map((fromName: string, index: number) => (
                    <TableRow key={fromName}>
                      <TableCell align="center">{fromName}</TableCell>
                      {index === 0 && (
                        <TableCell align="center" rowSpan={from.length}>
                          {to}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        )}
        {from.length === 0 && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <strong>
                Please select one or more CTMS records to update before clicking
                the "Update PI Name" button.
              </strong>
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          {from.length > 0 && (
            <Button
              onClick={handleConfirm}
              variant="outlined"
              color="primary"
              autoFocus
            >
              Confirm
            </Button>
          )}
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
