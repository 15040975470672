import React, { FC } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { withStyles, WithStyles } from "@mui/styles";

const styles = {
  table: {},
  tableHeader: {
    minWidth: "20%",
    "font-weight": "bold",
    "border-bottom": "none",
  },
  tableCell: { minWidth: "40%", "border-bottom": "none" },
};

interface Props extends WithStyles<typeof styles> {
  before: { [key: string]: any };
  after: { [key: string]: any };
  header: boolean;
  dense: boolean;
}

export const BulletLines: FC<{ value: string }> = ({ value }) =>
  value.indexOf ? (
    value.indexOf("\n") === -1 ? (
      <span>{value}</span>
    ) : (
      <ul>
        {value.split("\n").map((line, index) => (
          <li key={index}>{line}</li>
        ))}
      </ul>
    )
  ) : (
    <div />
  );

export const DifferencesListView: FC<Props> = ({
  classes,
  before,
  after,
  header,
  dense,
}) => {
  const keys = Array.from(
    new Set([...Object.keys(before || {}), ...Object.keys(after || {})]),
  )
    // ignore if both are falsey
    .filter((key) => before[key] || after[key])
    .filter((key) => !["__typename", "updated", "created"].includes(key));

  const noBefore = !keys.some((k) => before[k] !== null);

  return (
    <Table className={classes.table} size="small">
      {header && (
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Before</TableCell>
            <TableCell>After</TableCell>
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {keys.map((key) => (
          <TableRow key={key}>
            <TableCell
              // component="th"
              scope="row"
              className={classes.tableHeader}
            >
              {key}
            </TableCell>
            {!(dense && noBefore) && (
              <TableCell className={classes.tableCell}>
                {before[key] ? (
                  <BulletLines value={before[key]} />
                ) : (
                  <strong>Not set</strong>
                )}
              </TableCell>
            )}
            <TableCell className={classes.tableCell}>
              {after[key] ? (
                <BulletLines value={after[key]} />
              ) : (
                <strong>Not set</strong>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const DifferencesList = withStyles(styles)(DifferencesListView);

export default DifferencesList;
