import React, { FC } from "react";
import {
  Edit,
  EditProps,
  SimpleForm,
  TextField,
  required,
  TextInput,
  BooleanInput,
  useGetIdentity,
  TopToolbar,
  EditActionsProps,
  ReferenceInput,
  SelectInput,
  useRecordContext,
} from "react-admin";
import { useFormContext, useFormState } from "react-hook-form";

import { Button } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PublishIcon from "@mui/icons-material/Publish";

import AttributesEditor from "./SchemasEditor";
import ImportConfirmationDialog from "./ImportConfirmationDialog";
import { exportArtifact, importArtifact } from "./exportArtifact";
import ChangeCommentToolbar from "../../common/ChangeCommentToolbar";
import Labeled from "../../common/Labeled";

// TODO I haven't figured out how to use this as part of the <Edit actions={<MappingEditActions />}></Edit>
// without it complaining about not being able to useForm() since it's not inside of the SimpleForm.
// Instead, I have to put this inside of the form, which means these buttons don't appear in the
// actions area.
const MappingEditActions: FC<any> = (props) => {
  const [showImportDialog, setShowImportDialog] = React.useState(false);
  const { identity, isLoading: identityLoading } = useGetIdentity();

  const { setValue } = useFormContext();
  const { isDirty } = useFormState();
  const record = useRecordContext();

  const handleImportClick = (): void => setShowImportDialog(true);
  const handleCloseImportDialog = (): void => setShowImportDialog(false);
  const handleImport = (file: File): void => {
    file
      .text()
      .then((text) => setValue("schemas", importArtifact(text)))
      .then(() => {
        setShowImportDialog(false);
      })
      .catch((error) => {
        console.error("Failed to parse import file", error);
        alert(
          "Could not import uploaded file. Check that you have selected a file that was exported from the dashboard.",
        );
      });
  };

  const handleExportClick = (): void => {
    if (!record) {
      return;
    }

    const artifact = new Blob(
      [exportArtifact(record.schemas, identity?.username || "<unknown>")],
      {
        type: "text/html",
        endings: "native",
      },
    );
    const url = URL.createObjectURL(artifact);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", "export.html");
    tempLink.click();
  };

  return (
    <TopToolbar className={props.className}>
      <ImportConfirmationDialog
        open={showImportDialog}
        onClose={handleCloseImportDialog}
        onImport={handleImport}
      />
      <Button
        variant="contained"
        startIcon={<PublishIcon />}
        onClick={handleImportClick}
      >
        Import ...
      </Button>
      {!identityLoading && (
        <Button
          variant="contained"
          disabled={isDirty}
          title={
            isDirty
              ? "Cannot export if there are unsaved changes."
              : "Export attribute mapping to HTML"
          }
          startIcon={<CloudDownloadIcon />}
          onClick={handleExportClick}
        >
          Export
        </Button>
      )}
    </TopToolbar>
  );
};

const Actions: FC<EditActionsProps> = () => <div />;

const MappingEdit: FC<EditProps> = (props) => {
  return (
    <Edit {...props} actions={<Actions />}>
      <SimpleForm
        reValidateMode="onChange"
        toolbar={<ChangeCommentToolbar create={false} label="Mapping" />}
      >
        <MappingEditActions />

        <Labeled>
          <TextField source="id" />
        </Labeled>

        <TextInput source="name" validate={required()} />
        <TextInput source="description" multiline />
        <BooleanInput source="reference" />

        <ReferenceInput source="integrationType.id" reference="IntegrationType">
          <SelectInput
            label="Integration Type"
            optionText="name"
            helperText=""
            style={{ minWidth: "13em" }}
          />
        </ReferenceInput>

        <AttributesEditor name="schemas" />
      </SimpleForm>
    </Edit>
  );
};

export default MappingEdit;
