import React, { ChangeEvent, FC } from "react";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";

import * as API from "@advarra/connect-graphql/lib/types";

import { MaybeEmptyPartial, Rule, SchemaChildType } from "../types";

import SchemaElementActions from "./SchemaElementActions";
import useStyles from "./useStyles";
import fieldOptions from "./fieldOptions";

interface RuleEditorProps {
  rule: MaybeEmptyPartial<Rule>;
  schemaIndex: number;
  index: number;
  onClone: (schemaIndex: number, type: SchemaChildType, index: number) => void;
  onChange: (
    schemaIndex: number,
    type: SchemaChildType,
    index: number,
    fieldName: string,
    value: string,
  ) => void;
  onRemove: (schemaIndex: number, type: SchemaChildType, index: number) => void;
}

const RuleEditor: FC<RuleEditorProps> = ({
  rule,
  schemaIndex,
  index,
  onClone,
  onChange,
  onRemove,
  ...props
}) => {
  const classes = useStyles(props);
  return (
    <div className={classes.rule}>
      <Autocomplete
        freeSolo
        options={fieldOptions}
        value={rule.fieldName}
        className={classes.autoComplete}
        renderInput={({ inputProps, ...params }): JSX.Element => (
          <TextField
            autoComplete="off"
            className={classes.textInput}
            name="fieldName"
            required
            error={rule.fieldName === ""}
            helperText={rule.fieldName === "" ? "Must enter a field name" : " "}
            variant="filled"
            label="Field Name"
            inputProps={{ ...inputProps, maxLength: 255 }}
            {...params}
            style={{ width: "15em" }}
            fullWidth={false}
          />
        )}
        onChange={(
          event: ChangeEvent<unknown>,
          newValue: string | null,
        ): void =>
          onChange(schemaIndex, "rule", index, "fieldName", newValue ?? "")
        }
        onInputChange={(event: ChangeEvent<unknown>, newValue: string): void =>
          onChange(schemaIndex, "rule", index, "fieldName", newValue)
        }
      />
      <FormControl className={classes.selectInput}>
        <InputLabel variant="filled" shrink>
          Comparison Operator
        </InputLabel>
        <Select
          onChange={(event): void =>
            onChange(
              schemaIndex,
              "rule",
              index,
              "comparisonOperator",
              event.target.value as API.SchemaRuleComparisonOperator,
            )
          }
          name="comparisonOperator"
          label="Comparison Operator"
          value={rule.comparisonOperator}
          required
          error={rule.comparisonOperator === ""}
          variant="filled"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={API.SchemaRuleComparisonOperator.Equals}>=</MenuItem>
          <MenuItem value={API.SchemaRuleComparisonOperator.NotEquals}>
            !=
          </MenuItem>
          <MenuItem value={API.SchemaRuleComparisonOperator.Contains}>
            Contains
          </MenuItem>
          <MenuItem value={API.SchemaRuleComparisonOperator.NotContains}>
            Doesn't Contain
          </MenuItem>
          <MenuItem value={API.SchemaRuleComparisonOperator.GreaterThan}>
            &gt;
          </MenuItem>
          <MenuItem value={API.SchemaRuleComparisonOperator.LessThan}>
            &lt;
          </MenuItem>
          <MenuItem value={API.SchemaRuleComparisonOperator.LessThanOrEqual}>
            &le;
          </MenuItem>
          <MenuItem value={API.SchemaRuleComparisonOperator.GreaterThanOrEqual}>
            &ge;
          </MenuItem>
          <MenuItem value={API.SchemaRuleComparisonOperator.IsNull}>
            Null
          </MenuItem>
          <MenuItem value={API.SchemaRuleComparisonOperator.IsNotNull}>
            Not Null
          </MenuItem>
          <MenuItem value={API.SchemaRuleComparisonOperator.MatchesRegex}>
            Matches the Regex
          </MenuItem>
        </Select>
      </FormControl>
      <TextField
        className={classes.textInput}
        autoComplete="off"
        name="value"
        label="Value"
        value={rule.value}
        onChange={(event): void =>
          onChange(schemaIndex, "rule", index, "value", event.target.value)
        }
        variant="filled"
      />
      <SchemaElementActions
        type="rule"
        onClone={() => onClone(schemaIndex, "rule", index)}
        onRemove={() => onRemove(schemaIndex, "rule", index)}
      />
    </div>
  );
};

export default RuleEditor;
