import React, { FC } from "react";
import {
  Create,
  CreateProps,
  SimpleForm,
  SelectInput,
  AutocompleteInput,
  Toolbar,
  ToolbarProps,
  SaveButton,
  ListButton,
  required,
  TextInput,
} from "react-admin";
import Icon from "@mui/material/Icon";

import { PermissionType, Resources } from "../../permissions";

const CreateToolbar: FC<Omit<ToolbarProps, "width">> = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton label="Cancel" icon={<Icon />} />
  </Toolbar>
);

const AccessCreate: FC<CreateProps> = (props) => {
  const resourceOptions = [];
  for (const resource in Resources) {
    resourceOptions.push({
      id: Resources[resource as Resources],
      name: resource,
    });
  }

  return (
    <Create {...props}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <SelectInput
          validate={required()}
          source="type"
          choices={[
            { id: PermissionType.Create, name: "Create" },
            { id: PermissionType.List, name: "List" },
            { id: PermissionType.Update, name: "Update" },
          ]}
        />

        <AutocompleteInput
          source="resource"
          validate={required()}
          choices={resourceOptions}
        />

        <TextInput source="role" validate={required()} />
      </SimpleForm>
    </Create>
  );
};

export default AccessCreate;
