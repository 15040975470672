import React, { FC } from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import ConfirmButton from "../../../common/ConfirmButton";
import useStyles from "./useStyles";

interface SchemaElementActionsProps {
  onClone: () => void;
  onRemove: () => void;
  type: "schema" | "rule" | "field";
}

export const SchemaElementActionsInner: FC<
  SchemaElementActionsProps & { classes: Record<string, string> }
> = (props) => {
  return (
    <ButtonGroup color="primary" className={props.classes.action}>
      <Button
        title={"Clone " + props.type}
        onClick={props.onClone}
        startIcon={<FileCopyIcon />}
      ></Button>
      <ConfirmButton title={"Delete " + props.type} onClick={props.onRemove} />
    </ButtonGroup>
  );
};

const SchemaElementActions: FC<SchemaElementActionsProps> = (props) => {
  const classes = useStyles(props);
  return <SchemaElementActionsInner {...props} classes={classes} />;
};

export default SchemaElementActions;
