import React, { FC } from "react";
import MuiChip from "@mui/material/Chip";
import {
  ReferenceInput,
  AutocompleteInput,
  ReferenceArrayInputProps,
  useRecordContext,
} from "react-admin";
import * as API from "@advarra/connect-graphql/lib/types";
import Avatar from "./Avatar";

const OptionRenderer = () => {
  const record = useRecordContext<API.DashboardUser>();
  return (
    <MuiChip
      avatar={<Avatar user={record} />}
      label={record.username}
      variant="outlined"
    />
  );
};

const SupportUserInput: FC<ReferenceArrayInputProps> = ({
  label,
  ...props
}) => {
  return (
    <ReferenceInput label={label} {...props}>
      <AutocompleteInput
        sx={{ width: "20rem" }}
        label={label}
        optionText={<OptionRenderer />}
        filterToQuery={(searchText: string) => ({ name: searchText })}
        inputText={(record) => record.username}
        matchSuggestion={(filter, choice) =>
          choice.username.toLowerCase().includes(filter.toLowerCase())
        }
      />
    </ReferenceInput>
  );
};

export default SupportUserInput;
