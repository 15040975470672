import React from "react";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { withStyles, WithStyles } from "@mui/styles";

import Avatar from "../../common/Avatar";

const placeHolderStyles = {
  root: {
    width: 400,
    backgroundColor: "#cfcfcf",
    display: "block",
  },
};

const PlaceHolder = withStyles(placeHolderStyles)(
  ({ classes }: WithStyles<typeof placeHolderStyles>) => (
    <span className={classes.root}>&nbsp;</span>
  ),
);

const styles = {
  root: {
    margin: "auto",
  },
  card: {
    marginTop: "0.7em",
    marginBottom: "1em",
  },
  day: {},
};

const times = (nbChildren: number, fn: (arg0: number) => any) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

type Props = WithStyles<typeof styles>;

const AuditTimelineLoadingView = ({ classes }: Props) => (
  <div className={classes.root}>
    <div className={classes.day}>
      {times(2, (key1) => (
        <div key={key1}>
          <PlaceHolder />
          <Card className={classes.card}>
            <List>
              {times(5, (key2) => (
                <ListItem key={key2}>
                  <ListItemAvatar>
                    <Avatar />
                  </ListItemAvatar>
                  <ListItemText
                    primary={<PlaceHolder />}
                    secondary={<PlaceHolder />}
                  />
                </ListItem>
              ))}
            </List>
          </Card>
        </div>
      ))}
    </div>
  </div>
);

const AuditTimelineLoading = withStyles(styles)(AuditTimelineLoadingView);

export default AuditTimelineLoading;
