import React, { cloneElement, FC } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  ListProps,
  TextField,
  Datagrid,
  BooleanField,
  Filter,
  ReferenceInput,
  SelectInput,
  SelectArrayInput,
  TopToolbar,
  CreateButton,
  ExportButton,
  useListContext,
  sanitizeListRestProps,
  usePermissions,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from "react-admin";

import { METADATA_AUTOMATION_TYPES } from "@advarra/connect-constants";
import * as API from "@advarra/connect-graphql/lib/types";

import { getPermissions, Resources, PermissionStatus } from "../../permissions";
import ActiveStatusInput from "../../common/ActiveStatusInput";
import CustomerIntegrationInput from "../../common/CustomerIntegrationInput";
import List from "../List";

export const ListFilter = (props: any): JSX.Element => (
  <Filter {...props}>
    <ActiveStatusInput
      label="Is Active"
      source="isActive"
      alwaysOn
      trueLabel="Active"
      falseLabel="Inactive"
    />
    <CustomerIntegrationInput
      label="Customer Integration"
      source="id"
      reference="OrgIntegration"
    />

    <ReferenceArrayInput
      source="customerMapId"
      reference="Mapping"
      label="Mapping"
      sort={{
        field: "name",
        order: "ASC",
      }}
    >
      <AutocompleteArrayInput
        label="Mapping"
        filterToQuery={(searchText: string) => ({
          name: searchText,
        })}
        optionText={(record: API.CustomerMap) => {
          return record.name;
        }}
      />
    </ReferenceArrayInput>
    <ReferenceInput
      source="integrationTypeId"
      reference="IntegrationType"
      label="Integration Type"
    >
      <SelectInput label="Integration Type" optionText="name" helperText="" />
    </ReferenceInput>
    <SelectArrayInput
      label="Automation Type"
      source="metadataTypeId"
      choices={METADATA_AUTOMATION_TYPES.filter((v) => v.id !== "manual")}
    />
    <SelectInput
      choices={[
        { id: "production", name: "Production" },
        { id: "test", name: "Test" },
      ]}
      label="Environment"
      source="environment"
    />
  </Filter>
);

const ListActions = (props: any) => {
  const { canCreate, className, filters, maxResults, ...rest } = props;
  const { sort, resource, displayedFilters, filterValues, showFilter, total } =
    useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={sort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      <CreateButton
        // resource={basePath}
        disabled={!canCreate}
        icon={<AddIcon />}
        variant="contained"
        color="primary"
        label="Integration"
      />
    </TopToolbar>
  );
};

const OrgIntegrationList: FC<ListProps> = (props) => {
  const { permissions } = usePermissions();
  const { canCreate, canUpdate } = getPermissions(
    permissions,
    Resources.CustomerIntegration,
  );

  return (
    <List
      {...props}
      title="Customer Integrations"
      actions={<ListActions canCreate={canCreate} />}
      aside={
        <PermissionStatus
          canCreate={canCreate}
          canUpdate={canUpdate}
          resourceNamePlural="customer integrations"
          resourceName="customer integration"
        />
      }
      filters={<ListFilter />}
      filterDefaultValues={{ isActive: true }}
    >
      <Datagrid rowClick={canUpdate ? "edit" : ""} bulkActionButtons={false}>
        <TextField source="name" sortable={true} />
        <TextField source="environment" sortable={true} />
        <TextField source="integrationTypeName" sortable={true} />
        <TextField
          source="metadataTypeId"
          label="Automation type"
          sortable={true}
          emptyText="Manual"
        />
        <BooleanField source="isActive" sortable={true} />
      </Datagrid>
    </List>
  );
};

export default OrgIntegrationList;
