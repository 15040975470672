// much from https://github.com/vascofg/react-admin-date-inputs/blob/master/src/index.js
import React, { useCallback } from "react";
import { FieldTitle, useInput } from "react-admin";
import { DateTimePicker } from "@mui/x-date-pickers";

const BetterDateTimeInput = ({ ...fieldProps }) => {
  const { options, label, source, resource, className, helperText } =
    fieldProps;

  const {
    field,
    fieldState: { isTouched, error },
    isRequired,
  } = useInput({ source });

  const handleChange = useCallback(
    (value: any) => {
      Date.parse(value)
        ? field.onChange(value.toISOString())
        : field.onChange(null);
    },
    [field],
  );

  return (
    <div className="picker">
      <DateTimePicker
        {...options}
        label={
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        }
        margin="normal"
        error={!!(isTouched && error)}
        helperText={isTouched && error ? `${error} ${helperText}` : helperText}
        className={className}
        value={field.value ? new Date(field.value) : null}
        onChange={(date) => handleChange(date)}
        onBlur={() => field.onBlur()}
      />
    </div>
  );
};

export default BetterDateTimeInput;
