import { UpdateParams } from "ra-core";

export default function buildUpdateInputs<T extends { id: string }>(
  params: UpdateParams,
): { input: T } {
  const input: T = {} as T;
  Object.keys(params.data).forEach((field) => {
    if (
      field !== "id" &&
      params.data.hasOwnProperty(field) &&
      (!params.previousData ||
        params.data[field] !== params.previousData[field])
    ) {
      input[field as keyof T] = params.data[field];
    }
  });
  input.id = params.id as string;
  return { input };
}
