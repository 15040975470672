import React, { FC, MouseEvent, useState } from "react";

import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/RemoveCircleOutline";
import { Theme } from "@mui/material/styles";

interface ConfirmRemoveButtonProps {
  onClick: (event: MouseEvent) => void;
  title?: string;
}

const useStyles = makeStyles<Theme>((theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ConfirmButton: FC<ConfirmRemoveButtonProps> = ({ onClick, ...props }) => {
  const classes = useStyles(props);

  const [confirm, setConfirm] = useState(false);
  const onHandleClick = (event: MouseEvent): void => {
    if (confirm) {
      onClick(event);
    } else {
      setConfirm(true);
    }
  };
  const onHandleCancel = (): void => {
    setConfirm(false);
  };

  return (
    <>
      {confirm && (
        <Button size="small" {...props} onClick={onHandleCancel}>
          Cancel
        </Button>
      )}
      <Button size="small" {...props} onClick={onHandleClick}>
        <CloseIcon className={classes.leftIcon} />
        {confirm ? "Confirm" : ""}
      </Button>
    </>
  );
};

export default ConfirmButton;
