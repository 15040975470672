import * as React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BuildIcon from "@mui/icons-material/Build";
import { FilterList, FilterListItem } from "react-admin";
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
} from "date-fns";

export const CreatedFilter = () => (
  <FilterList label="Created" icon={<AccessTimeIcon />}>
    <FilterListItem
      label="Today"
      value={{
        created_gte: endOfYesterday().toISOString(),
        created_lte: undefined,
      }}
    />
    <FilterListItem
      label="This week"
      value={{
        created_gte: startOfWeek(new Date()).toISOString(),
        created_lte: undefined,
      }}
    />
    <FilterListItem
      label="Last week"
      value={{
        created_gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
        created_lte: startOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="This month"
      value={{
        created_gte: startOfMonth(new Date()).toISOString(),
        created_lte: undefined,
      }}
    />
    <FilterListItem
      label="Last month"
      value={{
        created_gte: subMonths(startOfMonth(new Date()), 1).toISOString(),
        created_lte: startOfMonth(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Earlier"
      value={{
        created_gte: undefined,
        created_lte: subMonths(startOfMonth(new Date()), 1).toISOString(),
      }}
    />
  </FilterList>
);

export const UpdatedFilter = () => (
  <FilterList label="Updated" icon={<AccessTimeIcon />}>
    <FilterListItem
      label="Today"
      value={{
        updated_gte: endOfYesterday().toISOString(),
        updated_lte: undefined,
      }}
    />
    <FilterListItem
      label="This week"
      value={{
        updated_gte: startOfWeek(new Date()).toISOString(),
        updated_lte: undefined,
      }}
    />
    <FilterListItem
      label="Last week"
      value={{
        updated_gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
        updated_lte: startOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="This month"
      value={{
        updated_gte: startOfMonth(new Date()).toISOString(),
        updated_lte: undefined,
      }}
    />
    <FilterListItem
      label="Last month"
      value={{
        updated_gte: subMonths(startOfMonth(new Date()), 1).toISOString(),
        updated_lte: startOfMonth(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Earlier"
      value={{
        updated_gte: undefined,
        updated_lte: subMonths(startOfMonth(new Date()), 1).toISOString(),
      }}
    />
  </FilterList>
);

export const ResolvedFilter = () => (
  <FilterList label="Resolved" icon={<AccessTimeIcon />}>
    <FilterListItem
      label="Today"
      value={{
        resolved_gte: endOfYesterday().toISOString(),
        resolved_lte: undefined,
      }}
    />
    <FilterListItem
      label="This week"
      value={{
        resolved_gte: startOfWeek(new Date()).toISOString(),
        resolved_lte: undefined,
      }}
    />
    <FilterListItem
      label="Last week"
      value={{
        resolved_gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
        resolved_lte: startOfWeek(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="This month"
      value={{
        resolved_gte: startOfMonth(new Date()).toISOString(),
        resolved_lte: undefined,
      }}
    />
    <FilterListItem
      label="Last month"
      value={{
        resolved_gte: subMonths(startOfMonth(new Date()), 1).toISOString(),
        resolved_lte: startOfMonth(new Date()).toISOString(),
      }}
    />
    <FilterListItem
      label="Earlier"
      value={{
        resolved_gte: undefined,
        resolved_lte: subMonths(startOfMonth(new Date()), 1).toISOString(),
      }}
    />
  </FilterList>
);

export const StatusFilter = () => (
  <FilterList label="Status" icon={<CheckCircleIcon />}>
    <FilterListItem label="New" value={{ status: "NEW" }} />
    <FilterListItem label="Investigating" value={{ status: "INVESTIGATING" }} />
    <FilterListItem label="Resolved" value={{ status: "RESOLVED" }} />
    <FilterListItem label="Do Not Fix" value={{ status: "DO NOT FIX" }} />
    <FilterListItem label="On Hold" value={{ status: "ON HOLD" }} />
  </FilterList>
);

export const EnvironmentExcludeFilter = () => (
  <FilterList label="Exclude Environment" icon={<BuildIcon />}>
    <FilterListItem
      label="Production"
      value={{ environment_not: "production" }}
    />
    <FilterListItem label="Test" value={{ environment_not: "test" }} />
  </FilterList>
);
