import { FC } from "react";
import { makeStyles } from "@mui/styles";
import { MenuProps, MenuItemLink, useSidebarState, Logout } from "react-admin";
import { Theme } from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useMediaQuery } from "@mui/material";
import resources from "../resources";

const useStyles = makeStyles<Theme>({
  nested: {
    marginLeft: "2em",
    whiteSpace: "break-spaces",
  },
  topLevel: {
    whiteSpace: "break-spaces",
  },
});

const NoIcon: FC = () => {
  return <></>;
};

const Menu: FC<MenuProps> = ({ onMenuClick }) => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs"),
  );
  const [open] = useSidebarState();
  const classes = useStyles();

  return (
    <div>
      {resources.map((resource) => {
        if (!resource.list && !resource.disabled) {
          return null;
        }
        return (
          <MenuItemLink
            key={resource.name}
            disabled={resource.disabled}
            to={`/${resource.name}`}
            classes={
              resource.options?.nested
                ? { root: classes.nested }
                : { root: classes.topLevel }
            }
            primaryText={resource.options?.label || resource.name}
            leftIcon={
              resource.options?.nested ? <ChevronRightIcon /> : <NoIcon />
            }
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        );
      })}
      {isXSmall && <Logout />}
    </div>
  );
};

export default Menu;
