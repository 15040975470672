import React, { FC } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

interface InvestigationNotesProps {
  text: string;
}

interface Line {
  ts: Date;
  username: string;
  text: string;
}

function parseLine(line: string): Line {
  const parts = line.split(/ +/);

  //See QUST-11943
  //Something happened and the investigation note wasn't correctly formatted
  //Return best available guess for parsing the investigation note
  if (parts.length < 6) {
    return {
      ts: new Date(),
      username: "😕 Username is missing",
      text: line,
    };
  }

  const [m, d, y, t] = parts.slice(0, 4);

  return {
    ts: new Date(
      [m, d, y, t.replace("AM", " AM").replace("PM", " PM"), "UTC"].join(" "),
    ),
    username: parts[4],
    text: parts.slice(5).join(" "),
  };
}

export function extractNotesFromLines(lines: string): Line[] {
  return lines
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line.length > 0)
    .map(parseLine);
}

const InvestigationNotes: FC<InvestigationNotesProps> = ({ text }) => (
  <div>
    <Typography variant="subtitle1">Investigation notes</Typography>
    <List dense>
      {text ? (
        extractNotesFromLines(text).map((line, i) => (
          <ListItem key={i}>
            <ListItemText
              primary={
                <span>
                  {line.ts.toLocaleString("en-US", {
                    timeZoneName: "short",
                  })}
                  &nbsp;
                  <strong>{line.username}</strong>
                </span>
              }
              secondary={line.text}
            />
          </ListItem>
        ))
      ) : (
        <ListItem>
          <ListItemText>
            <strong>None</strong>
          </ListItemText>
        </ListItem>
      )}
    </List>
  </div>
);

export default InvestigationNotes;
