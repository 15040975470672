import React from "react";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import { Labeled, TextField as RATextField, ReferenceField } from "react-admin";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers";

import * as API from "@advarra/connect-graphql/lib/types";

export interface SiteXrefInput {
  etmfStudyId: string;
  etmfSiteId: string;
  etmfProductId: string | undefined;
  etmfCountryId: string | undefined;
  initialTransferCompleteTimestamp: string | undefined;

  changeComment: string;
}

export default function CreateNewSiteXref({
  record,
  onConfirmation,
}: {
  record: API.SiteError;
  onConfirmation: (input: SiteXrefInput) => void;
}): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const [etmfSiteId, setEtmfSiteId] = React.useState<string | undefined>(
    undefined,
  );
  const [etmfStudyId, setEtmfStudyId] = React.useState<string | undefined>(
    undefined,
  );
  const [etmfProductId, setEtmfProductId] = React.useState<string | undefined>(
    undefined,
  );
  const [etmfCountryId, setEtmfCountryId] = React.useState<string | undefined>(
    undefined,
  );

  const [changeComment, setChangeComment] = React.useState<string | undefined>(
    undefined,
  );

  const [
    initialTransferCompleteTimestamp,
    setInitialTransferCompleteTimestamp,
  ] = React.useState<string | undefined>(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEtmfSiteId(undefined);
    setEtmfStudyId(undefined);
    setEtmfProductId(undefined);
    setEtmfCountryId(undefined);
    setInitialTransferCompleteTimestamp(undefined);
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    onConfirmation({
      etmfSiteId: etmfSiteId as string,
      etmfStudyId: etmfStudyId as string,
      etmfProductId,
      etmfCountryId,
      initialTransferCompleteTimestamp,

      changeComment: changeComment as string,
    });
    setEtmfSiteId(undefined);
    setEtmfStudyId(undefined);
    setEtmfProductId(undefined);
    setEtmfCountryId(undefined);
    setInitialTransferCompleteTimestamp(undefined);
  };

  const invalid = !etmfSiteId || !etmfStudyId || !changeComment;

  const suggestedStudyIds = extractSuggestions(
    record.suggestions?.etmfStudyId || [],
  );
  const suggestedProductIds = extractSuggestions(
    record.suggestions?.etmfProductId || [],
  );
  const suggestedCountryIds = extractSuggestions(
    record.suggestions?.etmfCountryId || [],
  );

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
      >
        New Site Xref
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create New Site Xref</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <Labeled label="Customer">
                <ReferenceField
                  source="orgId"
                  link={false}
                  reference="Organization"
                  record={record}
                  resource="SiteError"
                >
                  <RATextField source="name" />
                </ReferenceField>
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled label="SSU_ID">
                <RATextField
                  record={record}
                  resource="SiteError"
                  source="ssuId"
                />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="etmfSiteId"
                label="Site Id"
                required
                error={etmfSiteId === ""}
                helperText={etmfSiteId === "" ? "Must enter a site id" : " "}
                value={etmfSiteId || ""}
                onChange={(event): void => setEtmfSiteId(event.target.value)}
                variant="filled"
              />
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={6}>
                <TextField
                  name="etmfStudyId"
                  label="Study Id"
                  required
                  error={etmfStudyId === ""}
                  helperText={
                    etmfStudyId === "" ? "Must enter a study id" : " "
                  }
                  value={etmfStudyId || ""}
                  onChange={(event): void => setEtmfStudyId(event.target.value)}
                  variant="filled"
                />
              </Grid>
              <Grid item xs={6}>
                {suggestedStudyIds.map((id) => (
                  <Chip
                    variant="outlined"
                    size="small"
                    id={`study-hint-${id}`}
                    key={`study-hint-${id}`}
                    label={id}
                    title="Suggestion based on existing protocol or sites"
                    clickable
                    color="primary"
                    onClick={() => setEtmfStudyId(id)}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={6}>
                <TextField
                  name="etmfProductId"
                  label="Product Id"
                  value={etmfProductId || ""}
                  onChange={(event): void =>
                    setEtmfProductId(event.target.value)
                  }
                  variant="filled"
                />
              </Grid>
              <Grid item xs={6}>
                {suggestedProductIds.map((id) => (
                  <Chip
                    variant="outlined"
                    size="small"
                    id={`product-hint-${id}`}
                    key={`product-hint-${id}`}
                    label={id}
                    title="Suggestion based on existing protocol or sites"
                    clickable
                    color="primary"
                    onClick={() => setEtmfProductId(id)}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={6}>
                <TextField
                  name="etmfCountryId"
                  label="Country Id"
                  value={etmfCountryId || ""}
                  onChange={(event): void =>
                    setEtmfCountryId(event.target.value)
                  }
                  variant="filled"
                />
              </Grid>
              <Grid item xs={6}>
                {suggestedCountryIds.map((id) => (
                  <Chip
                    variant="outlined"
                    size="small"
                    id={`country-hint-${id}`}
                    key={`country-hint-${id}`}
                    label={id}
                    title="Suggestion based on existing protocol or sites"
                    clickable
                    color="primary"
                    onClick={() => setEtmfCountryId(id)}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DateTimePicker
                // fullWidth
                label="Initial Transfer Completed Date"
                // margin="normal"
                value={initialTransferCompleteTimestamp || null}
                // emptyLabel="None"
                onChange={(date): void =>
                  setInitialTransferCompleteTimestamp(date ? date : undefined)
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Tooltip
            placement="top"
            arrow
            title={
              invalid
                ? "Site Id, Study Id, and change comment are required to proceed."
                : ""
            }
          >
            {/* the button must be wrapped with a span so that the tooltip will appear when the button is disabled */}
            <span>
              <Button
                onClick={handleConfirm}
                variant="outlined"
                color="primary"
                disabled={invalid}
              >
                Save
              </Button>
            </span>
          </Tooltip>
          <TextField
            required
            name="changeComment"
            placeholder="What did you change?"
            error={changeComment === ""}
            helperText={
              changeComment === "" ? "Must enter a change comment" : " "
            }
            margin="dense"
            value={changeComment || ""}
            onChange={(event): void => setChangeComment(event.target.value)}
          />
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
function extractSuggestions(
  suggestions: API.SiteSuggestionDetails[],
): string[] {
  if (suggestions.some((o) => o.source === API.SiteSuggestionSource.Protocol)) {
    return suggestions
      .filter((o) => o.source === API.SiteSuggestionSource.Protocol)
      .map((o) => o.id);
  }

  return suggestions.map((o) => o.id);
}
