import React, { Fragment, useEffect } from "react";
import {
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  RadioButtonGroupInput,
  minLength,
  AutocompleteInput,
} from "react-admin";
import Button from "@mui/material/Button";
import { useWatch, useFormState, useFormContext } from "react-hook-form";
import ConnectorDetailsInput from "./ConnectorDetailsInput";
import { SectionTitle } from "./Create";
import { hasAllInputs } from "./connectionDetails";
import BetterDateTimeInput from "../../inputs/BetterDateTimeInput";

import * as API from "@advarra/connect-graphql/lib/types";
import {
  METADATA_AUTOMATION_TYPES,
  MetadataAutomationTypes,
  SOURCES,
} from "@advarra/connect-constants";
import {
  CreateOrgIntegrationInput,
  UpdateOrgIntegrationInput,
} from "@advarra/connect-graphql/types";

interface Props {
  create: boolean;
}

const PROCESS_NOT_RUN_MESSAGE = "The process has not run";

const vqlFieldValidators = [required(), minLength(1)];

function formatTimestamp(ts: string): string {
  if (ts === "1970-01-01T00:00:00Z") {
    return PROCESS_NOT_RUN_MESSAGE;
  }

  return ts;
}

function parseTimestamp(ts: string): string {
  if (ts === PROCESS_NOT_RUN_MESSAGE) {
    return "1970-01-01T00:00:00Z";
  }

  return ts;
}

type CreateOrUpdateOrgIntegrationInput = UpdateOrgIntegrationInput &
  CreateOrgIntegrationInput & {
    metadataVQLPull: {
      enableVQLProtocolQuery: boolean;
      enableVQLSiteQuery: boolean;
    };
    hasPassword: boolean;
  };

const FormContents = ({ create }: Props): JSX.Element => {
  const formValues = useWatch<CreateOrUpdateOrgIntegrationInput>();
  const { setValue } = useFormContext();
  const { isDirty, dirtyFields } = useFormState();

  let requiredFieldsProvided = false;

  requiredFieldsProvided =
    formValues.name && formValues.integrationTypeId && formValues.environment
      ? hasAllInputs(formValues)
      : false;
  const isCurrentlyInUse =
    (formValues.isActive && !dirtyFields.isActive) ||
    (!formValues.isActive && dirtyFields.isActive);

  useEffect(() => {
    if (isCurrentlyInUse && isDirty && !requiredFieldsProvided) {
      setValue("isActive", false);
    }
  });

  return (
    <Fragment>
      <SectionTitle label="General settings" />
      <TextInput
        source="name"
        label="Customer name"
        validate={required()}
        helperText="Free text: It might be similar to customer from CIRBI or can be what Advarra Connect uses as a name for the customer."
      />
      <RadioButtonGroupInput
        disabled={!create}
        helperText="Cannot be set or changed after the integration is created"
        validate={[create] && [required()]}
        style={{ display: "block" }}
        label="Environment"
        source="environment"
        choices={[
          { id: "production", name: "Production" },
          { id: "test", name: "Test" },
        ]}
      />
      <BooleanInput
        source="isActive"
        helperText="Configuration can only be set to Active if all fields are complete, otherwise it will be disabled."
        disabled={!isCurrentlyInUse && !requiredFieldsProvided}
      />
      <BetterDateTimeInput
        label="Start date"
        source="startDate"
        helperText="Only documents created date on or after this date are integrated. Ignored if blank."
        options={{ format: "MMM d, yyyy hh:mm a", clearable: true }}
      />
      <ReferenceInput
        source="integrationTypeId"
        reference="IntegrationType"
        allowEmpty
      >
        <SelectInput
          label="Integration Type"
          optionText="name"
          emptyText="None"
          validate={required()}
        />
      </ReferenceInput>
      <SelectInput
        label="Source"
        source="sourceId"
        optionText="name"
        emptyText="None"
        choices={SOURCES}
      />
      <div title="Select an existing attribute map to assign to this customer integration.">
        <ReferenceInput
          source="customerMapId"
          disabled={isCurrentlyInUse}
          reference="Mapping"
          allowEmpty
        >
          <AutocompleteInput
            label="Attribute Map"
            filterToQuery={(searchText: any) => ({ name: searchText })}
            emptyText="None"
            helperText={false}
            optionText={(record: API.CustomerMap) => {
              if (!record || !record.name) {
                return "None";
              }
              return `${record.name} - ${record.integrationType?.id}`;
            }}
          />
        </ReferenceInput>
      </div>
      {!create && !isCurrentlyInUse && (
        <Button
          variant="contained"
          style={{ margin: "1rem" }}
          color="primary"
          target="_blank"
          title="This will create a new attribute map and assign it to this customer integration."
          href={`/#/Mapping/create?source={"customerIntegrationId":${formValues.id}}`}
        >
          Create New
        </Button>
      )}
      <div>
        <ReferenceInput
          source="countryMapId"
          disabled={isCurrentlyInUse}
          reference="CountryMap"
          allowEmpty
        >
          <AutocompleteInput
            label="Country Map"
            filterToQuery={(searchText: any) => ({ name: searchText })}
            emptyText="None"
            helperText={false}
            optionText={(record: API.CountryMap) => {
              if (!record || !record.name) {
                return "None";
              }
              return record.name;
            }}
          />
        </ReferenceInput>
      </div>

      <SectionTitle label="Connector details" />
      <ConnectorDetailsInput
        integrationTypeId={formValues.integrationTypeId as string}
        hasPassword={formValues.hasPassword as boolean}
      />

      <SectionTitle label="Protocol and site configuration automation settings" />
      <SelectInput
        label="Automation Type"
        source="metadataTypeId"
        format={(value: string) => {
          return value ?? "manual";
        }}
        parse={(value: string) => {
          return value === "manual" ? null : value;
        }}
        choices={METADATA_AUTOMATION_TYPES}
      />

      {formValues.integrationTypeId === "VV3" && (
        <BooleanInput
          label="Support mapping a single source entity to multiple external records"
          source="combineSitesInXrefsFlag"
        />
      )}

      {formValues.metadataTypeId === MetadataAutomationTypes.CSVPush && (
        <TextInput
          source="metadataSftpPrefix"
          label="SFTP path prefix for CSV metadata"
          validate={required()}
          helperText="The path to where the CSV files will be uploaded via SFTP. e.g. abbvie_etmf/uploads/"
        />
      )}

      {formValues.metadataTypeId ===
        MetadataAutomationTypes.VaultQueryLanguagePull && (
        <BooleanInput
          label="Enable protocol level VQL queries"
          source="metadataVQLPull.enableVQLProtocolQuery"
          defaultValue={true}
        />
      )}

      {formValues.metadataTypeId ===
        MetadataAutomationTypes.VaultQueryLanguagePull &&
        formValues.metadataVQLPull?.enableVQLProtocolQuery && (
          <TextInput
            source="metadataVQLPull.protocolVQL"
            multiline={true}
            fullWidth={true}
            defaultValue=""
            label="VQL query for protocol records"
            validate={vqlFieldValidators}
            helperText="The Veeva Vault VQL query used to retrieve study/study country records"
          />
        )}
      {formValues.metadataTypeId ===
        MetadataAutomationTypes.VaultQueryLanguagePull && (
        <TextInput
          source="metadataVQLPull.protocolLastModifiedTimestamp"
          disabled
          defaultValue="1970-01-01T00:00:00Z"
          parse={parseTimestamp}
          format={formatTimestamp}
          label="Last time protocol VQL scan occurred"
          helperText="The next scan using the above Veeva Vault VQL query will request only records modified since this timestamp."
        />
      )}

      {formValues.metadataTypeId ===
        MetadataAutomationTypes.VaultQueryLanguagePull && (
        <ArrayInput
          source="metadataVQLPull.protocolMapping"
          label="Map VQL Result Fields to Output Fields"
        >
          <SimpleFormIterator>
            <TextInput
              source="vqlField"
              validate={vqlFieldValidators}
              label="VQL Field"
            />
            <SelectInput
              source="outputField"
              validate={required()}
              label="Output Field"
              choices={Object.values(API.CtmsRecordField).map((id) => ({
                id,
                name: id,
              }))}
            />
          </SimpleFormIterator>
        </ArrayInput>
      )}

      {formValues.metadataTypeId ===
        MetadataAutomationTypes.VaultQueryLanguagePull && (
        <BooleanInput
          label="Enable site level VQL queries"
          source="metadataVQLPull.enableVQLSiteQuery"
          defaultValue={true}
        />
      )}

      {formValues.metadataTypeId ===
        MetadataAutomationTypes.VaultQueryLanguagePull &&
        formValues.metadataVQLPull?.enableVQLSiteQuery && (
          <TextInput
            source="metadataVQLPull.siteVQL"
            multiline={true}
            fullWidth={true}
            defaultValue=""
            label="VQL query for site records"
            validate={vqlFieldValidators}
            helperText="The Veeva Vault VQL query used to retrieve site records"
          />
        )}
      {formValues.metadataTypeId ===
        MetadataAutomationTypes.VaultQueryLanguagePull && (
        <TextInput
          source="metadataVQLPull.siteLastModifiedTimestamp"
          disabled
          defaultValue="1970-01-01T00:00:00Z"
          parse={parseTimestamp}
          format={formatTimestamp}
          label="Last time site VQL scan occurred"
          helperText="The next scan using the above Veeva Vault VQL query will request only records modified since this timestamp."
        />
      )}
      {formValues.metadataTypeId ===
        MetadataAutomationTypes.VaultQueryLanguagePull && (
        <SelectInput
          source="metadataVQLPull.sitePINameMatching"
          defaultValue={API.SitePiNameMatchingType.Lastfirst}
          choices={[
            { id: API.SitePiNameMatchingType.Lastfirst, name: "Last, First" },
            { id: API.SitePiNameMatchingType.Firstlast, name: "First Last" },
          ]}
          label="PI Name matching for PRINCIPAL_INVESTIGATOR output field"
          helperText="How to interpret the PRINCIPAL_INVESTIGATOR output field, if used. 'Lastname, Firstname middle' or 'Firstname middle Lastname'"
        />
      )}

      {formValues.metadataTypeId ===
        MetadataAutomationTypes.VaultQueryLanguagePull && (
        <ArrayInput
          source="metadataVQLPull.siteMapping"
          label="Map VQL Result Fields to Output Fields"
        >
          <SimpleFormIterator>
            <TextInput
              source="vqlField"
              validate={vqlFieldValidators}
              label="VQL Field"
            />
            <SelectInput
              source="outputField"
              validate={required()}
              label="Output Field"
              choices={Object.values(API.CtmsRecordField).map((id) => ({
                id,
                name: id,
              }))}
            />
          </SimpleFormIterator>
        </ArrayInput>
      )}

      <ArrayInput
        source="entityMatchRules"
        label="Rules to Match Protocols and Sites to this Customer Integration"
      >
        <SimpleFormIterator>
          <SelectInput
            source="type"
            label="Type"
            validate={required()}
            choices={Object.values(API.CustomerEntityMatchRuleType).map(
              (id) => ({
                id,
                name: id.replace(/([a-z0-9])([A-Z])/g, "$1 $2"),
              }),
            )}
          />
          <TextInput source="value" validate={required()} label="Value" />
        </SimpleFormIterator>
      </ArrayInput>
    </Fragment>
  );
};

export default FormContents;
