import { defaultTheme, RaThemeOptions } from "react-admin";
import { createTheme } from "@mui/material/styles";

const themeSettings: RaThemeOptions = {
  ...defaultTheme,
  sidebar: {
    width: 300,
  },

  // Created with https://react-theming.github.io/create-mui-theme/
  // from
  // https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=007EA3&secondary.color=490E6F&primary.text.color=f1f2ed&secondary.text.color=ffffff
  palette: {
    primary: { main: "#007EA3", contrastText: "#f1f2ed" },
    secondary: { main: "#003e56", contrastText: "#f1f2ed" },
  },
};

export const lightTheme = createTheme(themeSettings);

export const darkTheme = createTheme({
  ...themeSettings,
  palette: {
    ...themeSettings.palette,
    mode: "dark",
  },
});
