import gql from "graphql-tag";

const auditTrailBaseFields = `
    id
    sourceKey
    created
    user {
      username
      email
    }

    customerIntegration {
      id
      name
      integrationTypeId
    }
`;

export default gql`
  query ListAuditTrails($perPage: Int, $page: Int, $filter: AuditTrailFilter, $sort: AuditTrailSort) {
    listAuditTrails(perPage: $perPage, page: $page, filter: $filter, sort: $sort) {
      items {
        ... on SiteErrorAuditTrail {
          ${auditTrailBaseFields}

          proId
          ssuId
          protocolNumber
          piFirstName
          piLastName
          investigationStatus

          before {
            id
            orgId
            proId
            ssuState
            piFirstName
            piLastName
            piEmailAddress
            protocolNumber
            ssuApprovedDate
            terminatedDate
            lastFileCreatedDate
            investigationStatus
            foundByApi
            reason
            responsible
            investigationNotes
            created
            updated
          }
          after {
            id
            orgId
            proId
            ssuState
            piFirstName
            piLastName
            piEmailAddress
            protocolNumber
            ssuApprovedDate
            terminatedDate
            lastFileCreatedDate
            investigationStatus
            foundByApi
            reason
            responsible
            investigationNotes
            created
            updated
          }
        }
        ... on StudyErrorAuditTrail {
          ${auditTrailBaseFields}

          proId
          protocolNumber
          piFirstName
          piLastName
          investigationStatus

          before {
            id
            orgId
            projectState
            protocolNumber
            foundByApi
            submittedDate
            companyAssignment
            investigationStatus
            reason
            responsible
            investigationNotes
            created
            updated
          }
          after {
            id
            orgId
            projectState
            protocolNumber
            foundByApi
            submittedDate
            companyAssignment
            investigationStatus
            reason
            responsible
            investigationNotes
            created
            updated
          }
        }
        ... on CustomerCTMSRecordAuditTrail {
          ${auditTrailBaseFields}

          proId
          ssuId
          protocolNumber
          piFirstName
          piLastName

          before {
            id
            orgId
            etmfId
            created
            updated
            processed
            error
            sourceBucket
            sourceKey
            piFirstName
            piLastName
            ingestedData
          }
          after {
            id
            orgId
            etmfId
            created
            updated
            processed
            error
            sourceBucket
            sourceKey
            piFirstName
            piLastName
            ingestedData
          }
        }

        ... on SiteXrefAuditTrail {
          ${auditTrailBaseFields}

          proId
          ssuId
          changeComment

          before {
            orgId
            etmfId
            ssuId
            etmfSiteId
            etmfStudyId
            etmfProductId
            etmfCountryId
            transferInitial
            transferIncremental
            isActive
            initialTransferCompleteTimestamp
            lastScheduledDate
            id
          }

          after {
            orgId
            etmfId
            ssuId
            etmfSiteId
            etmfStudyId
            etmfProductId
            etmfCountryId
            transferInitial
            transferIncremental
            isActive
            initialTransferCompleteTimestamp
            lastScheduledDate
            id
          }
        }

        ... on ProtocolXrefAuditTrail {
          ${auditTrailBaseFields}

          proId
          changeComment

          before {
            orgId
            etmfId
            proId
            etmfStudyId
            etmfProductId
            etmfCountryId
            transferInitial
            transferIncremental
            isActive
            initialTransferCompleteTimestamp
            lastScheduledDate
            id
          }

          after {
            orgId
            etmfId
            proId
            etmfStudyId
            etmfProductId
            etmfCountryId
            transferInitial
            transferIncremental
            isActive
            initialTransferCompleteTimestamp
            lastScheduledDate
            id
          }
        }
      }

      totalItems
    }
  }
`;
