/**
 * This file is NOT AUTO-GENERATED!
 */

export const updateSiteError = /* GraphQL */ `
  mutation UpdateSiteError($input: UpdateSiteErrorInput!) {
    updateSiteError(input: $input) {
      id
      orgId
      proId
      ssuState
      piFirstName
      piLastName
      piEmailAddress
      protocolNumber
      ssuApprovedDate
      terminatedDate
      lastFileCreatedDate
      investigationStatus
      foundByApi
      reason
      responsible
      investigationNotes
      created
      updated
      integration {
        etmfId
        metadataType
        metadataDirection
      }
    }
  }
`;
export const updateUnconfiguredProtocol = /* GraphQL */ `
  mutation UpdateUnconfiguredProtocol($input: UpdateStudyErrorInput!) {
    updateUnconfiguredProtocol(input: $input) {
      id
      orgId
      projectState
      protocolNumber
      foundByApi
      submittedDate
      companyAssignment
      investigationStatus
      reason
      responsible
      investigationNotes
      created
      updated
      integration {
        etmfId
        metadataType
        metadataDirection
      }
    }
  }
`;
export const updateCustomerCtmsRecord = /* GraphQL */ `
  mutation UpdateCustomerCtmsRecord($input: UpdateCustomerCTMSRecordInput!) {
    updateCustomerCTMSRecord(input: $input) {
      id
      orgId
      etmfId
      created
      updated
      processed
      error
      sourceBucket
      sourceKey
      piFirstName
      piLastName
      ingestedData
    }
  }
`;
export const createConnectionTest = /* GraphQL */ `
  mutation CreateConnectionTest($input: ConnectionTestInput!) {
    createConnectionTest(input: $input) {
      success
      error
    }
  }
`;
export const updateError = /* GraphQL */ `
  mutation UpdateError($input: UpdateErrorInput!) {
    updateError(input: $input) {
      id
      assignee
      ticketNumber
      created
      updated
      resolved
      status
      category
      message
      lambda
      stepFunction
      orgId
      integrationId
      transactionId
      documentId
      proId
      ssuId
      comments {
        created
        username
        note
      }
    }
  }
`;
export const createOrgIntegration = /* GraphQL */ `
  mutation CreateOrgIntegration($input: CreateOrgIntegrationInput!) {
    createOrgIntegration(input: $input) {
      id
      organizationId
      countryMapId
      customerMapId
      name
      integrationTypeId
      isActive
      integrationTypeName
      metadataTypeId
      metadataSftpPrefix
      metadataType
      metadataDirection
      metadataVQLPull {
        enableVQLProtocolQuery
        enableVQLSiteQuery
        protocolVQL
        siteVQL
        protocolMapping {
          outputField
          vqlField
        }
        siteMapping {
          outputField
          vqlField
        }
        protocolLastModifiedTimestamp
        siteLastModifiedTimestamp
        sitePINameMatching
      }
      environment
      entityMatchRules {
        type
        value
      }
      startDate
      endDate
      hasPassword
      ftpHostAddress
      ftpPort
      ftpUser
      ftpPath
      ftpMetadataPath
      apiHostAddress
      apiUser
      sourceId
    }
  }
`;
export const updateOrgIntegration = /* GraphQL */ `
  mutation UpdateOrgIntegration($input: UpdateOrgIntegrationInput!) {
    updateOrgIntegration(input: $input) {
      id
      organizationId
      countryMapId
      customerMapId
      combineSitesInXrefsFlag
      name
      integrationTypeId
      isActive
      integrationTypeName
      metadataTypeId
      metadataSftpPrefix
      metadataType
      metadataDirection
      metadataVQLPull {
        enableVQLProtocolQuery
        enableVQLSiteQuery
        protocolVQL
        siteVQL
        protocolMapping {
          outputField
          vqlField
        }
        siteMapping {
          outputField
          vqlField
        }
        protocolLastModifiedTimestamp
        siteLastModifiedTimestamp
        sitePINameMatching
      }
      environment
      entityMatchRules {
        type
        value
      }
      startDate
      endDate
      hasPassword
      ftpHostAddress
      ftpPort
      ftpUser
      ftpPath
      ftpMetadataPath
      apiHostAddress
      apiUser
      sourceId
    }
  }
`;
export const createSiteXref = /* GraphQL */ `
  mutation CreateSiteXref($input: CreateSiteXrefInput!) {
    createSiteXref(input: $input) {
      orgId
      etmfId
      ssuId
      siteId
      etmfSiteId
      etmfStudyId
      etmfProductId
      etmfCountryId
      initialTransferCompleteTimestamp
      id
    }
  }
`;

export const createProXref = /* GraphQL */ `
  mutation CreateProXref($input: CreateProXrefInput!) {
    createProXref(input: $input) {
      orgId
      etmfId
      proId
      protocolId
      etmfStudyId
      etmfProductId
      etmfCountryId
      initialTransferCompleteTimestamp
      id
    }
  }
`;

export const createPermission = /* GraphQL */ `
  mutation CreatePermission($input: CreatePermissionInput!) {
    createPermission(input: $input) {
      type
      resource
      role
      created
      updated
      id
    }
  }
`;

export const updatePermission = /* GraphQL */ `
  mutation UpdatePermission($input: UpdatePermissionInput!) {
    updatePermission(input: $input) {
      type
      resource
      role
      created
      updated
      id
    }
  }
`;

export const deletePermissions = /* GraphQL */ `
  mutation DeletePermissions($input: DeletePermissionsInput!) {
    deletePermissions(input: $input) {
      ids
    }
  }
`;
export const createMapping = /* GraphQL */ `
  mutation CreateMapping($input: CreateMappingInput!) {
    createMapping(input: $input) {
      warning

      mapping {
        id
        name
        description
        integrationType {
          id
          name
        }
        created
        updated
        createdBy {
          username
          email
        }
        lastUpdatedBy {
          username
          email
        }

        schemas {
          id
          name
          description
          precedence
          created
          updated

          fields {
            created
            destinationFieldName
            id
            source
            sourceType
            updated
          }

          rules {
            comparisonOperator
            created
            fieldName
            id
            updated
            value
          }
        }
      }
    }
  }
`;
export const updateMapping = /* GraphQL */ `
  mutation UpdateMapping($input: UpdateMappingInput!) {
    updateMapping(input: $input) {
      warning

      mapping {
        id
        name
        description
        integrationType {
          id
          name
        }
        created
        updated
        createdBy {
          username
          email
        }
        lastUpdatedBy {
          username
          email
        }

        schemas {
          id
          name
          description
          precedence
          type
          created
          updated

          fields {
            created
            destinationFieldName
            id
            source
            sourceType
            updated
          }

          rules {
            comparisonOperator
            created
            fieldName
            id
            updated
            value
          }
        }
      }
    }
  }
`;

export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction($input: UpdateTransactionInput!) {
    updateTransaction(input: $input) {
      attributes
      id
      created
      docId
      externalId
      status
      updated
    }
  }
`;
