// import React from "react";
import { Labeled } from "react-admin";
import { styled } from "@mui/system";

export const styles = {
  paddingBottom: "0.8rem",
  "& .RaLabeled-label": {
    paddingBottom: "0.3rem",
  },
};

export default styled(Labeled)(styles);
