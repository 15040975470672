import React, { FC } from "react";
import {
  Show,
  TextField,
  FunctionField,
  BooleanField,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  ShowProps,
  ShowActionsProps,
  TitleProps,
} from "react-admin";
import DateField from "../../fields/DateField";
import * as API from "@advarra/connect-graphql/lib/types";

const ProXrefTitle: FC<TitleProps> = ({ record }) =>
  record ? <span>Protocol Cross Reference {record.proId}</span> : null;

const ProXrefShowActions: FC<ShowActionsProps> = (props) => (
  <TopToolbar className={props.className}>
    <ListButton />
  </TopToolbar>
);

const ProXrefShow: FC<ShowProps> = (props) => (
  <Show {...props} title={<ProXrefTitle />} actions={<ProXrefShowActions />}>
    <SimpleShowLayout>
      <TextField source="proId" label="PRO ID" />
      <TextField source="protocolNumber" label="Customer Protocol Number" />
      <FunctionField<API.ProXref>
        sortable={true}
        source="customerIntegration"
        label="Customer Integration"
        render={(record) =>
          record && record.customerIntegration ? (
            <span>
              {record.customerIntegration.name} (
              {record.customerIntegration.integrationTypeId})
            </span>
          ) : null
        }
      />
      <TextField source="submittingOrg" label="Protocol Submitting Org" />
      <TextField source="orgId" label="Org Id" />
      <TextField source="etmfId" label="ETMF Id" />
      <BooleanField source="isActive" label="Active Status" />
      <TextField source="txfrInitial" label="Initial Transfer" />
      <TextField source="txfrIncremental" label="Transfer Incremental" />
      <TextField source="etmfStudyId" label="Customer Study ID" />
      <TextField source="etmfCountryId" label="Country Id" />
      <TextField source="etmfProductId" label="Product Id" />
      <DateField
        source="initialTransferCompleteTimestamp"
        label="Initial Document Transfer"
      />
      <DateField source="lastScheduledDate" label="Last Scheduled Date" />
      <DateField source="created" />
      <DateField source="updated" />
    </SimpleShowLayout>
  </Show>
);

export default ProXrefShow;
