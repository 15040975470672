export function getInputFields(integrationTypeId: string): string[] {
  switch (integrationTypeId) {
    case "VV2":
    case "VV3":
      return ["apiUser", "apiHostAddress"];
    case "SFTP":
      return [
        "ftpHostAddress",
        "ftpPort",
        "ftpUser",
        "ftpPath",
        "ftpMetadataPath",
      ];
    case "ER":
      return ["apiUser", "apiHostAddress"];
    case "TI2":
      return ["apiUser", "apiHostAddress", "ftpHostAddress"];
    case "SIP":
      return ["apiUser", "apiHostAddress"];
    default:
      return [];
  }
}

export function hasAllInputs(formValues: Record<string, any>): boolean {
  if (!formValues.hasPassword && (formValues.password || "") === "") {
    return false;
  }

  return getInputFields(formValues.integrationTypeId).every(
    (input) => formValues[input],
  );
}
