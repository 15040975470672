import React from "react";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import { withStyles, WithStyles } from "@mui/styles";
import AuditItem from "./AuditItem";
import AuditEvent from "./AuditEvent";

const styles = {
  root: {},
};

interface Props extends WithStyles<typeof styles> {
  events?: AuditEvent[];
}

const EventListView = ({ events = [], classes }: Props) => (
  <Card className={classes.root}>
    <List>
      {events.map((event) => (
        <AuditItem event={event} key={event.id} />
      ))}
    </List>
  </Card>
);

const EventList = withStyles(styles)(EventListView);

export default EventList;
