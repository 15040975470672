import React, { FC } from "react";
import {
  Toolbar,
  SaveButton,
  ListButton,
  TextInput,
  required,
  ToolbarProps,
} from "react-admin";
import Icon from "@mui/material/Icon";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SaveIcon from "@mui/icons-material/Save";
import { useFormState, useFormContext } from "react-hook-form";
import Button from "@mui/material/Button";

interface ChangeCommentToolbarProps {
  create: boolean;
  label: string;
}

const ChangeCommentToolbar: FC<
  Omit<ToolbarProps, "width"> & ChangeCommentToolbarProps
> = ({ ...props }) => {
  return <ChangeCommentToolbarInner {...props} />;
};

export const ChangeCommentToolbarInner: FC<
  Omit<ToolbarProps, "width"> & ChangeCommentToolbarProps
> = ({ create, label, ...props }) => {
  const { reset } = useFormContext();
  const { dirtyFields } = useFormState();
  const isDirty = Object.keys(dirtyFields).length > 0;

  return (
    <>
      {!create && isDirty && (
        <TextInput
          {...{ props }}
          validate={required()}
          label="Change Comment"
          source="changeComment"
          size="small"
          margin="dense"
          style={{
            flexDirection: "row",
            maxHeight: "2.6rem",
            marginLeft: "1rem",
          }}
        />
      )}
      <Toolbar {...props}>
        <SaveButton
          label={create ? "Create" : "Save"}
          icon={create ? <AddBoxIcon /> : <SaveIcon />}
          disabled={!isDirty}
        />
        <ListButton
          label="Cancel"
          title={`Return to ${label} List`}
          icon={<Icon />}
        />
        <Button disabled={!isDirty} onClick={() => reset()}>
          Discard Changes
        </Button>
      </Toolbar>
    </>
  );
};

export default ChangeCommentToolbar;
