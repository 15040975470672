import * as React from "react";

import { Auth } from "@aws-amplify/auth";
import { QRCodeSVG } from "qrcode.react";

import { useState } from "react";
import { useLogin } from "react-admin";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CircularProgress from "@mui/material/CircularProgress";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import logo from "./Logo.svg";

// https://mui.com/material-ui/react-tabs/
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const LoginPage = () => {
  const [credentials, setCredentials] = useState<null | {
    username: string;
    password: string;
    user: any;
  }>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false);
  const [mfaSetupRequired, setMfaSetupRequired] = useState<string | null>(null);
  const [tab, setTab] = useState(0);
  const login = useLogin();

  const handleLogin = async (
    username: string,
    password: string,
    mfaCode?: string,
  ) => {
    setError(null);
    setLoading(true);

    try {
      await login({ username, password, mfaCode });
      // if this does not reject/throw, then we successfully logged in
      // we don't change loading flag because we will automatically navigate away from the page via react-admin
    } catch (e: any) {
      setLoading(false);
      if (e.user) {
        setCredentials({ username, password, user: e.user });
      }
      switch (e.type) {
        case "MFA_SETUP": {
          // e.user should be set, if e.type is this valu
          const mfaSetupCode = await Auth.setupTOTP(e.user);
          setMfaSetupRequired(
            `otpauth://totp/AWSCognito:${e.user.username}?secret=${mfaSetupCode}&issuer=Connect%20${window.ENV}`,
          );
          setTab(1);
          break;
        }
        case "NEW_PASSWORD_REQUIRED": {
          setIsNewPasswordRequired(true);
          setTab(2);
          break;
        }
        case "SOFTWARE_TOKEN_MFA": {
          // e.user should be set, if e.type is this value
          setMfaSetupRequired(null);
          setTab(1);
          break;
        }
        case "FAILURE":
          setError(e.error);
          break;
        default:
          setError(e.toString());
      }
    }
  };

  const handleCompleteLoginWithMfa = (mfaCode: string) => {
    if (!credentials) {
      throw new Error("Missing credentials from step 1 of login");
    }

    handleLogin(credentials.username, credentials.password, mfaCode);
  };

  const handleChangeTab = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ): void => {
    setTab(newValue);
  };

  const handleSetupMfa = async (mfaCode: string) => {
    setLoading(true);
    setError(null);

    if (!credentials) {
      throw new Error("Missing credentials from step 1 of login");
    }

    try {
      await Auth.verifyTotpToken(credentials.user, mfaCode);
      await Auth.setPreferredMFA(credentials.user, "TOTP");
      setTab(0);
    } catch (err: any) {
      setError(err.toString());
    }
    setLoading(false);
  };

  const handleSetNewPassword = async (newPassword: string) => {
    setError(null);
    setLoading(true);

    if (!credentials) {
      throw new Error("Missing credentials from step 1 of login");
    }

    try {
      await Auth.completeNewPassword(credentials.user, newPassword);

      setIsNewPasswordRequired(false);
      setCredentials(null);
      setTab(0);
    } catch (err: any) {
      setError(err.toString());
    }
    setLoading(false);
  };

  const handleChangePassword = async (
    username: string,
    password: string,
    newPassword: string,
    mfaCode?: string,
  ) => {
    setError(null);
    setLoading(true);

    try {
      let user = await Auth.signIn(username, password);

      if (mfaCode) {
        user = await Auth.confirmSignIn(
          user, // Return object from Auth.signIn()
          mfaCode,
          "SOFTWARE_TOKEN_MFA",
        );
        if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
          user.challengeName = null;
        }
      }
      await Auth.changePassword(user, password, newPassword);

      setIsNewPasswordRequired(false);
      setCredentials(null);
      setTab(0);
    } catch (err: any) {
      setError(err.toString());
    }
    setLoading(false);
  };

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <Box style={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={handleChangeTab}>
          <Tab label="Login" {...a11yProps(0)} />
          <Tab label="MFA" {...a11yProps(1)} disabled={!credentials} />
          <Tab label="Change Password" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <Login loading={loading} onLogin={handleLogin} setError={setError} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <MFAView
          mfaSetupRequired={mfaSetupRequired}
          onMfaCode={handleCompleteLoginWithMfa}
          onSetupMfa={handleSetupMfa}
          loading={loading}
          setError={setError}
        />
      </TabPanel>

      <TabPanel value={tab} index={2}>
        {isNewPasswordRequired ? (
          <SetNewPassword
            onSetPassword={handleSetNewPassword}
            loading={loading}
            setError={setError}
          />
        ) : (
          <ChangePassword
            loading={loading}
            setError={setError}
            onChange={handleChangePassword}
          />
        )}
      </TabPanel>
    </>
  );
};

export default LoginPage;

interface CommonProps {
  loading: boolean;
  setError: (err: string | null) => void;
}

export function Login({
  onLogin,
  loading,
}: CommonProps & {
  onLogin: (username: string, password: string) => Promise<void>;
}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setUsername("");
    setPassword("");
    onLogin(username, password);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            minWidth: 300,
            maxWidth: 400,

            padding: "4em",
            marginTop: "6em",
          }}
        >
          <Box style={{ backgroundColor: "#003e56" }}>
            <img src={logo} title="Advarra Connect logo" />
          </Box>
          <Box>
            <TextField
              id="loginUsername"
              name="username"
              fullWidth={false}
              type="text"
              value={username}
              label="Username"
              required
              onChange={(e) => setUsername(e.target.value)}
              autoFocus
              disabled={loading}
            />
          </Box>
          <Box>
            <TextField
              id="loginPassword"
              name="password"
              fullWidth={false}
              type="password"
              label="Password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />
          </Box>

          <CardActions>
            {loading && <CircularProgress />}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={loading || !username || !password}
            >
              Login
            </Button>
          </CardActions>
        </Card>
      </Box>
    </form>
  );
}

export function MFAView({
  mfaSetupRequired,
  onSetupMfa,
  onMfaCode,
  loading,
  setError,
}: CommonProps & {
  onSetupMfa: (mfaCode: string) => void;
  mfaSetupRequired: null | string;
  onMfaCode: (mfaCode: string) => void;
}) {
  const [mfaCode, setMfaCode] = useState<null | string>(null);

  const handleMfaSubmit: React.FormEventHandler<HTMLFormElement> = async (
    e,
  ) => {
    e.preventDefault();

    if (!mfaCode) {
      // shouldn't happen as UI prevents getting this far
      setError("MFA code not provided");
      return;
    }

    if (!mfaSetupRequired) {
      onMfaCode(mfaCode);
    } else {
      onSetupMfa(mfaCode);
    }
    setMfaCode(null);
  };

  return (
    <form onSubmit={handleMfaSubmit}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            minWidth: 300,
            maxWidth: 400,

            padding: "4em",
            marginTop: "6em",
          }}
        >
          <Box style={{ backgroundColor: "#003e56" }}>
            <img src={logo} title="Advarra Connect logo" />
          </Box>
          {mfaSetupRequired && (
            <Box>
              <Typography>
                Scan the following QR code with your authenticator device/app:
              </Typography>
              <QRCodeSVG id="mfaSetupCode" value={mfaSetupRequired} />,
            </Box>
          )}
          <Box>
            <TextField
              id="mfaCode"
              name="mfaCode"
              fullWidth={false}
              type="text"
              label="MFA Code"
              required
              onChange={(e) => setMfaCode(e.target.value)}
              value={mfaCode || ""}
              autoFocus
              autoComplete="off"
              disabled={loading}
            />
          </Box>
          <CardActions>
            {loading && <CircularProgress />}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={loading || !mfaCode}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
      </Box>
    </form>
  );
}

export function SetNewPassword({
  onSetPassword,
  loading,
  setError,
}: CommonProps & {
  onSetPassword: (password: string) => void;
}) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");

  const handlePasswordResetSubmit: React.FormEventHandler<
    HTMLFormElement
  > = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmedPassword) {
      setError("Passwords do not match");
      return;
    }

    setNewPassword("");
    setConfirmedPassword("");

    onSetPassword(newPassword);
  };

  return (
    <form onSubmit={handlePasswordResetSubmit}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            minWidth: 300,
            maxWidth: 400,

            padding: "4em",
            marginTop: "6em",
          }}
        >
          <Box style={{ backgroundColor: "#003e56" }}>
            <img src={logo} title="Advarra Connect logo" />
          </Box>
          <Box>
            <TextField
              id="password"
              name="password"
              fullWidth={false}
              type="password"
              label="New Password"
              value={newPassword}
              required
              onChange={(e) => setNewPassword(e.target.value)}
              autoFocus
            />
          </Box>
          <Box>
            <TextField
              id="confirmPassword"
              name="confirmedPassword"
              fullWidth={false}
              type="password"
              label="Confirm New Password"
              value={confirmedPassword}
              required
              onChange={(e) => setConfirmedPassword(e.target.value)}
            />
          </Box>

          <CardActions>
            {loading && <CircularProgress />}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!newPassword || loading || !confirmedPassword}
            >
              Set New Password
            </Button>
          </CardActions>
        </Card>
      </Box>
    </form>
  );
}

export function ChangePassword({
  onChange,
  setError,
  loading,
}: CommonProps & {
  onChange: (
    username: string,
    password: string,
    confirmedPassword: string,
    mfaCode: string,
  ) => void;
}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [mfaCode, setMfaCode] = useState("");

  const handlePasswordResetSubmit: React.FormEventHandler<
    HTMLFormElement
  > = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmedPassword) {
      setError("Passwords do not match");
      return;
    }

    if (newPassword === password) {
      setError("New password should be different from the old one");
      return;
    }

    if (!mfaCode) {
      // shouldn't happen as UI prevents getting this far
      setError("MFA code not provided");
      return;
    }

    onChange(username, password, newPassword, mfaCode);
  };

  return (
    <form onSubmit={handlePasswordResetSubmit}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            minWidth: 300,
            maxWidth: 400,

            padding: "4em",
            marginTop: "6em",
          }}
        >
          <Box style={{ backgroundColor: "#003e56" }}>
            <img src={logo} title="Advarra Connect logo" />
          </Box>
          <Box>
            <TextField
              id="loginUsername"
              name="username"
              fullWidth={false}
              type="text"
              value={username}
              label="Username"
              required
              onChange={(e) => setUsername(e.target.value)}
              autoFocus
            />
          </Box>
          <Box>
            <TextField
              id="oldPassword"
              name="oldPassword"
              fullWidth={false}
              type="password"
              label="Old Password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          <Box>
            <TextField
              id="password"
              name="password"
              fullWidth={false}
              type="password"
              label="New Password"
              value={newPassword}
              required
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Box>
          <Box>
            <TextField
              id="confirmPassword"
              name="confirmedPassword"
              fullWidth={false}
              type="password"
              label="Confirm New Password"
              value={confirmedPassword}
              required
              onChange={(e) => setConfirmedPassword(e.target.value)}
            />
          </Box>
          <Box>
            <TextField
              id="mfaCode"
              name="mfaCode"
              fullWidth={false}
              type="text"
              label="MFA Code"
              value={mfaCode || ""}
              required
              onChange={(e) => setMfaCode(e.target.value)}
              autoComplete="off"
            />
          </Box>

          <CardActions>
            {loading && <CircularProgress />}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={
                !username ||
                !newPassword ||
                loading ||
                !confirmedPassword ||
                !mfaCode
              }
            >
              Set New Password
            </Button>
          </CardActions>
        </Card>
      </Box>
    </form>
  );
}
