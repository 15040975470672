import React, { FC } from "react";

import {
  Datagrid,
  FilterProps,
  ListProps,
  Filter,
  BooleanField,
  TextField,
  TextInput,
  SelectInput,
  ReferenceInput,
  BooleanInput,
  EditButton,
  ShowButton,
  usePermissions,
  useRecordContext,
} from "react-admin";
import DateField from "../../fields/DateField";
import CustomerInput from "../../common/CustomerInput";
import List from "../List";

import AvatarField from "../../fields/AvatarField";
import { getPermissions, Resources, PermissionStatus } from "../../permissions";
import CustomersField from "./CustomersField";

const ListFilter = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <TextInput source="name" />

    <BooleanInput source="reference" />

    <ReferenceInput
      source="integrationType"
      reference="IntegrationType"
      label="Integration Type"
    >
      <SelectInput label="Integration Type" optionText="name" helperText="" />
    </ReferenceInput>

    <TextInput source="lastUpdatedBy" />

    <CustomerInput
      alwaysOn
      label="Customer"
      source="organizationId"
      reference="Organization"
    />
  </Filter>
);

const EditOrShowButton: FC<any> = ({ canUpdate, ...rest }) => {
  const record = useRecordContext();
  if (canUpdate && !record?.hasActiveIntegrations) {
    return (
      <>
        <ShowButton record={record} {...rest} />
        <EditButton record={record} {...rest} />
      </>
    );
  } else {
    return <ShowButton record={record} {...rest} />;
  }
};

const MappingList: FC<ListProps> = (props) => {
  const { permissions } = usePermissions();
  const { canUpdate, canCreate } = getPermissions(
    permissions,
    Resources.Mapping,
  );
  return (
    <List
      {...props}
      filters={<ListFilter />}
      sort={{ field: "name", order: "ASC" }}
      title="Attribute Mappings"
      hasCreate={canCreate}
      aside={
        <PermissionStatus
          canCreate={canCreate}
          canUpdate={canUpdate}
          resourceNamePlural="mappings"
          resourceName="mapping"
        />
      }
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" />

        <BooleanField source="reference" />
        <BooleanField source="hasActiveIntegrations" />

        <TextField label="Integration Type" source="integrationType.name" />

        <CustomersField label="Customers" sortable={false} />

        <DateField source="created" />

        <DateField source="updated" />

        <AvatarField label="Last updated by" source="lastUpdatedBy" />

        <EditOrShowButton canUpdate={canUpdate} />
      </Datagrid>
    </List>
  );
};

export default MappingList;
