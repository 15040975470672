import React, { FC } from "react";
import {
  ListProps,
  TextField,
  Datagrid,
  Filter,
  FilterProps,
  TextInput,
  BooleanField,
  FunctionField,
  DateInput,
} from "react-admin";
import DateField from "../../fields/DateField";
import CustomerIntegrationInput from "../../common/CustomerIntegrationInput";
import List from "../List";
import ActiveStatusInput from "../../common/ActiveStatusInput";

export const ListFilter = (
  props: Omit<FilterProps, "children">,
): JSX.Element => (
  <Filter {...props}>
    <TextInput source="ssuId" label="SSU_ID" />
    <TextInput source="proId" label="PRO_ID" />
    <TextInput source="protocolNumber" label="Customer Protocol Number" />
    <CustomerIntegrationInput
      label="Customer Integration"
      source="customerIntegration"
      reference="OrgIntegration"
    />
    <TextInput source="submittingOrg" label="Protocol Submitting Org" />
    <TextInput source="piOrg" label="Site PI_ORG" />
    <TextInput source="etmfStudyId" label="Customer Study ID" />
    <TextInput source="etmfSiteId" label="Customer Site ID" />
    <DateInput label="Last Scheduled Date" source="lastScheduledDate" />
    <ActiveStatusInput label="Active Status" source="isActive" alwaysOn />
  </Filter>
);

const SiteXrefList: FC<ListProps> = (props) => (
  <List
    {...props}
    title="Site Xref List"
    filters={<ListFilter />}
    sort={{ field: "created", order: "DESC" }}
    filterDefaultValues={{ isActive: true }}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="proId" label="PRO_ID" />
      <TextField source="ssuId" label="SSU_ID" />
      <TextField source="protocolNumber" label="Customer Protocol Number" />
      <FunctionField
        sortable={true}
        source="customerIntegration"
        label="Customer Integration"
        render={(record: any) =>
          record && record.customerIntegration ? (
            <span>
              {record.customerIntegration.name} (
              {record.customerIntegration.integrationTypeId})
            </span>
          ) : null
        }
      />

      <TextField
        source="submittingOrg"
        label="Protocol Submitting Org"
        sortable={true}
      />
      <TextField source="piOrg" label="Site PI_ORG" sortable={true} />
      <TextField
        source="etmfStudyId"
        label="Customer Study ID"
        sortable={true}
      />
      <TextField source="etmfSiteId" label="Customer Site ID" sortable={true} />
      <DateField
        source="lastScheduledDate"
        label="Last Scheduled Date"
        sortable={true}
      />
      <BooleanField source="isActive" label="Active Status" sortable={true} />
    </Datagrid>
  </List>
);

export default SiteXrefList;
