import React from "react";
import {
  AppBar,
  AppBarProps,
  UserMenuProps,
  UserMenu,
  Logout,
  usePermissions,
  ToggleThemeButton,
} from "react-admin";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

import logo from "../Logo.svg";

import { lightTheme, darkTheme } from "./theme";

const useStyles = makeStyles<Theme>({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  logo: {
    width: 100,
    marginRight: 10,
  },
});

const MyUserMenu: React.ComponentType<UserMenuProps> = (props) => {
  const { isLoading, permissions } = usePermissions();
  return (
    <UserMenu {...props}>
      <MenuItem title="Roles">
        {isLoading ? (
          "Loading..."
        ) : (
          <div>
            <Typography variant="h6" color="inherit">
              Roles
            </Typography>
            <ul>
              {permissions.roles.map((r: string) => (
                <li key={r}>{r}</li>
              ))}
            </ul>
          </div>
        )}
      </MenuItem>

      <Logout />
    </UserMenu>
  );
};

const MyAppBar: React.ComponentType<AppBarProps> = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props} userMenu={<MyUserMenu />}>
      <img src={logo} alt="Advarra logo" className={classes.logo} />
      {window.ENV !== "prod" && (
        <Typography variant="h4" color="error">
          [{window.ENV}]&nbsp;
        </Typography>
      )}
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />

      <ToggleThemeButton lightTheme={lightTheme} darkTheme={darkTheme} />
    </AppBar>
  );
};

export default MyAppBar;
