import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {},

  action: {
    margin: theme.spacing(1),
  },
  addButton: {
    display: "inline-block",
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },

  schemaContents: {
    marginLeft: theme.spacing(3),
    borderLeft: `4px solid ${theme.palette.divider}`,
  },
  description: {},
  textInput: {
    width: theme.spacing(50),
    marginLeft: theme.spacing(1),
  },
  selectInput: {
    minWidth: theme.spacing(20),
    marginLeft: theme.spacing(1),
  },
  textArea: {
    width: theme.spacing(50),
    marginLeft: theme.spacing(1),
    resize: "both",
  },
  booleanInput: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },

  radioGroup: {
    display: "inline",
  },

  filled: {
    marginLeft: theme.spacing(1),
    transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    backgroundColor: "rgba(0, 0, 0, 0.09)",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    marginBottom: theme.spacing(1),
  },
  fieldEditor: {},
  autoComplete: {
    width: "50em",
    display: "inline",
  },
  rule: {},

  schemasTitle: {
    fontSize: "1.2em",
    fontWeight: "bold",
    float: "left",
  },
  schemasPagination: {
    background: "white",
    paddingTop: "40px",
    paddingBottom: "40px",
  },
}));

export default useStyles;
