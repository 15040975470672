import React, { FC } from "react";

import { DropzoneArea } from "react-mui-dropzone";

import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface ImportConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onImport: (file: File) => void;
}

const ImportConfirmationDialog: FC<ImportConfirmationDialogProps> = ({
  open,
  onClose,
  onImport,
}) => {
  const [file, setFile] = React.useState<File | null>(null);
  const handleChange = (files: File[]): void => {
    if (files.length === 0) {
      setFile(null);
    } else {
      setFile(files[0]);
    }
  };

  const handleImport = (): void => {
    if (file === null) {
      throw new Error("file was null, but this should not be reachable.");
    }
    onImport(file);
  };

  const handleClose = (): void => {
    setFile(null);
    onClose();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm Action</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to import new schemas?{" "}
          <strong>Doing so will remove all existing schemas!</strong>
        </DialogContentText>
        <DropzoneArea
          dropzoneText="Drag and drop a file here or click"
          filesLimit={1}
          acceptedFiles={["text/html"]}
          showFileNames={true}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleImport} disabled={file === null}>
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportConfirmationDialog;
