import { serialize, parse } from "parse5";
import { Schema } from "./types";

type Artifact = string;
type HTMLTree = any;

type InternalFormat = any[];

export function exportArtifact(
  schemas: InternalFormat,
  username: string,
): Artifact {
  return serialize(
    constructDocument(
      constructSchemas(schemas),
      ...schemas.map(constructSchemaDetails),
      constructFooter(username),
    ),
  );
}

export function importArtifact(artifact: Artifact): InternalFormat {
  return parseDocument(artifact);
}

function parseDocument(html: string): InternalFormat {
  const body = (parse(html) as any).childNodes[1].childNodes[1].childNodes;

  const schemaTableRows = body[0].childNodes[2].childNodes;

  const schemas: Schema[] = [];

  for (const row of schemaTableRows) {
    const [precedenceStr, name, description, type] = row.childNodes
      .slice(1)
      .map((node: any) => node.childNodes[0]?.value ?? "");
    schemas.push({
      __typename: "MappingSchema",
      id: precedenceStr,
      created: new Date().toISOString(),
      precedence: +precedenceStr,
      name,
      description,
      type,
      fields: [],
      rules: [],
    });
  }

  for (const section of body.slice(1, body.length - 1)) {
    const schemaIndex = +section.attrs[0].value;

    const rulesTable = section.childNodes[0];
    const fieldsTable = section.childNodes[1];

    const rulesRows = rulesTable.childNodes[2].childNodes;

    schemas[schemaIndex].rules = rulesRows.map((row: any, index: number) => {
      const [fieldName, comparisonOperator, value] = row.childNodes.map(
        (x: any) => x.childNodes[0]?.value ?? "",
      );
      return {
        id: -index,
        fieldName,
        comparisonOperator,
        value,
        __typename: "SchemaRule",
      };
    });

    const fieldsRows = fieldsTable.childNodes[2].childNodes;

    schemas[schemaIndex].fields = fieldsRows.map((row: any, index: number) => {
      const [source, destinationFieldName, sourceType] = row.childNodes.map(
        (x: any) => x.childNodes[0]?.value ?? "",
      );
      return {
        id: -index,
        source,
        destinationFieldName,
        sourceType,
        __typename: "SchemaField",
      };
    });
  }

  return schemas;
}

function constructDocument(...childNodes: any[]): HTMLTree {
  return {
    nodeName: "#document",
    childNodes: [
      {
        nodeName: "#documentType",
        name: "html",
      },
      {
        nodeName: "html",
        childNodes: [
          {
            nodeName: "head",
            childNodes: [
              {
                nodeName: "style",
                tagName: "style",
                attrs: [],
                childNodes: [
                  {
                    nodeName: "#text",
                    value:
                      "caption { caption-side: top !important; font-weight: bold; font-size: 1.7rem; text-align: center !important; }",
                  },
                ],
              },
              {
                nodeName: "link",
                tagName: "link",
                childNodes: [],
                attrs: [
                  { name: "rel", value: "stylesheet" },
                  {
                    name: "href",
                    value:
                      "https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css",
                  },
                  {
                    name: "integrity",
                    value:
                      "sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2",
                  },
                  { name: "crossorigin", value: "anonymous" },
                ],
                namespaceURI: "http://www.w3.org/1999/xhtml",
              },
            ],
            tagName: "head",
            attrs: [],
            namespaceURI: "http://www.w3.org/1999/xhtml",
          },
          {
            nodeName: "body",
            childNodes,
            tagName: "body",
            attrs: [],
            namespaceURI: "http://www.w3.org/1999/xhtml",
          },
        ],
        tagName: "html",
        attrs: [],
        namespaceURI: "http://www.w3.org/1999/xhtml",
      },
    ],
    mode: "no-quirks",
  };
}

function constructSchemas(schemas: Schema[]): HTMLTree {
  return {
    nodeName: "table",
    childNodes: [
      {
        nodeName: "caption",
        childNodes: [
          {
            nodeName: "#text",
            value: "Schemas",
          },
        ],
        tagName: "caption",
        attrs: [],
        namespaceURI: "http://www.w3.org/1999/xhtml",
      },
      {
        nodeName: "thead",
        childNodes: [
          {
            nodeName: "tr",
            childNodes: [
              {
                nodeName: "th",
                childNodes: [
                  {
                    nodeName: "#text",
                    value: "Schema #",
                  },
                ],
                tagName: "th",
                attrs: [
                  {
                    name: "scope",
                    value: "col",
                  },
                ],
                namespaceURI: "http://www.w3.org/1999/xhtml",
              },
              {
                nodeName: "th",
                childNodes: [
                  {
                    nodeName: "#text",
                    value: "Precedence",
                  },
                ],
                tagName: "th",
                attrs: [
                  {
                    name: "scope",
                    value: "col",
                  },
                ],
                namespaceURI: "http://www.w3.org/1999/xhtml",
              },
              {
                nodeName: "th",
                childNodes: [
                  {
                    nodeName: "#text",
                    value: "Name",
                  },
                ],
                tagName: "th",
                attrs: [
                  {
                    name: "scope",
                    value: "col",
                  },
                ],
                namespaceURI: "http://www.w3.org/1999/xhtml",
              },
              {
                nodeName: "th",
                childNodes: [
                  {
                    nodeName: "#text",
                    value: "Description",
                  },
                ],
                tagName: "th",
                attrs: [
                  {
                    name: "scope",
                    value: "col",
                  },
                ],
                namespaceURI: "http://www.w3.org/1999/xhtml",
              },
              {
                nodeName: "th",
                childNodes: [
                  {
                    nodeName: "#text",
                    value: "Type",
                  },
                ],
                tagName: "th",
                attrs: [
                  {
                    name: "scope",
                    value: "col",
                  },
                ],
                namespaceURI: "http://www.w3.org/1999/xhtml",
              },
              {
                nodeName: "th",
                childNodes: [
                  {
                    nodeName: "#text",
                    value: "# of rules",
                  },
                ],
                tagName: "th",
                attrs: [
                  {
                    name: "scope",
                    value: "col",
                  },
                ],
                namespaceURI: "http://www.w3.org/1999/xhtml",
              },
              {
                nodeName: "th",
                childNodes: [
                  {
                    nodeName: "#text",
                    value: "# of fields",
                  },
                ],
                tagName: "th",
                attrs: [
                  {
                    name: "scope",
                    value: "col",
                  },
                ],
                namespaceURI: "http://www.w3.org/1999/xhtml",
              },
            ],
            tagName: "tr",
            attrs: [],
            namespaceURI: "http://www.w3.org/1999/xhtml",
          },
        ],
        tagName: "thead",
        attrs: [],
        namespaceURI: "http://www.w3.org/1999/xhtml",
      },
      {
        nodeName: "tbody",
        childNodes: schemas.map((schema, index) => ({
          nodeName: "tr",
          childNodes: [
            {
              nodeName: "td",
              childNodes: [
                {
                  nodeName: "#text",
                  value: (index + 1).toString(),
                },
              ],
              tagName: "th",
              attrs: [{ name: "scope", value: "row" }],
              namespaceURI: "http://www.w3.org/1999/xhtml",
            },
            {
              nodeName: "td",
              childNodes: [
                {
                  nodeName: "#text",
                  value: schema.precedence.toString(),
                },
              ],
              tagName: "td",
              attrs: [],
              namespaceURI: "http://www.w3.org/1999/xhtml",
            },
            {
              nodeName: "td",
              childNodes: [
                {
                  nodeName: "#text",
                  value: schema.name || "",
                },
              ],
              tagName: "td",
              attrs: [],
              namespaceURI: "http://www.w3.org/1999/xhtml",
            },
            {
              nodeName: "td",
              childNodes: [
                {
                  nodeName: "#text",
                  value: schema.description || "",
                },
              ],
              tagName: "td",
              attrs: [],
              namespaceURI: "http://www.w3.org/1999/xhtml",
            },
            {
              nodeName: "td",
              childNodes: [
                {
                  nodeName: "#text",
                  value: schema.type,
                },
              ],
              tagName: "td",
              attrs: [],
              namespaceURI: "http://www.w3.org/1999/xhtml",
            },
            {
              nodeName: "td",
              childNodes: [
                {
                  nodeName: "#text",
                  value: schema.rules!.length.toString(),
                },
              ],
              tagName: "td",
              attrs: [],
              namespaceURI: "http://www.w3.org/1999/xhtml",
            },
            {
              nodeName: "td",
              childNodes: [
                {
                  nodeName: "#text",
                  value: schema.fields!.length.toString(),
                },
              ],
              tagName: "td",
              attrs: [],
              namespaceURI: "http://www.w3.org/1999/xhtml",
            },
          ],
          tagName: "tr",
          attrs: [{ name: "id", value: index.toString() }],
          namespaceURI: "http://www.w3.org/1999/xhtml",
        })),
        tagName: "tbody",
        attrs: [],
        namespaceURI: "http://www.w3.org/1999/xhtml",
      },
    ],
    tagName: "table",
    attrs: [{ name: "class", value: "table table-striped" }],
    namespaceURI: "http://www.w3.org/1999/xhtml",
  };
}

function constructSchemaDetails(schema: Schema, index: number): HTMLTree {
  return {
    nodeName: "section",
    childNodes: [
      {
        nodeName: "table",
        childNodes: [
          {
            nodeName: "caption",
            childNodes: [
              {
                nodeName: "#text",
                value: `Schema ${index + 1} Rules`,
              },
            ],
            tagName: "caption",
            attrs: [],
            namespaceURI: "http://www.w3.org/1999/xhtml",
          },
          {
            nodeName: "thead",
            childNodes: [
              {
                nodeName: "tr",
                childNodes: [
                  {
                    nodeName: "th",
                    childNodes: [
                      {
                        nodeName: "#text",
                        value: "Field name",
                      },
                    ],
                    tagName: "th",
                    attrs: [
                      {
                        name: "scope",
                        value: "col",
                      },
                    ],
                    namespaceURI: "http://www.w3.org/1999/xhtml",
                  },
                  {
                    nodeName: "th",
                    childNodes: [
                      {
                        nodeName: "#text",
                        value: "Comparison operator",
                      },
                    ],
                    tagName: "th",
                    attrs: [
                      {
                        name: "scope",
                        value: "col",
                      },
                    ],
                    namespaceURI: "http://www.w3.org/1999/xhtml",
                  },
                  {
                    nodeName: "th",
                    childNodes: [
                      {
                        nodeName: "#text",
                        value: "Value",
                      },
                    ],
                    tagName: "th",
                    attrs: [
                      {
                        name: "scope",
                        value: "col",
                      },
                    ],
                    namespaceURI: "http://www.w3.org/1999/xhtml",
                  },
                ],
                tagName: "tr",
                attrs: [],
                namespaceURI: "http://www.w3.org/1999/xhtml",
              },
            ],
            tagName: "thead",
            attrs: [],
            namespaceURI: "http://www.w3.org/1999/xhtml",
          },
          {
            nodeName: "tbody",
            childNodes: schema.rules!.map((rule) => ({
              nodeName: "tr",
              childNodes: [
                {
                  nodeName: "td",
                  childNodes: [
                    {
                      nodeName: "#text",
                      value: rule.fieldName || "",
                    },
                  ],
                  tagName: "td",
                  attrs: [],
                  namespaceURI: "http://www.w3.org/1999/xhtml",
                },
                {
                  nodeName: "td",
                  childNodes: [
                    {
                      nodeName: "#text",
                      value: rule.comparisonOperator || "",
                    },
                  ],
                  tagName: "td",
                  attrs: [],
                  namespaceURI: "http://www.w3.org/1999/xhtml",
                },
                {
                  nodeName: "td",
                  childNodes: [
                    {
                      nodeName: "#text",
                      value: rule.value || "",
                    },
                  ],
                  tagName: "td",
                  attrs: [],
                  namespaceURI: "http://www.w3.org/1999/xhtml",
                },
              ],
              tagName: "tr",
              attrs: [],
              namespaceURI: "http://www.w3.org/1999/xhtml",
            })),
            tagName: "tbody",
            attrs: [],
            namespaceURI: "http://www.w3.org/1999/xhtml",
          },
        ],
        tagName: "table",
        attrs: [
          {
            name: "role",
            value: "rules",
          },
          { name: "class", value: "table table-striped" },
        ],
        namespaceURI: "http://www.w3.org/1999/xhtml",
      },
      {
        nodeName: "table",
        childNodes: [
          {
            nodeName: "caption",
            childNodes: [
              {
                nodeName: "#text",
                value: `Schema ${index + 1} Fields`,
              },
            ],
            tagName: "caption",
            attrs: [],
            namespaceURI: "http://www.w3.org/1999/xhtml",
          },
          {
            nodeName: "thead",
            childNodes: [
              {
                nodeName: "tr",
                childNodes: [
                  {
                    nodeName: "th",
                    childNodes: [
                      {
                        nodeName: "#text",
                        value: "Source",
                      },
                    ],
                    tagName: "th",
                    attrs: [
                      {
                        name: "scope",
                        value: "col",
                      },
                    ],
                    namespaceURI: "http://www.w3.org/1999/xhtml",
                  },
                  {
                    nodeName: "th",
                    childNodes: [
                      {
                        nodeName: "#text",
                        value: "Destination field name",
                      },
                    ],
                    tagName: "th",
                    attrs: [
                      {
                        name: "scope",
                        value: "col",
                      },
                    ],
                    namespaceURI: "http://www.w3.org/1999/xhtml",
                  },
                  {
                    nodeName: "th",
                    childNodes: [
                      {
                        nodeName: "#text",
                        value: "Source type",
                      },
                    ],
                    tagName: "th",
                    attrs: [
                      {
                        name: "scope",
                        value: "col",
                      },
                    ],
                    namespaceURI: "http://www.w3.org/1999/xhtml",
                  },
                ],
                tagName: "tr",
                attrs: [],
                namespaceURI: "http://www.w3.org/1999/xhtml",
              },
            ],
            tagName: "thead",
            attrs: [],
            namespaceURI: "http://www.w3.org/1999/xhtml",
          },
          {
            nodeName: "tbody",
            childNodes: schema.fields!.map((field) => ({
              nodeName: "tr",
              childNodes: [
                {
                  nodeName: "td",
                  childNodes: [
                    {
                      nodeName: "#text",
                      value: field.source || "",
                    },
                  ],
                  tagName: "td",
                  attrs: [],
                  namespaceURI: "http://www.w3.org/1999/xhtml",
                },
                {
                  nodeName: "td",
                  childNodes: [
                    {
                      nodeName: "#text",
                      value: field.destinationFieldName || "",
                    },
                  ],
                  tagName: "td",
                  attrs: [],
                  namespaceURI: "http://www.w3.org/1999/xhtml",
                },
                {
                  nodeName: "td",
                  childNodes: [
                    {
                      nodeName: "#text",
                      value: field.sourceType || "",
                    },
                  ],
                  tagName: "td",
                  attrs: [],
                  namespaceURI: "http://www.w3.org/1999/xhtml",
                },
              ],
              tagName: "tr",
              attrs: [],
              namespaceURI: "http://www.w3.org/1999/xhtml",
            })),
            tagName: "tbody",
            attrs: [],
            namespaceURI: "http://www.w3.org/1999/xhtml",
          },
        ],
        tagName: "table",
        attrs: [
          {
            name: "role",
            value: "fields",
          },
          { name: "class", value: "table table-striped" },
        ],
        namespaceURI: "http://www.w3.org/1999/xhtml",
      },
    ],
    tagName: "section",
    attrs: [
      {
        name: "id",
        value: index.toString(),
      },
    ],
    namespaceURI: "http://www.w3.org/1999/xhtml",
  };
}

function constructFooter(username: string): HTMLTree {
  return {
    nodeName: "footer",
    tagName: "footer",
    childNodes: [
      {
        nodeName: "#text",
        value: `Exported by ${username} on ${new Date().toLocaleString(
          "en-US",
          {
            timeZoneName: "short",
          },
        )}`,
      },
    ],
    attrs: [],
    namespaceURI: "http://www.w3.org/1999/xhtml",
  };
}
