import { downloadCSV, Exporter } from "react-admin";
import jsonExport from "jsonexport/dist";

const exporter: Exporter = async (
  data,
  _: unknown,
  __: unknown,
  resource: string | undefined,
): Promise<void> => {
  const dataForExport = data.map(
    (datum: { [x: string]: any; created?: any; updated?: any }) => {
      // Omit __typename
      const { __typename, ...datumForExport } = datum;
      // Handle fields we have that don't typically get shown in the UI,
      // like lastFileCreatedDate and terminatedDate
      for (const key of Object.keys(datumForExport)) {
        if (key.endsWith("Date") && datumForExport[key]) {
          const val = datumForExport[key];

          if (val.endsWith("T00:00:00.000Z")) {
            // Strip off nonsense time and just show "yyyy-mm-dd" date
            datumForExport[key] = val.replace("T00:00:00.000Z", "");
          } else {
            // if we have a time, assume that it's properly formatted
            // with a timezone so that Date can auto-convert it into the
            // local timezone.
            datumForExport[key] = new Date(val).toLocaleString("en-US", {
              timeZoneName: "short",
            });
          }
        }
      }

      datumForExport.created = new Date(datum.created).toLocaleString("en-US", {
        timeZoneName: "short",
      });
      if (datum.updated) {
        datumForExport.updated = new Date(datum.updated).toLocaleString(
          "en-US",
          {
            timeZoneName: "short",
          },
        );
      }

      return datumForExport;
    },
  );

  const csv: string = await new Promise((resolve, reject) => {
    jsonExport(
      dataForExport,
      {
        typeHandlers: {
          String: function (value: string) {
            // replace any initial = with space + = in any strings
            return value.replace(/^=/, " =");
          },
        },
      },
      (err: Error, data: string) => {
        if (err) {
          reject(err);
        }

        resolve(data);
      },
    );
  });

  downloadCSV(csv, resource || "");
};

export default exporter;
