import React, { FC } from "react";
import { SelectArrayInput, usePermissions } from "react-admin";
import {
  TextField,
  SelectInput,
  ReferenceArrayInput,
  TextInput,
  DateInput,
  Datagrid,
  Filter,
  FunctionField,
  FilterProps,
  ListProps,
} from "react-admin";
import DateField from "../../fields/DateField";
import CustomerIntegrationInput from "../../common/CustomerIntegrationInput";
import List from "../List";
import { getPermissions, Resources, PermissionStatus } from "../../permissions";
import ActiveStatusInput from "../../common/ActiveStatusInput";

export const ListFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Investigation Status"
      source="investigationStatus"
      options={{ style: { minWidth: "12rem" } }}
      alwaysOn
      choices={[
        { id: "NEW", name: "New" },
        { id: "FIXED", name: "Fixed" },
        { id: "INVESTIGATED", name: "Investigated" },
        { id: "DO NOT FIX", name: "Do not fix" },
      ]}
    />
    <ActiveStatusInput
      label="Is Active"
      source="isActive"
      alwaysOn
      trueLabel="Active"
      falseLabel="Inactive"
    />
    <CustomerIntegrationInput
      label="Customer Integration"
      source="customerIntegration"
      reference="OrgIntegration"
    />
    <ReferenceArrayInput
      source="integrationTypeId_not"
      reference="IntegrationType"
      label="Exclude Integration Type"
    >
      <SelectArrayInput
        label="Exclude Integration Type"
        options={{ style: { minWidth: "14rem" } }}
        optionText="name"
        helperText=""
      />
    </ReferenceArrayInput>
    <TextInput label="PI Last name" source="piLastName" />
    <TextInput label="PRO_ID" source="proId" />
    <TextInput source="ssuId" label="SSU_ID" />
    <TextInput source="submittingOrg" label="Protocol Submitting Org" />
    <TextInput source="piOrg" label="Site PI_ORG" />
    <SelectInput
      label="Found By Api"
      source="foundByApi"
      choices={[
        { id: "TRUE", name: "TRUE" },
        { id: "FALSE", name: "FALSE" },
        { id: "NULL", name: "NULL" },
      ]}
    />
    <DateInput source="updated" label="Updated" />
    <TextInput source="protocolNumber" label="Protocol Number" />
    <TextInput source="ssuState" label="IRB SSU State" />
    <TextInput source="responsible" label="Responsible Party" />
  </Filter>
);

export const SiteErrorConfigurationList: FC<ListProps> = (props) => {
  const { permissions } = usePermissions();
  const { canUpdate } = getPermissions(permissions, Resources.UnconfiguredSite);

  return (
    <List
      {...props}
      filters={<ListFilter />}
      sort={{ field: "created", order: "DESC" }}
      title="Unconfigured Sites"
      aside={
        <PermissionStatus
          canCreate={true}
          canUpdate={canUpdate}
          resourceNamePlural="unconfigured sites"
          resourceName="unconfigured site"
        />
      }
      filterDefaultValues={{
        investigationStatus: "NEW",
        isActive: true,
      }}
    >
      <Datagrid rowClick={canUpdate ? "edit" : ""} bulkActionButtons={false}>
        <TextField source="ssuId" label="SSU_ID" sortable={true} />
        <TextField source="proId" label="PRO_ID" sortable={false} />
        <TextField
          source="protocolNumber"
          label="Protocol Number"
          sortable={true}
        />
        <TextField source="ssuState" label="IRB SSU State" sortable={true} />
        <FunctionField
          sortable={true}
          source="customerIntegration"
          label="Customer Integration"
          render={(record: any) =>
            record && record.customerIntegration ? (
              <span>
                {record.customerIntegration.name} (
                {record.customerIntegration.integrationTypeId})
              </span>
            ) : null
          }
        />
        <TextField
          source="submittingOrg"
          label="Protocol Submitting Org"
          sortable={true}
        />
        <TextField source="piOrg" label="Site PI_ORG" sortable={true} />
        <TextField source="environment" label="Environment" sortable={false} />
        <TextField source="piLastName" label="PI Last" sortable={true} />

        <TextField
          source="investigationStatus"
          label="Investigation Status"
          sortable={false}
        />
        <TextField
          source="responsible"
          label="Responsible Party"
          sortable={true}
        />
        <TextField source="reason" sortable={false} />

        <DateField source="created" sortable={false} />
        <DateField source="updated" sortable={true} />
      </Datagrid>
    </List>
  );
};

export default SiteErrorConfigurationList;
