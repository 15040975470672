import { FC } from "react";
import {
  Permissions,
  Permission as APIPermission,
  PermissionType,
  User,
} from "@advarra/connect-graphql/lib/types";
import Alert from "@mui/material/Alert";
import { Identifier } from "react-admin";

export type { Permissions, User };
export { PermissionType };

export type Permission = APIPermission & {
  id: Identifier;
};

export enum Resources {
  CustomerIntegration = "CustomerIntegration",
  Mapping = "Mapping",
  AuditTrail = "AuditTrail",
  Error = "Error",
  CustomerCTMSRecord = "CustomerCTMSRecord",
  ProXref = "ProXref",
  SiteXref = "SiteXref",
  UnconfiguredProtocol = "UnconfiguredProtocol",
  UnconfiguredSite = "UnconfiguredSite",
  User = "User",
  Access = "Access",
  Document = "Document",
  Protocol = "Protocol",
  Site = "Site",
  Entity = "Entity",
  ERegInstance = "ERegInstance",
  Job = "Job",
  Transaction = "Transaction",
}

export function checkPermission(
  permissions: Permissions | undefined,
  type: PermissionType,
  resource: string | undefined,
): boolean {
  if (permissions === undefined) {
    return false;
  }

  // compatibility: if we haven't defined the permission resource, allow access
  if (resource === undefined) {
    return true;
  }

  if (
    permissions.permissions.find(
      (perm) => perm.resource === resource && perm.type === type,
    )
  ) {
    return true;
  }

  // deny by default
  return false;
}

interface UIPermissions {
  canCreate: boolean;
  canUpdate: boolean;
}

export function getPermissions(
  permissions: Permissions | undefined,
  resource: string,
): UIPermissions {
  const canCreate = checkPermission(
    permissions,
    PermissionType.Create,
    resource,
  );
  const canUpdate = checkPermission(
    permissions,
    PermissionType.Update,
    resource,
  );

  return { canCreate, canUpdate };
}

export const PermissionStatus: FC<
  UIPermissions & { resourceName: string; resourceNamePlural: string }
> = ({ canCreate, canUpdate, resourceName, resourceNamePlural }) => {
  return !canCreate || !canUpdate ? (
    <div style={{ width: 200, margin: "1em" }}>
      {!canUpdate && (
        <Alert severity="warning">
          You <em>do not have permission to edit</em> {resourceNamePlural}
        </Alert>
      )}
      {!canCreate && (
        <Alert severity="warning">
          You <em>do not have permission to create</em> a new {resourceName}
        </Alert>
      )}
    </div>
  ) : null;
};
