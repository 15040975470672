import React from "react";
import { Admin, Resource } from "react-admin";

import { raDataProvider } from "ra-data-graphql";

import authProvider from "./authProvider";
import resources from "./resources";
import * as views from "./views";
import { Menu, Layout, lightTheme } from "./layout";
import { checkPermission, Permissions, PermissionType } from "./permissions";
import LoginPage from "./LoginPage";

interface Props {
  dataProvider: typeof raDataProvider;
}

async function fetchResources(permissions: Permissions) {
  return resources.map(
    ({ name, list, edit, show, create, options, permissionResource }) => (
      <Resource
        name={name}
        list={
          list &&
          checkPermission(
            permissions,
            PermissionType.List,
            permissionResource,
          ) &&
          (views as any)[list]
        }
        edit={
          edit &&
          checkPermission(
            permissions,
            PermissionType.Update,
            permissionResource,
          ) &&
          (views as any)[edit]
        }
        show={show && (views as any)[show]}
        create={
          create &&
          checkPermission(
            permissions,
            PermissionType.Create,
            permissionResource,
          ) &&
          (views as any)[create]
        }
        options={options}
      />
    ),
  );
}

export default function ({ dataProvider }: Props): JSX.Element {
  return (
    <Admin
      theme={lightTheme}
      layout={Layout}
      menu={Menu}
      loginPage={LoginPage}
      dashboard={views.Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      {fetchResources}
    </Admin>
  );
}
