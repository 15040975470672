const fieldOptions = [
  "DOC_ID",
  "PRO_ID",
  "SSU_ID",
  "MOD_ID",
  "CR_ID",
  "PARENT_MOD_ID",
  "PARENT_PRE_ID",
  "DOC_CATEGORY",
  "DOC_CIRBI_ID",
  "DOC_S3_ID",
  "FILE_CREATED_DT",
  "FILE_NAME",
  "FILE_PROCESSED_DT",
  "FILE_SIZE",
  "POST_PROC_REQD",
  "UPLOAD_DT",
  "REV_NO",
  "UPDATED_DT",
  "PRE_ID",
  "SUBMISSION_DT",
  "APPROVAL_DT",
  "EXPIRATION_DT",
  "EVENT_DT",
  "file_name_orig",
  "TERMINATED_DT",
  "PI_FIRST_NAME",
  "PI_LAST_NAME",
  "TYPE_OF_MATERIAL",
  "MATERIAL_TYPE_NAME",
  "MATERIAL_CATEGORY",
  "MATERIAL_TYPE",
  "MATERIAL_TYPE_OTHER",
  "DOC_SUB_TYPE",
  "ICF_TYPE",
  "ICF_LANGUAGE",
  "ICF_VERSION",
  "TRANSLATION_TYPE",
  "TRANSLATOR",
  "RECRUITMENT_STATUS",
  "icPostedDate",
  "icStudyId",
  "icProductId",
  "icStudyCountryId",
  "icSiteId",
  "icGeneratedDocId",
  "icPIName",
  "icCleanedFileName",
  "SOURCE",
  "$.documentMetadata.",
  "$.protocolMetadata.",
];

export default fieldOptions;
