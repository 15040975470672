import React from "react";
import { useRecordContext } from "react-admin";

export const LambdaLinkField = (props: any) => {
  const record = useRecordContext(props);
  const name = record[props.source];

  if (name) {
    return (
      <a
        href={
          "https://console.aws.amazon.com/lambda/home?region=us-east-1#/functions/" +
          name
        }
      >
        {name}
      </a>
    );
  }

  return null;
};

export const StepFunctionLinkField = (props: any) => {
  const record = useRecordContext(props);
  const arn = record[props.source];

  if (arn) {
    const parts = arn.split(":");
    if (parts.length > 7) {
      const name = `${parts[6]}/${parts[7]}`;

      return (
        <a
          href={
            "https://console.aws.amazon.com/states/home?region=us-east-1#/executions/details/" +
            arn
          }
        >
          {name}
        </a>
      );
    } else {
      return arn;
    }
  }
  return null;
};
