import React, { FC } from "react";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import useStyles from "./useStyles";
import SchemaElementActions from "./SchemaElementActions";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ClassNameMap } from "@mui/material";

interface SchemaDescriptionEditorProps {
  precedence: number;
  name: string;
  description: string;
  type: string;

  index: number;

  onClone: (index: number) => void;
  onChange: (
    index: number,
    fieldName: string,
    value: null | string | number,
  ) => void;
  onRemove: (index: number) => void;
}

const SchemaDescriptionEditor: FC<SchemaDescriptionEditorProps> = (props) => {
  const classes = useStyles(props);

  return <SchemaDescriptionEditorInner {...props} classes={classes} />;
};

export const SchemaDescriptionEditorInner: FC<
  SchemaDescriptionEditorProps & { classes: ClassNameMap<string> }
> = React.memo(
  ({
    precedence,
    name,
    description,
    type,
    index,
    onClone,
    onChange,
    onRemove,
    classes,
  }) => {
    const invalidPrecedence =
      (precedence !== 0 && !precedence) || precedence.toString() === "";

    return (
      <div className={classes.description}>
        <TextField
          className={classes.textInput}
          name="precedence"
          label="Precedence"
          type="number"
          value={precedence}
          error={invalidPrecedence}
          helperText={invalidPrecedence ? "Precedence required" : " "}
          style={{ width: "6em" }}
          required
          onChange={(event): void => {
            const val = parseInt(event.target.value, 10);
            onChange(index, "precedence", isNaN(val) ? null : val);
          }}
          variant="filled"
        />

        <TextField
          className={classes.textInput}
          name="name"
          label="Name"
          required
          error={name === ""}
          helperText={name === "" ? "Must enter a name" : " "}
          value={name}
          onChange={(event): void =>
            onChange(index, "name", event.target.value)
          }
          variant="filled"
        />

        <TextField
          className={classes.textArea}
          name="description"
          label="Description"
          multiline
          rows={1}
          value={description}
          onChange={(event): void =>
            onChange(index, "description", event.target.value)
          }
          variant="filled"
        />

        <FormControl className={classes.booleanInput}>
          <FormControlLabel
            value="end"
            control={
              <Switch
                color="primary"
                checked={type === "Exclude"}
                onChange={(_event): void =>
                  onChange(
                    index,
                    "type",
                    type === "Exclude" ? "Include" : "Exclude",
                  )
                }
              />
            }
            label="Exclude?"
            labelPlacement="end"
          />
        </FormControl>

        <SchemaElementActions
          type="schema"
          onClone={(): void => onClone(index)}
          onRemove={(): void => onRemove(index)}
        />
      </div>
    );
  },
);

export default SchemaDescriptionEditor;
