import { FC } from "react";
import {
  FilterProps,
  Filter,
  ListProps,
  Datagrid,
  TextField,
  ArrayField,
  FunctionField,
  SelectInput,
  TextInput,
} from "react-admin";

import DateField from "../../fields/DateField";
import * as API from "@advarra/connect-graphql/lib/types";
import List from "../List";

const ListFilter = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <TextInput source="source" />
    <TextInput source="protocolExternalId" label="Protocol" />
    <TextInput source="siteExternalId" label="Site" />

    <TextInput source="transactionExternalId" label="Returned ID" />
    <TextInput source="orgName" label="Integration" />
    <SelectInput
      source="transactionStatus"
      choices={[
        { id: "Pending", name: "Pending" },
        { id: "Completed", name: "Completed" },
        { id: "HOLD", name: "HOLD" },
        { id: "Error", name: "Error" },
        { id: "Setup", name: "Setup" },
      ]}
    />

    <TextInput source="fileName" />
  </Filter>
);

const DocumentList: FC<ListProps> = (props) => {
  return (
    <List
      {...props}
      filters={<ListFilter />}
      sort={{ field: "created", order: "DESC" }}
      title="Documents"
    >
      <Datagrid
        bulkActionButtons={false}
        isRowExpandable={(row) => (row?.transactions || []).length > 0}
        rowClick="show"
        expand={
          <>
            <h3>Transactions</h3>
            <ArrayField source="transactions">
              <Datagrid bulkActionButtons={false}>
                <TextField source="externalId" label="Returned ID" />
                <FunctionField<API.Transaction>
                  render={(data) =>
                    data ? `${data.orgName} (${data.etmfName})` : ""
                  }
                  label="Integration"
                />
                <TextField source="status" />
                <DateField source="created" />
              </Datagrid>
            </ArrayField>
          </>
        }
      >
        <TextField source="source" label="Source" sortable={true} />
        <TextField
          source="protocolExternalId"
          label="Protocol"
          sortable={true}
        />
        <TextField source="siteExternalId" label="Site" sortable={true} />

        <TextField source="fileName" sortable={true} />

        <DateField
          sortable={true}
          source="fileCreatedDate"
          label="Document created on"
        />
        <DateField
          sortable={true}
          source="fileProcessedDate"
          label="Received by Connect on"
        />
      </Datagrid>
    </List>
  );
};

export default DocumentList;
