import { FC } from "react";
import {
  Edit,
  TextField,
  EditProps,
  NumberField,
  SimpleForm,
  SelectInput,
  useRecordContext,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  SaveButton,
  ToolbarProps,
  Identifier,
} from "react-admin";

import { TransactionStatus } from "@advarra/connect-constants";

import DateField from "../../fields/DateField";
import Labeled from "../../common/Labeled";

const choices = [
  {
    id: TransactionStatus.PENDING,
    name: "Pending",
    disabled: false,
  },
  {
    id: TransactionStatus.HOLD,
    name: "Hold",
    disabled: false,
  },
  {
    id: TransactionStatus.ERROR,
    name: "Error",
    disabled: false,
  },
  {
    id: TransactionStatus.INCOMPLETE,
    name: "Incomplete",
    disabled: true,
  },
  {
    id: TransactionStatus.COMPLETED,
    name: "Completed",
    disabled: false,
  },
];

const canEditStatuses = [
  TransactionStatus.PENDING,
  TransactionStatus.HOLD,
  TransactionStatus.ERROR,
];

const TransactionEdit: FC<EditProps> = (props) => {
  const saveRedirect = (
    _resource: string | undefined,
    _id: Identifier | undefined,
    data: any,
  ) => {
    return `Document/${data.docId}/show`;
  };

  return (
    <Edit {...props} redirect={saveRedirect}>
      <EditBody />
    </Edit>
  );
};

const EditBody = () => {
  const record = useRecordContext();
  const allowAttributesEdit = canEditStatuses.includes(record?.status);

  return (
    <SimpleForm toolbar={<EditToolbar />}>
      <Labeled>
        <NumberField source="id" label="ID" />
      </Labeled>
      <Labeled>
        <DateField source="created" label="Created On" />
      </Labeled>
      <Labeled>
        <DateField source="updated" label="Updated On" />
      </Labeled>
      <Labeled>
        <TextField source="externalId" label="Returned ID" />
      </Labeled>

      <SelectInput source="status" choices={choices} required />

      <ArrayInput source="attributes" disabled={!allowAttributesEdit}>
        <SimpleFormIterator inline>
          <TextInput source="name" helperText={false} required />
          <TextInput source="value" helperText={false} required />
        </SimpleFormIterator>
      </ArrayInput>

      <TextInput source="changeComment" required />
    </SimpleForm>
  );
};

// hide the Delete button
const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export default TransactionEdit;
