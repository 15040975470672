import React, { ChangeEvent, FC } from "react";

import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";

import { Field, MaybeEmptyPartial, SchemaChildType } from "../types";

import SchemaElementActions from "./SchemaElementActions";
import useStyles from "./useStyles";
import fieldOptions from "./fieldOptions";

interface FieldEditorProps {
  field: MaybeEmptyPartial<Field>;
  schemaIndex: number;
  index: number;
  onClone: (schemaIndex: number, type: SchemaChildType, index: number) => void;
  onChange: (
    schemaIndex: number,
    type: SchemaChildType,
    index: number,
    fieldName: string,
    value: string,
  ) => void;
  onRemove: (schemaIndex: number, type: SchemaChildType, index: number) => void;
}

const FieldEditor: FC<FieldEditorProps> = React.memo(
  ({ field, onClone, onChange, onRemove, schemaIndex, index, ...props }) => {
    const classes = useStyles(props);
    return (
      <div className={classes.fieldEditor}>
        <Autocomplete
          freeSolo
          options={fieldOptions}
          value={field.source}
          className={classes.autoComplete}
          renderInput={({ inputProps, ...params }): JSX.Element => (
            <TextField
              className={classes.textInput}
              autoComplete="off"
              name="source"
              required
              error={field.source === ""}
              helperText={field.source === "" ? "Must enter a source" : " "}
              variant="filled"
              label="Source"
              inputProps={{ ...inputProps, maxLength: 255 }}
              {...params}
              style={{ width: "15em" }}
              fullWidth={false}
            />
          )}
          onChange={(
            event: ChangeEvent<unknown>,
            newValue: string | null,
          ): void =>
            onChange(schemaIndex, "field", index, "source", newValue ?? "")
          }
          onInputChange={(
            event: ChangeEvent<unknown>,
            newValue: string,
          ): void => onChange(schemaIndex, "field", index, "source", newValue)}
        />
        <FormControl variant="filled" className={classes.filled}>
          <FormLabel component="legend">Source Type</FormLabel>
          <RadioGroup
            name="sourceType"
            className={classes.radioGroup}
            value={field.sourceType}
            onChange={(event): void =>
              onChange(
                schemaIndex,
                "field",
                index,
                "sourceType",
                event.target.value,
              )
            }
          >
            <FormControlLabel
              value="ATTRIBUTE"
              control={<Radio />}
              label="ATTRIBUTE"
            />
            <FormControlLabel
              value="CONSTANT"
              control={<Radio />}
              label="CONSTANT"
            />
          </RadioGroup>
        </FormControl>

        <TextField
          className={classes.textInput}
          autoComplete="off"
          name="destinationFieldName"
          label="Destination Field Name"
          value={field.destinationFieldName}
          onChange={(event): void =>
            onChange(
              schemaIndex,
              "field",
              index,
              "destinationFieldName",
              event.target.value,
            )
          }
          required
          error={field.destinationFieldName === ""}
          helperText={
            field.destinationFieldName === ""
              ? "Must enter a destination name"
              : " "
          }
          variant="filled"
        />
        <SchemaElementActions
          type="field"
          onClone={() => onClone(schemaIndex, "field", index)}
          onRemove={() => onRemove(schemaIndex, "field", index)}
        />
      </div>
    );
  },
);

export default FieldEditor;
