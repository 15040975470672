import React from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Avatar from "../common/Avatar";
import { DashboardUser as User } from "@advarra/connect-graphql/types";
import { InjectedFieldProps, Labeled, PublicFieldProps } from "react-admin";

interface Props extends PublicFieldProps, InjectedFieldProps {
  record?: { [key: string]: User | undefined };
}

const AvatarField = ({ source, record = {}, label }: Props): JSX.Element => (
  <Labeled label={label || ""}>
    {source !== undefined && record[source] ? (
      <List dense>
        <ListItem>
          <ListItemAvatar>
            <Avatar user={record[source]} />
          </ListItemAvatar>
          <ListItemText primary={record[source]?.username} />
        </ListItem>
      </List>
    ) : (
      <Typography>No one</Typography>
    )}
  </Labeled>
);

AvatarField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default AvatarField;
