import React, { FC } from "react";
import PropTypes from "prop-types";
import {
  SimpleForm,
  TextField,
  TextInput,
  BooleanField,
  SelectInput,
  ReferenceInput,
  Toolbar,
  SaveButton,
  ListButton,
  FormDataConsumer,
  FunctionField,
  useDataProvider,
  useNotify,
  TitleProps,
  usePermissions,
  useRecordContext,
  // Labeled,
} from "react-admin";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";

import DateField from "../../fields/DateField";
import { PermissionType, Resources, checkPermission } from "../../permissions";
import Edit from "../Edit";
import InvestigationNotes from "../../common/InvestigationNotes";
import * as API from "@advarra/connect-graphql/lib/types";
import CreateNewProXref, { ProXrefInput } from "./CreateNewProXref";
import Alert from "@mui/material/Alert";
import Labeled from "../../common/Labeled";

export const StudyErrorConfigurationTitle: FC<TitleProps> = () => {
  const record = useRecordContext();
  return (
    <span>
      Unconfigured Protocol Detail for {record ? `${record.proId}` : ""}
    </span>
  );
};

export const InvestigationStatus = ({
  investigationStatus,
}: {
  investigationStatus?: string;
}) => {
  const choices = [
    { id: "NEW", name: "NEW" },
    { id: "INVESTIGATED", name: "INVESTIGATED" },
    { id: "FIXED", name: "FIXED", disabled: investigationStatus !== "FIXED" },
    { id: "DO NOT FIX", name: "DO NOT FIX" },
  ];

  return (
    <SelectInput
      label="Investigation Status"
      source="investigationStatus"
      options={{ style: { minWidth: "12rem" } }}
      choices={choices}
    />
  );
};

export const CustomStudyErrorConfigurationToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton resource={props.basePath} icon={<Icon />} label="Cancel" />
  </Toolbar>
);

export const ProtocolNumber = ({ record }: { record?: API.StudyError }) => {
  if (!record) return null;

  const isPull =
    record.integration &&
    record.integration.metadataDirection === API.MetadataDirection.Pull;

  if (isPull) {
    return <TextInput source="protocolNumber" label="Protocol Number" />;
  } else {
    return (
      <TextInput disabled source="protocolNumber" label="Protocol Number" />
    );
  }
};

const NewProXref = (props: any) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const record = useRecordContext();

  const { isLoading, permissions } = usePermissions();

  if (isLoading) {
    return (
      <Paper>
        <CircularProgress size={16} thickness={3} color="secondary" />
      </Paper>
    );
  }

  const canCreate = checkPermission(
    permissions,
    PermissionType.Create,
    Resources.ProXref,
  );

  if (!canCreate) {
    return (
      <Paper>
        <Alert severity="warning">
          You <em>do not have permission to create</em> protocol xrefs.
        </Alert>
      </Paper>
    );
  }

  const createProXref = (input: ProXrefInput) => {
    dataProvider
      .create("ProXref", {
        data: {
          ...input,
          orgId: record.orgId,
          etmfId: record.integration.etmfId,
          protocolId: record.protocolId,
        },
      })
      .then(() => {
        // success side effects go here
        notify("New Pro Xref Created");
      })
      .catch((error: Error) => {
        // failure side effects go here
        notify(`New Pro Xref Created error: ${error.message}`, {
          type: "warning",
        });
      });
  };

  return (
    <Paper>
      <CreateNewProXref record={props.record} onConfirmation={createProXref} />
    </Paper>
  );
};

const StudyErrorConfigurationEdit = (
  props: PropTypes.InferProps<typeof Edit.propTypes>,
) => {
  const record = useRecordContext();

  return <TestableChunk {...props} record={record} />;
};

export const TestableChunk = (
  props: PropTypes.InferProps<typeof Edit.propTypes>,
) => {
  const { record } = props;

  return (
    <Edit
      title={<StudyErrorConfigurationTitle />}
      {...props}
      aside={<NewProXref />}
    >
      <SimpleForm
        toolbar={<CustomStudyErrorConfigurationToolbar />}
        warnWhenUnsavedChanges
      >
        <Labeled label="PRO_ID">
          <TextField fontSize={"1rem"} source="proId" />
        </Labeled>
        <Labeled label="Customer Integration">
          <FunctionField<API.StudyError>
            fontSize={"1rem"}
            sortable={true}
            source="customerIntegration"
            render={(record) =>
              record && record.customerIntegration ? (
                <span>
                  {record.customerIntegration.name} (
                  {record.customerIntegration.integrationTypeId})
                </span>
              ) : null
            }
          />
        </Labeled>
        <Labeled label="Created">
          <DateField fontSize={"1rem"} source="created" label="Created Date" />
        </Labeled>
        <Labeled label="Updated">
          <DateField fontSize={"1rem"} source="updated" label="Updated Date" />
        </Labeled>
        <Labeled label="IRB Project State">
          <TextField fontSize={"1rem"} source="projectState" />
        </Labeled>
        <ProtocolNumber {...props} />
        <Labeled label="Submitted Date">
          <TextField fontSize={"1rem"} source="submittedDate" />
        </Labeled>
        <Labeled label="Company Assignment">
          <TextField fontSize={"1rem"} source="companyAssignment" />
        </Labeled>
        <Labeled label="Protocol Submitting Org">
          <TextField fontSize={"1rem"} source="submittingOrg" />
        </Labeled>
        <Labeled label="Environment">
          <TextField fontSize={"1rem"} source="environment" />
        </Labeled>
        <InvestigationStatus
          investigationStatus={record && record.investigationStatus}
        />
        <Labeled label="Found by API">
          <BooleanField
            source="foundByApi"
            record={record}
            resource="StudyError"
          />
        </Labeled>
        <ReferenceInput
          source="reason"
          reference="StudyReasonType"
          resource="StudyError"
        >
          <SelectInput optionText="id" label="Error Reason" />
        </ReferenceInput>
        <SelectInput
          source="responsible"
          label="Responsible Party"
          choices={[
            { id: "Customer", name: "Customer" },
            { id: "Advarra", name: "Advarra" },
          ]}
        />
        <TextInput source="newInvestigationNotes" />
        <FormDataConsumer>
          {({ formData }: { formData: { investigationNotes: string } }) => (
            <Box p="1em">
              <InvestigationNotes
                text={formData && formData.investigationNotes}
              />
            </Box>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default StudyErrorConfigurationEdit;
