import React, { FC, useContext } from "react";
import {
  Create,
  CreateProps,
  SimpleForm,
  required,
  TextInput,
  BooleanInput,
  TopToolbar,
  CreateActionsProps,
  ReferenceInput,
  SelectInput,
  useNotify,
  useRedirect,
  DataProviderContext,
  CreateResult,
  RaRecord,
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { Button } from "@mui/material";

import PublishIcon from "@mui/icons-material/Publish";

import * as API from "@advarra/connect-graphql/types";

import AttributesEditor from "./SchemasEditor";
import ImportConfirmationDialog from "./ImportConfirmationDialog";
import { importArtifact } from "./exportArtifact";
import ChangeCommentToolbar from "../../common/ChangeCommentToolbar";

const MappingCreateActions: FC<CreateActionsProps> = (props) => {
  const [showImportDialog, setShowImportDialog] = React.useState(false);

  const form = useFormContext();

  const handleImportClick = (): void => setShowImportDialog(true);
  const handleCloseImportDialog = (): void => setShowImportDialog(false);
  const handleImport = (file: File): void => {
    file
      .text()
      .then((text) => form.setValue("schemas", importArtifact(text)))
      .then(() => {
        setShowImportDialog(false);
      })
      .catch((error) => {
        console.error("Failed to parse import file", error);
        alert(
          "Could not import uploaded file. Check that you have selected a file that was exported from the dashboard.",
        );
      });
  };

  return (
    <TopToolbar className={props.className}>
      <ImportConfirmationDialog
        open={showImportDialog}
        onClose={handleCloseImportDialog}
        onImport={handleImport}
      />
      <Button
        variant="contained"
        startIcon={<PublishIcon />}
        onClick={handleImportClick}
      >
        Import ...
      </Button>
    </TopToolbar>
  );
};

const Actions: FC<CreateActionsProps> = () => <div />;

const MappingCreate: FC<CreateProps> = (props) => {
  const dataProvider = useContext(DataProviderContext);
  const notify = useNotify();
  const redirect = useRedirect();
  const createRecord = async (data: Partial<API.CreateMappingInput>) => {
    const result = (await dataProvider.create("Mapping", {
      data,
    })) as CreateResult<RaRecord> & { warning: string | null };
    if (result.warning) {
      notify(result.warning, { type: "warning" });
    }

    if (data.customerIntegrationId) {
      redirect("edit", "/OrgIntegration", data.customerIntegrationId);
    } else {
      redirect("edit", "/Mapping", result.data.id);
    }
  };

  return (
    <Create {...props} actions={<Actions />}>
      <SimpleForm
        toolbar={<ChangeCommentToolbar create={true} label="Mapping" />}
        defaultValues={{ schemas: [] }}
        onSubmit={createRecord}
      >
        <MappingCreateActions />

        <TextInput source="name" validate={required()} />
        <TextInput source="description" multiline />
        <BooleanInput source="reference" />

        <ReferenceInput source="integrationType.id" reference="IntegrationType">
          <SelectInput
            label="Integration Type"
            optionText="name"
            helperText=""
            style={{ minWidth: "13em" }}
          />
        </ReferenceInput>

        <AttributesEditor name="schemas" />
      </SimpleForm>
    </Create>
  );
};

export default MappingCreate;
