import React from "react";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { Labeled, TextField as RATextField, ReferenceField } from "react-admin";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers";

export interface ProXrefInput {
  etmfStudyId: string;
  etmfProductId: string | undefined;
  etmfCountryId: string | undefined;
  initialTransferCompleteTimestamp: string | undefined;

  changeComment: string;
}

export default function CreateNewProXref({
  record,
  onConfirmation,
}: {
  record: any;
  onConfirmation: (input: ProXrefInput) => void;
}): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const [etmfStudyId, setEtmfStudyId] = React.useState<string | undefined>(
    undefined,
  );
  const [etmfProductId, setEtmfProductId] = React.useState<string | undefined>(
    undefined,
  );
  const [etmfCountryId, setEtmfCountryId] = React.useState<string | undefined>(
    undefined,
  );

  const [changeComment, setChangeComment] = React.useState<string | undefined>(
    undefined,
  );

  const [
    initialTransferCompleteTimestamp,
    setInitialTransferCompleteTimestamp,
  ] = React.useState<string | undefined>(undefined);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const reset = () => {
    setEtmfStudyId(undefined);
    setEtmfProductId(undefined);
    setEtmfCountryId(undefined);
    setInitialTransferCompleteTimestamp(undefined);
    setChangeComment(undefined);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    onConfirmation({
      etmfStudyId: etmfStudyId as string,
      etmfProductId,
      etmfCountryId,
      initialTransferCompleteTimestamp,

      changeComment: changeComment as string,
    });
    reset();
  };

  const invalid = !etmfStudyId || !changeComment;

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
      >
        New Pro Xref
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Create New Pro Xref</DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <Box>
              <Labeled label="Customer">
                <ReferenceField
                  source="orgId"
                  link={false}
                  reference="Organization"
                  record={record}
                  resource="StudyError"
                >
                  <RATextField source="name" />
                </ReferenceField>
              </Labeled>
              &nbsp;
              <Labeled label="PRO_ID">
                <RATextField
                  record={record}
                  resource="StudyError"
                  source="proId"
                />
              </Labeled>
            </Box>
            <Box>
              <TextField
                name="etmfStudyId"
                label="Study Id"
                required
                error={etmfStudyId === ""}
                helperText={etmfStudyId === "" ? "Must enter a study id" : " "}
                value={etmfStudyId || ""}
                onChange={(event): void => setEtmfStudyId(event.target.value)}
                variant="filled"
              />
            </Box>
            <Box>
              <TextField
                name="etmfProductId"
                label="Product Id"
                value={etmfProductId || ""}
                onChange={(event): void => setEtmfProductId(event.target.value)}
                variant="filled"
              />
              &nbsp;
              <TextField
                name="etmfCountryId"
                label="Country Id"
                value={etmfCountryId || ""}
                onChange={(event): void => setEtmfCountryId(event.target.value)}
                variant="filled"
              />
            </Box>
            <Box>
              <DateTimePicker
                // fullWidth
                label="Initial Transfer Completed Date"
                // margin="normal"
                value={initialTransferCompleteTimestamp || null}
                // emptyLabel="None"
                onChange={(date): void =>
                  setInitialTransferCompleteTimestamp(date ? date : undefined)
                }
              />
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Tooltip
            placement="top"
            arrow
            title={
              invalid
                ? "Study Id and change comment are required to proceed."
                : ""
            }
          >
            {/* the button must be wrapped with a span so that the tooltip will appear when the button is disabled */}
            <span>
              <Button
                onClick={handleConfirm}
                variant="outlined"
                color="primary"
                disabled={invalid}
              >
                Save
              </Button>
            </span>
          </Tooltip>
          <TextField
            required
            name="changeComment"
            placeholder="What did you change?"
            error={changeComment === ""}
            helperText={
              changeComment === "" ? "Must enter a change comment" : " "
            }
            margin="dense"
            value={changeComment || ""}
            onChange={(event): void => setChangeComment(event.target.value)}
          />
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
