import React, { FC } from "react";
import {
  Datagrid,
  Filter,
  TextField,
  TextInput,
  ReferenceInput,
  SelectInput,
  CheckboxGroupInput,
  ListProps,
  FilterProps,
} from "react-admin";
import DateField from "../../../fields/DateField";
import AvatarField from "../../../fields/AvatarField";
import DifferencesField from "../DifferencesField";

import * as API from "@advarra/connect-graphql/lib/types";
import CustomerInput from "../../../common/CustomerInput";
import List from "../../List";

export const ListFilter: FC<Omit<FilterProps, "children">> = (props) => (
  <Filter {...props}>
    <CustomerInput label="Customer" source="orgId" reference="Organization" />
    <ReferenceInput
      source="etmfId"
      reference="IntegrationType"
      label="Integration Type"
    >
      <SelectInput label="Integration Type" optionText="name" helperText="" />
    </ReferenceInput>
    <SelectInput
      label="Investigation Status"
      source="investigationStatus"
      options={{ style: { minWidth: "12rem" } }}
      choices={[
        { id: "NEW", name: "New" },
        { id: "FIXED", name: "Fixed" },
        { id: "INVESTIGATED", name: "Investigated" },
        { id: "DO NOT FIX", name: "Do not fix" },
      ]}
    />
    <TextInput label="PRO_ID" source="proId" />
    <TextInput label="Protocol Number" source="protocolNumber" />

    <TextInput label="PI First name" source="piFirstName" />
    <TextInput label="PI Last name" source="piLastName" />

    <TextInput label="SSU_ID" source="ssuId" />

    <CheckboxGroupInput
      source="sourceResources"
      label="Record Types"
      choices={[
        {
          name: "Customer CTMS",
          id: API.AuditTrailSourceResource.CustomerCtmsRecord,
        },
        {
          name: "Unconfigured Site",
          id: API.AuditTrailSourceResource.SiteError,
        },
        {
          name: "Unconfigured Protocol",
          id: API.AuditTrailSourceResource.StudyError,
        },
      ]}
    />
  </Filter>
);

const UnconfiguredAuditTrailList: FC<ListProps> = (props) => (
  <List
    {...props}
    filters={<ListFilter />}
    sort={{ field: "created", order: "DESC" }}
    filterDefaultValues={{
      sourceResources: [
        API.AuditTrailSourceResource.CustomerCtmsRecord,
        API.AuditTrailSourceResource.SiteError,
        API.AuditTrailSourceResource.StudyError,
      ],
    }}
    title="Unconfigured Protocols and Sites Audit Trail"
  >
    <Datagrid bulkActionButtons={false}>
      <DateField source="created" />
      <AvatarField source="user" label="User" />
      <TextField source="__typename" label="Record Type" />
      <TextField source="customerIntegration.name" label="Customer" />
      <TextField source="proId" label="PRO_ID" />
      <TextField source="ssuId" label="SSU_ID" />
      <TextField source="investigationStatus" />
      <TextField source="piFirstName" label="PI First Name" />
      <TextField source="piLastName" label="PI Last Name" />
      <TextField source="protocolNumber" label="Customer Protocol Number" />
      <DifferencesField label="Changes (before, after)" />
    </Datagrid>
  </List>
);

export default UnconfiguredAuditTrailList;
