import buildGraphQLProvider, { raDataProvider } from "ra-data-graphql";

import buildQuery from "./buildQuery";
import createClient from "./client";
import type { Client } from "./client";

export default async function dataProvider(): Promise<{
  dataProvider: typeof raDataProvider;
  client: Client;
}> {
  const client = createClient();

  const dataProvider = await buildGraphQLProvider({
    buildQuery,
    client,
    introspection: false,
  });

  return { client, dataProvider };
}
