import React, { FC } from "react";
import {
  Edit,
  EditProps,
  SimpleForm,
  TextField,
  SelectInput,
  AutocompleteInput,
  Toolbar,
  ToolbarProps,
  SaveButton,
  ListButton,
  required,
  TextInput,
} from "react-admin";
import DateField from "../../fields/DateField";
import Icon from "@mui/material/Icon";

import { PermissionType, Resources } from "../../permissions";
import Labeled from "../../common/Labeled";

const EditToolbar: FC<Omit<ToolbarProps, "width">> = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton label="Cancel" icon={<Icon />} />
  </Toolbar>
);

const AccessEdit: FC<EditProps> = (props) => {
  const resourceOptions = [];
  for (const resource in Resources) {
    resourceOptions.push({
      id: Resources[resource as Resources],
      name: resource,
    });
  }

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <Labeled>
          <TextField source="id" />
        </Labeled>
        <Labeled>
          <DateField source="created" />
        </Labeled>
        <Labeled>
          <DateField source="updated" />
        </Labeled>

        <SelectInput
          validate={required()}
          source="type"
          choices={[
            { id: PermissionType.Create, name: "Create" },
            { id: PermissionType.List, name: "List" },
            { id: PermissionType.Update, name: "Update" },
          ]}
        />

        <AutocompleteInput
          source="resource"
          choices={resourceOptions}
          validate={required()}
        />

        <TextInput source="role" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

export default AccessEdit;
