import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  ReferenceField,
  BooleanField,
} from "react-admin";
import DateField from "../../fields/DateField";

const SiteErrorConfigurationTitle: any = ({ record }: any) => {
  return <span>{record ? `${record.ssuId}` : ""}</span>;
};

const SiteErrorConfigurationShow: any = (props: any) => (
  <Show title={<SiteErrorConfigurationTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="ssuId" label="SSU_ID" />
        <TextField source="proId" label="PRO_ID" />
        <TextField source="ssuState" label="SSU State" />
        <ReferenceField
          label="Customer"
          source="orgId"
          reference="Organization"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="piFirstName" label="PI First" />
        <TextField source="piLastName" label="PI Last" />

        <TextField source="investigationStatus" />
        <BooleanField source="foundByApi" />
        <TextField source="reason" />
        <TextField source="responsible" />
        <RichTextField source="investigationNotes" />

        <DateField source="created" />
        <DateField source="updated" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default SiteErrorConfigurationShow;
