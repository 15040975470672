import React, { FC } from "react";
import { Link, useRecordContext, PublicFieldProps } from "react-admin";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import * as API from "@advarra/connect-graphql/types";

const CustomersField: FC<PublicFieldProps> = () => {
  const record = useRecordContext<API.CustomerMap>();
  const link = `/OrgIntegration?filter={"customerMapId":${record.id}}`;

  return (
    <Tooltip
      placement="top"
      arrow
      title="Click to view a list of customer integrations that use this mapping"
    >
      <Typography variant="body2" component="span" align="right">
        <Link to={link}>
          {(record.customerIntegrations || []).length.toLocaleString()}
        </Link>
      </Typography>
    </Tooltip>
  );
};

export default CustomersField;
