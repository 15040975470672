import React, { FC } from "react";
import {
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceArrayInputProps,
} from "react-admin";
import * as API from "@advarra/connect-graphql/lib/types";

const CustomerIntegrationInput: FC<ReferenceArrayInputProps> = ({
  label,
  ...props
}) => {
  return (
    <ReferenceArrayInput label={label} {...props}>
      <AutocompleteArrayInput
        label={label}
        filterToQuery={(searchText: string) => ({ name: searchText })}
        optionText={(record: API.OrgIntegration) => {
          return record.name
            ? `${record.name} (${record.integrationTypeId})`
            : "";
        }}
      />
    </ReferenceArrayInput>
  );
};

export default CustomerIntegrationInput;
