import React, { FC, Fragment } from "react";
import {
  TextInput,
  PasswordInput,
  NumberInput,
  email,
  regex,
} from "react-admin";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

import { formatClientId } from "@advarra/connect-vault-api/lib/client";

import ConnectionCheckInput from "./ConnectionCheckInput";

const useStyles = makeStyles<Theme>({
  username: {
    width: "30rem",
  },
  password: {
    width: "30rem",
  },
  domain: {
    width: "30rem",
  },
  directory: {
    width: "40rem",
  },
});

const validateEmail = email();

const validateDomain = regex(/^(?!http).+\..+/, "Must be a domain name");

const validateURL = regex(/^https:\/\/.+\..+/, "Must be a https:// url");

const StandardPasswordInput: FC<any> = ({ hasPassword, ...props }) => {
  const classes = useStyles();

  return (
    <PasswordInput
      source="password"
      helperText={
        hasPassword
          ? `${props.label} present. Leave blank to leave existing credential unchanged.`
          : `${props.label} needed`
      }
      inputProps={{ autoComplete: "new-password" }}
      className={classes.password}
      {...props}
    />
  );
};

interface Props {
  integrationTypeId: string;
  hasPassword: boolean;
}

const ConnectorDetailsInput: FC<Props> = ({
  integrationTypeId,
  hasPassword,
}) => {
  const classes = useStyles();
  switch (integrationTypeId) {
    case "VV3":
      return (
        <Fragment>
          <TextInput
            disabled
            source="name"
            label="Client ID"
            helperText="The client identifier sent with API requests to Veeva Vault for this customer integration (read-only)"
            format={(value: string): string =>
              value ? formatClientId(value) : ""
            }
            style={{ width: "40em" }}
            multiline
            rows={1}
          />
          <br />
          <TextInput
            source="apiUser"
            label="Vault user name"
            placeholder="test+user@customervault.com"
            inputProps={{ autoComplete: "off" }}
            className={classes.username}
            validate={validateEmail}
          />
          <br />
          <StandardPasswordInput
            label="Vault password"
            hasPassword={hasPassword}
          />
          <br />
          <TextInput
            source="apiHostAddress"
            label="Vault domain name"
            placeholder="customervault.com"
            inputProps={{ autoComplete: "off" }}
            validate={validateDomain}
            className={classes.domain}
          />
          <br />
          <ConnectionCheckInput />
        </Fragment>
      );
    case "SFTP":
      return (
        <Fragment>
          <TextInput
            source="ftpHostAddress"
            label="SFTP domain name"
            placeholder="sftp.customer.com"
            inputProps={{ autoComplete: "off" }}
            validate={validateDomain}
            className={classes.domain}
          />
          <br />
          <NumberInput
            source="ftpPort"
            label="SFTP port"
            defaultValue={22}
            min={1}
            max={65535}
          />
          <br />
          <TextInput
            source="ftpUser"
            label="User name"
            inputProps={{ autoComplete: "off" }}
            className={classes.username}
          />
          <br />
          <StandardPasswordInput label="Password" hasPassword={hasPassword} />
          <br />
          <TextInput
            source="ftpPath"
            label="Document directory"
            inputProps={{ autoComplete: "off" }}
            className={classes.directory}
          />
          <br />
          <TextInput
            source="ftpMetadataPath"
            label="Metadata directory"
            inputProps={{ autoComplete: "off" }}
            className={classes.directory}
          />
          <br />
          <ConnectionCheckInput />
        </Fragment>
      );
    case "ER":
      return (
        <Fragment>
          <TextInput
            source="apiUser"
            label="oAuth client ID"
            inputProps={{ autoComplete: "off" }}
            className={classes.username}
          />
          <br />
          <StandardPasswordInput
            label="oAuth client secret"
            hasPassword={hasPassword}
          />
          <br />
          <TextInput
            source="apiHostAddress"
            label="eReg instance name"
            placeholder="eregcustomer.com"
            inputProps={{ autoComplete: "off" }}
            validate={validateDomain}
            className={classes.domain}
          />
          <br />
          <ConnectionCheckInput />
        </Fragment>
      );
    case "SIP":
      return (
        <Fragment>
          <TextInput
            source="apiUser"
            label="oAuth client ID"
            inputProps={{ autoComplete: "off" }}
            className={classes.username}
          />
          <br />
          <StandardPasswordInput
            label="oAuth client secret"
            hasPassword={hasPassword}
          />
          <br />
          <TextInput
            source="apiHostAddress"
            label="SIP instance name"
            placeholder="https://sipcustomer.com"
            inputProps={{ autoComplete: "off" }}
            validate={validateURL}
            className={classes.domain}
          />
          <br />
          <ConnectionCheckInput />
        </Fragment>
      );
    case "TI2":
      return (
        <Fragment>
          <TextInput
            source="apiUser"
            label="Username"
            inputProps={{ autoComplete: "off" }}
            className={classes.username}
          />
          <br />
          <StandardPasswordInput label="Password" hasPassword={hasPassword} />
          <br />
          <TextInput
            source="apiHostAddress"
            label="API Base URL"
            placeholder="https://www.trialinteractive.com/"
            inputProps={{ autoComplete: "off" }}
            validate={validateURL}
            className={classes.domain}
          />
          <br />
          <TextInput
            source="ftpHostAddress"
            label="Customer Domain"
            inputProps={{ autoComplete: "off" }}
            className={classes.domain}
          />
          <ConnectionCheckInput />
        </Fragment>
      );
    case "AC2":
      return (
        <Fragment>
          <TextInput
            source="apiHostAddress"
            label="Activate domain name"
            placeholder="subdomain.gobalto.com"
            inputProps={{ autoComplete: "off" }}
            validate={validateDomain}
            className={classes.domain}
          />
          <br />
          <StandardPasswordInput label="IRB token" hasPassword={hasPassword} />
        </Fragment>
      );
    case undefined:
      return (
        <Fragment>
          <Typography>Please select an integration type.</Typography>
          <br />
        </Fragment>
      );
    default:
      return (
        <Fragment>
          <Typography color="error">
            The selected integration type is not supported by this tool.
          </Typography>
          <br />
        </Fragment>
      );
  }
};

export default ConnectorDetailsInput;
