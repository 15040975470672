import React, { FC } from "react";
import {
  TextField,
  FunctionField,
  TextInput,
  BooleanField,
  SelectInput,
  ReferenceInput,
  Toolbar,
  SaveButton,
  ListButton,
  ReferenceManyField,
  Datagrid,
  FormDataConsumer,
  Form,
  useRecordSelection,
  useDataProvider,
  useNotify,
  useRefresh,
  usePermissions,
  useRecordContext,
  useListContext,
  TitleProps,
} from "react-admin";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";

import LinearProgress from "@mui/material/LinearProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Alert, AlertTitle } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import DateField from "../../fields/DateField";
import { PermissionType, Resources, checkPermission } from "../../permissions";
import Edit from "../Edit";
import InvestigationNotes from "../../common/InvestigationNotes";
import * as API from "@advarra/connect-graphql/lib/types";
import AuditTimeline from "../../views/AuditTrail/AuditTimeline";
import UpdatePINameCustomerCTMSConfirmationDialog from "./UpdatePINameCustomerCTMSConfirmationDialog";
import CreateNewSiteXref, { SiteXrefInput } from "./CreateNewSiteXref";
import Labeled from "../../common/Labeled";

export const SiteErrorConfigurationTitle: FC<TitleProps> = () => {
  const record = useRecordContext();
  return (
    <span>
      Unconfigured Site Detail <b>{record ? `for ${record.ssuId}` : ""}</b>
    </span>
  );
};

export const InvestigationStatus = ({
  record,
}: {
  record: API.SiteError;
}): JSX.Element => {
  if (record.investigationStatus === "FIXED") {
    return (
      <SelectInput
        resource="SiteError"
        source="investigationStatus"
        options={{ style: { minWidth: "12rem" } }}
        choices={[
          { id: "NEW", name: "NEW" },
          { id: "INVESTIGATED", name: "INVESTIGATED" },
          { id: "FIXED", name: "FIXED" },
          { id: "DO NOT FIX", name: "DO NOT FIX" },
        ]}
      />
    );
  } else {
    return (
      <SelectInput
        resource="SiteError"
        source="investigationStatus"
        options={{ style: { minWidth: "12rem" } }}
        choices={[
          { id: "NEW", name: "NEW" },
          { id: "INVESTIGATED", name: "INVESTIGATED" },
          { id: "DO NOT FIX", name: "DO NOT FIX" },
        ]}
      />
    );
  }
};

const UpdateButton = (props: {
  id: string;
  selectedIds: string[];
  piFirstName: string;
  piLastName: string;
  onComplete: () => void;
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { permissions } = usePermissions();
  const dataProvider = useDataProvider();
  const { data } = useListContext();

  const canUpdate = checkPermission(
    permissions,
    PermissionType.Update,
    Resources.CustomerCTMSRecord,
  );

  if (!canUpdate) {
    return (
      <Alert severity="warning">
        You <em>do not have permission to update</em> customer CTMS records.
      </Alert>
    );
  }

  const update = () =>
    dataProvider
      .updateMany("CustomerCTMSRecord", {
        ids: props.selectedIds,
        data: {
          relatedRecordId: props.id,
          piLastName: props.piLastName,
          piFirstName: props.piFirstName,
          processed: false,
        },
      })
      .then(() => {
        // success side effects go here

        notify("Customer CTMS Updated");
        props.onComplete();
        refresh();
      })
      .catch((error: Error) => {
        // failure side effects go here
        notify(`Customer CTMS Updated error: ${error.message}`, {
          type: "warning",
        });
      });

  const from = data
    .filter(({ id }) => props.selectedIds.includes(id))
    .map((item) => `${item.piFirstName} ${item.piLastName}`);

  return (
    <UpdatePINameCustomerCTMSConfirmationDialog
      onConfirmation={update}
      from={from}
      to={`${props.piFirstName} ${props.piLastName}`}
    />
  );
};

const NewSiteXref = (props: { record: API.SiteError }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { isLoading, permissions } = usePermissions();

  if (isLoading) {
    return (
      <Paper>
        <CircularProgress size={16} thickness={3} color="secondary" />
      </Paper>
    );
  }

  const canCreate = checkPermission(
    permissions,
    PermissionType.Create,
    Resources.SiteXref,
  );

  if (!canCreate) {
    return (
      <Paper>
        <Alert severity="warning">
          You <em>do not have permission to create</em> site xrefs.
        </Alert>
      </Paper>
    );
  }
  const createSiteXref = (input: SiteXrefInput) => {
    dataProvider
      .create("SiteXref", {
        data: {
          orgId: props.record.orgId,
          etmfId: props.record.integration.etmfId,
          siteId: props.record.siteId,
          ...input,
        },
      })
      .then(() => {
        // success side effects go here
        notify("New Site Xref Created");
      })
      .catch((error: Error) => {
        // failure side effects go here
        notify(`New Site Xref Created error: ${error.message}`, {
          type: "warning",
        });
      });
  };

  return (
    <CreateNewSiteXref record={props.record} onConfirmation={createSiteXref} />
  );
};

const PushCustomerPIListView = ({
  id,
  piFirstName,
  piLastName,
  ...props
}: any) => {
  const { isLoading, error, data } = useListContext();
  const [selectedIds, selectionModifiers] = useRecordSelection(props.resource);

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>
          Failed to load information about records to update
        </AlertTitle>
        {error.toString()}
      </Alert>
    );
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  if (data.length === 0) {
    return (
      <Alert severity="info">
        No similar PI names from Customer CTMS found.
      </Alert>
    );
  }

  const BulkActions = () => {
    return <span>{/*nothing*/}</span>;
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h4" gutterBottom>
          Similar PI names from Customer CTMS
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Datagrid
          {...props}
          bulkActionButtons={<BulkActions />}
          onToggleItem={selectionModifiers.toggle}
          onSelect={selectionModifiers.select}
          selectedIds={selectedIds}
          data-testid="similar-pi-names"
        >
          <DateField source="created" />
          <TextField source="piLastName" />
          <TextField source="piFirstName" />
          <TextField source="processed" />
          <TextField source="error" />
        </Datagrid>
      </AccordionDetails>
      <AccordionActions>
        <UpdateButton
          id={id}
          selectedIds={selectedIds as string[]}
          piLastName={piLastName}
          piFirstName={piFirstName}
          onComplete={() => selectionModifiers.clearSelection()}
        />
      </AccordionActions>
    </Accordion>
  );
};

const PushCustomerPIList = (props: any) => {
  return (
    <Paper>
      <FormDataConsumer>{PushCustomerPIListBody(props)}</FormDataConsumer>
    </Paper>
  );
};

export const PushCustomerPIListBody =
  (props: any) =>
  ({
    formData,
    ...rest
  }: {
    formData: {
      piFirstName: string;
      piLastName: string;
      id: string;
      protocolNumber: string;
    };
  }) => {
    if (formData.piLastName && formData.protocolNumber) {
      // the Protocol Number may be stale on the record if it was updated in the form
      const currentRecord = Object.assign({}, props.record, {
        protocolNumber: formData.protocolNumber,
      });
      return (
        <ReferenceManyField
          {...props}
          record={currentRecord}
          target="studyNum"
          label=""
          reference="CustomerCTMSRecord"
          source="protocolNumber"
          filter={{
            piLastName: formData.piLastName.substring(0, 3) + "%",
            orgId: props.record.orgId,
            etmfId: props.record.etmfId,
          }}
          {...rest}
        >
          <PushCustomerPIListView
            {...props}
            id={formData.id}
            piFirstName={formData.piFirstName}
            piLastName={formData.piLastName}
          />
        </ReferenceManyField>
      );
    } else if (isIntegrationPull(props.record)) {
      return (
        <Alert severity="info">
          Enter Protocol Number and PI Last to search for potential matches.
        </Alert>
      );
    } else {
      return (
        <Alert severity="warning">
          Cannot find matching CTMS records, Protocol Number or PI Last is
          blank.
        </Alert>
      );
    }
  };

const isIntegrationPull = (record: API.SiteError) =>
  record.integration?.metadataDirection === API.MetadataDirection.Pull;

export const EditableTextIfPull = (props: any) => {
  const { record, source, label, resource } = props;

  if (!record) return null;

  const isPull = isIntegrationPull(record);

  return (
    <TextInput
      disabled={!isPull}
      source={source}
      label={label}
      resource={resource}
    />
  );
};

const SiteForm = (props: any) => {
  const record = useRecordContext() as API.SiteError;

  return (
    <Form {...props}>
      <Grid container>
        <Grid item xs={6}>
          <Box p="1em">
            <Box>
              <Labeled label="SSU_ID">
                <TextField fontSize={"1rem"} source="ssuId" />
              </Labeled>
            </Box>

            <Box>
              <Labeled label="PRO_ID">
                <TextField fontSize={"1rem"} source="proId" />
              </Labeled>
            </Box>
            <Box>
              <EditableTextIfPull
                fontSize={"1rem"}
                source="protocolNumber"
                label="Protocol Number"
              />
            </Box>
            <Box>
              <Labeled label="IRB SSU State">
                <TextField fontSize={"1rem"} source="ssuState" />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Customer Integration">
                <FunctionField<API.SiteError>
                  fontSize={"1rem"}
                  sortable={true}
                  source="customerIntegration"
                  render={(record) =>
                    record && record.customerIntegration ? (
                      <span>
                        {record.customerIntegration.name} (
                        {record.customerIntegration.integrationTypeId})
                      </span>
                    ) : null
                  }
                />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Protocol Submitting Org">
                <TextField fontSize={"1rem"} source="submittingOrg" />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Site PI_ORG">
                <TextField fontSize={"1rem"} source="piOrg" />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Environment">
                <TextField fontSize={"1rem"} source="environment" />
              </Labeled>
            </Box>
            <Box>
              <EditableTextIfPull
                resource="SiteError"
                record={record}
                source="piFirstName"
                label="PI First"
              />
            </Box>
            <Box>
              <EditableTextIfPull
                resource="SiteError"
                record={record}
                source="piLastName"
                label="PI Last"
              />
            </Box>
            <Box>
              <Labeled label="Created">
                <DateField
                  fontSize={"1rem"}
                  record={record}
                  resource="SiteError"
                  source="created"
                />
              </Labeled>
            </Box>
            <Box>
              <Labeled label="Updated">
                <DateField
                  fontSize={"1rem"}
                  record={record}
                  resource="SiteError"
                  source="updated"
                />
              </Labeled>
            </Box>
            <Box>
              <InvestigationStatus record={record} />
            </Box>
            <Box>
              <Labeled label="Found by API">
                <BooleanField
                  record={record}
                  resource="SiteError"
                  source="foundByApi"
                />
              </Labeled>
            </Box>
            <Box>
              <ReferenceInput
                resource="SiteError"
                source="reason"
                reference="SiteReasonType"
              >
                <SelectInput optionText="id" />
              </ReferenceInput>
            </Box>
            <Box>
              <SelectInput
                source="responsible"
                choices={[
                  { id: "Customer", name: "Customer" },
                  { id: "Advarra", name: "Advarra" },
                ]}
              />
            </Box>
            <Box>
              <TextInput resource="SiteError" source="newInvestigationNotes" />
            </Box>
            <Box>
              <CustomSiteErrorConfigurationToolbar
                record={record}
                resource="SiteError"
                saving={props.saving}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <NewSiteXref record={record} />
            <br />
          </Box>
          <Box>
            <PushCustomerPIList
              record={record}
              resource="SiteError"
              basePath={props.basePath}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box p="1em">
            <InvestigationNotes
              text={(record && record.investigationNotes) || ""}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Accordion square defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Audit trail</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AuditTimeline
                  reference="SiteError"
                  target={record}
                ></AuditTimeline>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
      </Grid>
    </Form>
  );
};

const SiteErrorConfigurationEdit = (props: any): JSX.Element => {
  return (
    <Edit title={<SiteErrorConfigurationTitle />} {...props}>
      <SiteForm warnWhenUnsavedChanges />
    </Edit>
  );
};

export const CustomSiteErrorConfigurationToolbar = (
  props: any,
): JSX.Element => (
  <Toolbar {...props}>
    <SaveButton
      mutationOptions={{
        onSuccess: props.saving,
      }}
    />
    <ListButton resource={props.basePath} label="Cancel" icon={<Icon />} />
  </Toolbar>
);

export default SiteErrorConfigurationEdit;
