import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { withStyles, WithStyles } from "@mui/styles";
import Avatar from "../../common/Avatar";
import AuditEvent from "./AuditEvent";
import DifferencesList from "./DifferencesList";

const styles = {
  truncate: {},
  table: {},
};

interface Props extends WithStyles<typeof styles> {
  event: AuditEvent;
}

const AuditItemView = ({ event, classes }: Props) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar user={event.user} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <div className={classes.truncate}>
            <strong>{event.user ? event.user.username : "Anonymous"}</strong>{" "}
            changed
            <DifferencesList
              header={true}
              dense={false}
              before={event.before}
              after={event.after}
            />
          </div>
        }
        secondary={event.created.toLocaleString("en-US", {
          timeZoneName: "short",
        })}
      />
    </ListItem>
  );
};

const AuditItem = withStyles(styles)(AuditItemView);

export default AuditItem;
