import React, { FC } from "react";
import {
  Edit,
  SimpleForm,
  TextField,
  ReferenceField,
  SelectInput,
  Toolbar,
  SaveButton,
  ListButton,
  ArrayField,
  Datagrid,
  TextInput,
  BooleanField,
} from "react-admin";
import Icon from "@mui/material/Icon";
import DateField from "../../fields/DateField";

import { LambdaLinkField, StepFunctionLinkField } from "./fields";
import { ErrorTitleProps } from "./types";
import Labeled from "../../common/Labeled";
import SupportUserInput from "../../common/SupportUserInput";
import TicketLinkField from "./TicketLinkField";

const ErrorTitle: FC<ErrorTitleProps> = ({ record }) =>
  record ? <span>Error {record.id}</span> : null;

const ErrorToolbar: FC<any> = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <ListButton label="Cancel" icon={<Icon />} />
  </Toolbar>
);

const ErrorEdit: FC<any> = (props) => (
  <Edit {...props} title={<ErrorTitle />}>
    <SimpleForm toolbar={<ErrorToolbar />} warnWhenUnsavedChanges>
      <Labeled>
        <TextField source="id" />
      </Labeled>
      <Labeled>
        <DateField source="created" />
      </Labeled>
      <Labeled>
        <DateField source="updated" />
      </Labeled>
      <Labeled>
        <DateField source="resolved" />
      </Labeled>

      <SelectInput
        source="status"
        choices={[
          { id: "NEW", name: "NEW" },
          { id: "INVESTIGATING", name: "INVESTIGATING" },
          { id: "RESOLVED", name: "RESOLVED" },
          { id: "DO NOT FIX", name: "DO NOT FIX" },
          { id: "ON HOLD", name: "ON HOLD" },
        ]}
      />
      <Labeled>
        <TextField source="category" />
      </Labeled>
      <Labeled>
        <TextField source="sourceSys" label="Source" />
      </Labeled>
      <Labeled>
        <TextField source="message" />
      </Labeled>
      <Labeled>
        <LambdaLinkField source="lambda" />
      </Labeled>
      <Labeled>
        <StepFunctionLinkField source="stepFunction" />
      </Labeled>
      <Labeled>
        <ReferenceField
          label="Customer"
          source="orgId"
          reference="Organization"
        >
          <TextField source="name" />
        </ReferenceField>
      </Labeled>
      <Labeled>
        <TextField source="integrationId" />
      </Labeled>
      <Labeled>
        <TextField source="transactionId" />
      </Labeled>

      <Labeled>
        <TextField source="documentId" label="DOC_ID" />
      </Labeled>
      <Labeled>
        <TextField source="proId" label="PRO_ID" />
      </Labeled>
      <Labeled>
        <TextField source="ssuId" label="SSU_ID" />
      </Labeled>

      <SupportUserInput source="assignee" reference="User" />
      <Labeled>
        <TicketLinkField source="ticketNumber" label="Ticket link" />
      </Labeled>
      <Labeled>
        <TextInput
          source="ticketNumber"
          InputProps={{
            inputProps: { inputMode: "numeric", pattern: "[0-9]*" },
          }}
        />
      </Labeled>

      <Labeled>
        <TextInput source="comment" />
      </Labeled>
      <Labeled>
        <ArrayField source="comments">
          <Datagrid bulkActionButtons={false}>
            <DateField source="created" />
            <BooleanField source="reoccurance" />
            <TextField source="username" />
            <TextField source="note" />
          </Datagrid>
        </ArrayField>
      </Labeled>
    </SimpleForm>
  </Edit>
);

export default ErrorEdit;
