import { FC } from "react";
import { NullableBooleanInput, NullableBooleanInputProps } from "react-admin";

const ActiveStatusInput: FC<NullableBooleanInputProps> = (props) => (
  <NullableBooleanInput
    nullLabel="All"
    SelectProps={{
      displayEmpty: true,
    }}
    InputLabelProps={{
      shrink: true,
    }}
    {...props}
  />
);

export default ActiveStatusInput;
