import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title } from "react-admin";

export default () => (
  <Card>
    <Title title="Support App" />
    <CardContent>
      Welcome to the Advarra Connect Support Application! Explore by clicking
      any of the navigation items on the left of the page.
    </CardContent>
  </Card>
);
