import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { withStyles, WithStyles } from "@mui/styles";

import SingleAuditList from "./SingleAuditList";
import groupByDay from "./groupByDay";
import AuditEvent from "./AuditEvent";

const styles = {
  root: {
    margin: "auto",
  },
  day: {
    marginBottom: "1em",
  },
  loadingMoreIndicator: {
    marginRight: "0.5em",
  },
};

const getDayString = (date: string): string =>
  new Date(date).toLocaleDateString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

interface Props extends WithStyles<typeof styles> {
  events?: AuditEvent[];
  loading?: boolean;
  total?: number;
  handleLoadMore?: () => void;
}

const AuditTimelineLoadedView = ({
  events = [],
  handleLoadMore,
  loading,
  total,
  classes,
}: Props) => {
  const { days, eventsByDay } = groupByDay(events);
  if (days.length === 0) {
    return (
      <Typography color="error">
        Error: This list should not be empty.
      </Typography>
    );
  }
  return (
    <div className={classes.root}>
      {days.map((day) => (
        <div key={day} className={classes.day}>
          <Typography variant="subtitle1" gutterBottom>
            {getDayString(day)}
          </Typography>
          <SingleAuditList events={eventsByDay[day]} />
        </div>
      ))}
      {total !== undefined && events.length < total && (
        <Button variant="contained" onClick={handleLoadMore}>
          {loading && (
            <CircularProgress
              size={16}
              thickness={3}
              color="secondary"
              className={classes.loadingMoreIndicator}
            />
          )}
          Load more events
        </Button>
      )}
    </div>
  );
};

const AuditTimelineLoaded = withStyles(styles)(AuditTimelineLoadedView);

export default AuditTimelineLoaded;
