import React, { FC } from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  ReferenceField,
  BooleanField,
} from "react-admin";
import DateField from "../../fields/DateField";

const StudyErrorConfigurationTitle = ({ record }: any) => {
  return <span>{record ? `${record.proId}` : ""}</span>;
};

const StudyErrorConfigurationShow: FC<any> = (props: any) => (
  <Show title={<StudyErrorConfigurationTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="proId" label="PRO_ID" />
        <ReferenceField
          label="Customer"
          source="orgId"
          reference="Organization"
        >
          <TextField source="name" />
        </ReferenceField>

        <DateField source="created" label="Created Date" />
        <DateField source="updated" label="Updated Date" />
        <TextField source="projectState" label="Project State" />
        <TextField source="protocolNumber" label="Protocol Number" />
        <TextField source="submittedDate" label="Submitted Date" />
        <TextField source="companyAssignment" label="Company Assignment" />
        <TextField source="investigationStatus" label="Investigation Status" />
        <BooleanField source="foundByApi" label="Found by API" />
        <TextField source="reason" label="Error Reason" />
        <TextField source="responsible" label="Responsible Party" />

        <RichTextField
          source="investigationNotes"
          label="Investigation Notes"
        />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default StudyErrorConfigurationShow;
