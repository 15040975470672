export interface ResourceDefinition {
  name: string;
  permissionResource?: string;
  list?: string;
  show?: string;
  edit?: string;
  create?: string;
  options?: Record<string, string>;
  nested?: boolean;
  disabled?: boolean;
}

import resources from "./resources.json";

export default resources as ResourceDefinition[];
