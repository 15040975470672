import { List as ReactAdminList } from "react-admin";
import tzExporter from "../common/tzExporter";

const List: any = ReactAdminList;

List.defaultProps = {
  exporter: tzExporter,
  perPage: 25,
};

export default List;
