import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import { withStyles, WithStyles } from "@mui/styles";

const styles = {
  root: {
    margin: "auto",
  },
  content: {
    display: "flex",
  },
  icon: {
    width: 50,
    height: 50,
    paddingRight: "1em",
  },
  image: {
    width: 50,
    marginTop: "0.5em",
    display: "block",
  },
};

type Props = WithStyles<typeof styles>;

const AuditTimelineEmptyView = ({ classes }: Props) => (
  <Card className={classes.root}>
    <CardContent className={classes.content}>
      <InfoIcon color="primary" className={classes.icon} />
      <div>
        <Typography variant="subtitle1" gutterBottom>
          This area will show the actions of users on this record.
        </Typography>
      </div>
    </CardContent>
  </Card>
);

const AuditTimelineEmpty = withStyles(styles)(AuditTimelineEmptyView);

export default AuditTimelineEmpty;
