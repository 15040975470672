import * as API from "@advarra/connect-graphql/lib/types";
import { FC } from "react";
import { TextField, Datagrid, ListProps, FunctionField } from "react-admin";

import * as cron from "aws-cron-parser";

import DateField from "../../fields/DateField";
import List from "../List";

function datetimeBeforeToday(iso8601Point: string) {
  const point = new Date(iso8601Point);
  const midnightLastNight = new Date();
  midnightLastNight.setHours(0);
  midnightLastNight.setMinutes(0);
  midnightLastNight.setSeconds(0);

  return point < midnightLastNight;
}

function renderStatus(record: API.Job | undefined): string {
  if (!record) {
    return "Loading";
  }

  if (datetimeBeforeToday(record.updatedAt)) {
    return "Not Run Today";
  }

  switch (record.status) {
    case API.JobStatus.Failed:
      return "Failed";
    case API.JobStatus.Running:
      return "Running";
    case API.JobStatus.Succeeded:
      return "Succeeded";
    case API.JobStatus.NotRunToday:
      return "Not Run Today";
    default:
      return "Unknown";
  }
}

function renderSchedule(record: API.Job | undefined): string {
  if (!record || !record.schedule) {
    return "";
  }

  if (record.schedule.startsWith("cron")) {
    return cron.getScheduleDescription(
      cron.parse(
        record.schedule.slice("cron(".length, record.schedule.length - 2),
      ),
    );
  } else if (record.schedule.startsWith("rate")) {
    return `every ${record.schedule.slice(
      "rate(".length,
      record.schedule.length - 1,
    )}`;
  } else {
    return record.schedule;
  }
}

function renderNextOccurrence(record: API.Job | undefined): string {
  if (!record || !record.schedule) {
    return "";
  }

  if (record.schedule.startsWith("cron")) {
    const date = cron.next(
      cron.parse(
        record.schedule.slice("cron(".length, record.schedule.length - 1),
      ),
      new Date(),
    );

    let delta = "";
    const now = new Date();

    if (date && date > now) {
      const diffInMilliseconds = date.valueOf() - now.valueOf();
      const diffInHours = Math.floor(diffInMilliseconds / 1000 / 60 / 60);
      const diffInMinutes = (
        (diffInMilliseconds - diffInHours * 1000 * 60 * 60) /
        1000 /
        60
      ).toLocaleString(undefined, { maximumFractionDigits: 1 });

      if (diffInHours > 0) {
        delta = ` (in ${diffInHours} hours and ${diffInMinutes} minutes)`;
      } else {
        delta = ` (in ${diffInMinutes} minutes)`;
      }
    }

    return `${date?.toLocaleTimeString() || ""}${delta}`;
  } else {
    return renderSchedule(record);
  }
}

const JobList: FC<ListProps> = (props) => {
  return (
    <List {...props} title="Scheduled Jobs" pagination={false}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" sortable={false} label="Name" />
        <TextField source="description" sortable={false} />
        <FunctionField<API.Job>
          render={renderSchedule}
          source="schedule"
          label="Schedule (UTC)"
          sortable={false}
        />
        <FunctionField<API.Job>
          render={renderNextOccurrence}
          source="schedule"
          sortable={false}
          label="Next occurrence"
        />
        <FunctionField<API.Job>
          source="status"
          sortable={false}
          render={renderStatus}
        />
        <DateField source="updatedAt" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default JobList;
