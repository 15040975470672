import { FC } from "react";
import {
  ShowProps,
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  FunctionField,
  ArrayField,
  Datagrid,
  WithRecord,
  useDataProvider,
  useNotify,
  EditButton,
  useRecordContext,
  usePermissions,
} from "react-admin";
import DateField from "../../fields/DateField";
import * as API from "@advarra/connect-graphql/lib/types";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { checkPermission, Resources } from "../../permissions";

const DocumentDownloadButton: FC<{ id: string; filename: string }> = (
  props,
) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [documentId, _transactionId] = props.id.split("-");

  const downloadDocument = async () => {
    let result;
    try {
      result = await dataProvider.getOne("DocumentDownload", {
        id: documentId,
      });
    } catch {
      notify("Failed to get download information", {
        type: "error",
      });
      return;
    }

    const tempLink = document.createElement("a");
    tempLink.href = result.data.downloadUrl;
    tempLink.target = "_blank";
    tempLink.setAttribute("download", props.filename);
    tempLink.click();
  };

  return (
    <Button variant="contained" color="primary" onClick={downloadDocument}>
      Download {props.filename}
    </Button>
  );
};

const DocumentShow: FC<ShowProps> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <DateField source="created" />
        <DateField source="updated" />

        <TextField source="fileName" />

        <TextField source="source" label="Source" />

        <TextField source="protocolExternalId" />
        <TextField source="siteExternalId" />
        <TextField source="entityExternalId" />
        <TextField source="entityType" />

        <DateField source="fileCreatedDate" label="Document created on" />
        <DateField source="fileProcessedDate" label="Received by Connect on" />

        <WithRecord<API.DashboardDocument>
          label="Document"
          render={(record) => {
            if (record.s3Key) {
              return (
                <DocumentDownloadButton
                  id={record.id}
                  filename={record.fileName}
                />
              );
            } else {
              return <Typography color="error">No document present</Typography>;
            }
          }}
        />

        <h3>Additional Metadata</h3>
        <ArrayField source="additionalMetadata">
          <Datagrid bulkActionButtons={false}>
            <TextField source="key" />
            <TextField source="value" />
          </Datagrid>
        </ArrayField>

        <h3>Transactions</h3>
        <ArrayField source="transactions">
          <Datagrid
            rowClick="expand"
            expand={<TextField source="attributes" />}
            expandSingle
            bulkActionButtons={false}
          >
            <NumberField source="id" label="ID" />
            <DateField source="created" />
            <DateField source="updated" />
            <TextField source="externalId" label="Returned ID" />
            <FunctionField<API.Transaction>
              render={(data) =>
                data ? `${data.orgName} (${data.etmfName})` : ""
              }
              label="Integration"
            />
            <TextField source="status" />

            <TransactionEditButton />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export default DocumentShow;

const TransactionEditButton = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();

  const canUpdate = checkPermission(
    permissions,
    API.PermissionType.Update,
    Resources.Transaction,
  );

  if (canUpdate) {
    return <EditButton resource="Transaction" record={record} />;
  } else {
    return null;
  }
};
