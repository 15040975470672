import Amplify from "@aws-amplify/auth";

declare global {
  interface Window {
    ENV: string;
    awsParameters: {
      environmentName: string;
      graphqlUrl: string;
      userPoolId: string;
      userPoolClientId: string;
      region: string;
    };
  }
}

window.ENV = "";

export default (): void => {
  const config = window.awsParameters;
  Amplify.configure({
    aws_user_pools_id: config.userPoolId,
    aws_user_pools_web_client_id: config.userPoolClientId,
    aws_appsync_graphqlEndpoint: config.graphqlUrl,
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  });

  window.ENV = config.environmentName;
};
