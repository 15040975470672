/**
 * Map from React Admin resources to the GraphQL queries used to create/retrieve/update/delete them.
 *
 * Maps from resourceName + fetchType + parameters to
 * GraphQL Query + variables object + response parsing function.
 *
 */
import gql from "graphql-tag";
import {
  GetManyReferenceParams,
  UpdateParams,
  UpdateManyParams,
  GetOneResult,
  GetManyReferenceResult,
  UpdateResult,
  UpdateManyResult,
  CreateParams,
  CreateResult,
  RaRecord,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
} from "ra-core";

import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import * as API from "@advarra/connect-graphql/lib/types";
import {
  getOne,
  BaseResourceMapping,
  getPaginatedList,
  declareResourceMapping,
  parseGetOneResponseFactory,
  getUnpaginatedList,
  declareGetMany,
  getManyVariables,
  parseGetListResponseFactory,
  declareGetManyReference,
  declareGetList,
  getPaginatedListVariables,
  declareGetOne,
  getOneVariables,
  getBareList,
} from "./mappingTools";
import buildUpdateInputs from "./buildUpdateInputs";
import stripTypename from "./stripTypename";
import listAuditTrails from "./queries/listAuditTrails";
import getOrgIntegration from "./queries/getOrgIntegration";
import { GetManyParams } from "react-admin";
import cleanSchema from "./cleanSchema";

type Mapping = {
  [resourceName: string]: { [fetchType: string]: BaseResourceMapping };
};

export default {
  Transaction: {
    GET_ONE: declareGetOne({
      query: queries.getTransaction,
      getVariables: getOneVariables,
      parseResponse: function (response: { data: API.Query }): GetOneResult {
        if (response.data.getTransaction) {
          const { attributes, ...transaction } = response.data.getTransaction;

          const newAttributes = Object.entries(JSON.parse(attributes)).map(
            ([name, value]: [string, unknown]) => ({ name, value }),
          );

          return {
            data: {
              ...transaction,
              attributes: newAttributes,
              originalAttributes: attributes,
            } as RaRecord,
          };
        } else {
          throw new Error("Failed to retrieve record");
        }
      },
    }),
    UPDATE: declareResourceMapping<
      UpdateParams,
      API.MutationUpdateTransactionArgs,
      API.Mutation,
      UpdateResult
    >({
      query: mutations.updateTransaction,
      getVariables: ({ data, ...params }) => {
        const attributes = data.attributes;

        const newAttributes = JSON.stringify(
          Object.fromEntries(
            attributes.map(
              ({ name, value }: { name: string; value: string }) => [
                name,
                value,
              ],
            ),
          ),
        );

        delete data.attributes;
        if (newAttributes !== data.originalAttributes) {
          data.attributes = newAttributes;
        }
        delete data.originalAttributes;

        return buildUpdateInputs<API.UpdateTransactionInput>({
          data,
          ...params,
        });
      },
      parseResponse: parseGetOneResponseFactory("updateTransaction"),
    }),
  },
  DocumentDownload: {
    GET_ONE: getOne("getDocumentDownload"),
  },
  Document: {
    GET_ONE: getOne("getDocument"),
    GET_LIST: getPaginatedList("listDocuments"),
  },
  UnconfiguredAuditTrail: {
    GET_LIST: declareGetList({
      query: listAuditTrails,
      getVariables: (params) => getPaginatedListVariables(params),
      parseResponse: parseGetListResponseFactory("listAuditTrails"),
    }),
    GET_MANY_REFERENCE: declareGetManyReference<
      API.QueryListAuditTrailsArgs,
      API.Query
    >({
      query: listAuditTrails,
      getVariables: (params) => ({
        page: params.pagination.page,
        perPage: params.pagination.perPage,
        filter: {
          sourceResource: params.target as API.AuditTrailSourceResource,
          sourceKey: params.id.toString(),
        },
      }),
      parseResponse: (response) => {
        const items = response.data.listAuditTrails?.items || [];
        return {
          data: items.map((item) => ({
            ...item,
            created: item && new Date(item.created),
          })) as RaRecord[],
          total: response.data.listAuditTrails?.totalItems || 0,
        };
      },
    }),
  },
  XrefAuditTrail: {
    GET_LIST: declareGetList({
      query: listAuditTrails,
      getVariables: (params) => {
        const vars = getPaginatedListVariables(params);
        if (vars.filter.created) {
          vars.filter.created = new Date(
            `${vars.filter.created} 00:00:00`,
          ).toISOString();
        }
        vars.filter.sourceResources = [
          API.AuditTrailSourceResource.SiteXref,
          API.AuditTrailSourceResource.ProtocolXref,
        ];
        return vars;
      },
      parseResponse: parseGetListResponseFactory("listAuditTrails"),
    }),
    GET_MANY_REFERENCE: declareGetManyReference<
      API.QueryListAuditTrailsArgs,
      API.Query
    >({
      query: listAuditTrails,
      getVariables: (params) => ({
        page: params.pagination.page,
        perPage: params.pagination.perPage,
        filter: {
          sourceResource: params.target as API.AuditTrailSourceResource,
          sourceKey: params.id.toString(),
        },
      }),
      parseResponse: (response) => {
        const items = response.data.listAuditTrails?.items || [];
        return {
          data: items.map((item) => ({
            ...item,
            created: item && new Date(item.created),
          })) as RaRecord[],
          total: response.data.listAuditTrails?.totalItems || 0,
        };
      },
    }),
  },
  Error: {
    GET_ONE: getOne("getError"),
    GET_LIST: getPaginatedList("listErrors"),
    UPDATE: declareResourceMapping<
      UpdateParams,
      API.MutationUpdateErrorArgs,
      API.Mutation,
      UpdateResult
    >({
      query: mutations.updateError,
      getVariables: ({ data: { comments: _, ...data }, ...params }) =>
        buildUpdateInputs<API.UpdateErrorInput>({ data, ...params }),
      parseResponse: parseGetOneResponseFactory("updateError"),
    }),
  },
  SiteError: {
    GET_ONE: getOne("getSiteError"),
    GET_LIST: getPaginatedList("listSiteErrors"),
    UPDATE: declareResourceMapping<
      UpdateParams,
      API.MutationUpdateSiteErrorArgs,
      API.Mutation,
      UpdateResult
    >({
      query: mutations.updateSiteError,
      getVariables: ({
        data: {
          customerIntegration: _customerIntegration,
          integration: _integration,
          suggestions: _suggestions,
          ...data
        },
        ...params
      }) => buildUpdateInputs<API.UpdateSiteErrorInput>({ data, ...params }),
      parseResponse: parseGetOneResponseFactory("updateSiteError"),
    }),
  },
  StudyError: {
    GET_ONE: getOne("getUnconfiguredProtocol"),
    GET_LIST: getPaginatedList("listUnconfiguredProtocols"),
    UPDATE: declareResourceMapping<
      UpdateParams,
      API.MutationUpdateUnconfiguredProtocolArgs,
      API.Mutation,
      UpdateResult
    >({
      query: mutations.updateUnconfiguredProtocol,
      getVariables: ({
        data: {
          customerIntegration: _customerIntegration,
          integration: _integration,
          ...data
        },
        ...params
      }) => buildUpdateInputs<API.UpdateStudyErrorInput>({ data, ...params }),
      parseResponse: parseGetOneResponseFactory("updateUnconfiguredProtocol"),
    }),
  },
  SiteReasonType: {
    GET_ONE: getOne("getSiteReasonType"),
    GET_LIST: getUnpaginatedList("listSiteReasonTypes"),
    GET_MANY: declareGetMany<API.QueryListSiteReasonTypesArgs, API.Query>({
      query: queries.listSiteReasonTypes,
      getVariables: getManyVariables,
      parseResponse: parseGetListResponseFactory("listSiteReasonTypes"),
    }),
  },
  Job: {
    GET_LIST: getUnpaginatedList("listJobs"),
  },
  StudyReasonType: {
    GET_ONE: getOne("getStudyReasonType"),
    GET_LIST: getUnpaginatedList("listStudyReasonTypes"),

    GET_MANY: declareGetMany<API.QueryListStudyReasonTypesArgs, API.Query>({
      query: queries.listStudyReasonTypes,
      getVariables: getManyVariables,
      parseResponse: parseGetListResponseFactory("listStudyReasonTypes"),
    }),
  },
  IntegrationType: {
    GET_ONE: getOne("getIntegrationType"),
    GET_LIST: getUnpaginatedList("listIntegrationTypes"),
    GET_MANY: declareGetMany<API.QueryListIntegrationTypesArgs, API.Query>({
      query: queries.listIntegrationTypes,
      getVariables: getManyVariables,
      parseResponse: parseGetListResponseFactory("listIntegrationTypes"),
    }),
  },
  Organization: {
    GET_ONE: getOne("getOrganization"),
    GET_LIST: getPaginatedList("listOrganizations"),

    GET_MANY: declareGetMany<API.QueryListOrganizationsArgs, API.Query>({
      query: queries.listOrganizations,
      getVariables: (params: GetManyParams): API.QueryListOrganizationsArgs => {
        return {
          filter: { ids: params.ids as string[] },
          page: 1,
          perPage: 25,
        };
      },
      parseResponse: parseGetListResponseFactory("listOrganizations"),
    }),
  },
  OrgIntegration: {
    GET_ONE: declareGetOne({
      query: getOrgIntegration,
      getVariables: getOneVariables,
      parseResponse: parseGetOneResponseFactory("getOrgIntegration"),
    }),
    GET_MANY: declareGetMany<API.QueryListOrgIntegrationsArgs, API.Query>({
      query: queries.listOrgIntegrations,
      getVariables: (params) => {
        return { filter: { id: params.ids as string[] }, page: 1, perPage: 25 };
      },
      parseResponse: parseGetListResponseFactory("listOrgIntegrations"),
    }),
    GET_LIST: getPaginatedList("listOrgIntegrations"),
    UPDATE: declareResourceMapping<
      UpdateParams,
      API.MutationUpdateOrgIntegrationArgs,
      API.Mutation,
      UpdateResult
    >({
      query: mutations.updateOrgIntegration,
      getVariables: ({
        data: {
          integration: _integration,
          connectionValid: _connectionValid,
          ...data
        },
        ...params
      }) => {
        /**
         * repackage metadataVQLPull so that we can leave out any __typename keys in the data
         * since we can't send those as part of the VqlPullMetadataInput
         */
        let metadataVQLPull: API.VqlPullMetadataInput | undefined;
        if (data.metadataVQLPull) {
          metadataVQLPull = {
            protocolMapping: data.metadataVQLPull.protocolMapping.map(
              ({ outputField, vqlField }: any) => ({ outputField, vqlField }),
            ),
            protocolVQL: data.metadataVQLPull.protocolVQL ?? "",
            siteMapping: data.metadataVQLPull.siteMapping.map(
              ({ outputField, vqlField }: any) => ({ outputField, vqlField }),
            ),
            siteVQL: data.metadataVQLPull.siteVQL ?? "",
            protocolLastModifiedTimestamp:
              data.metadataVQLPull.protocolLastModifiedTimestamp,
            siteLastModifiedTimestamp:
              data.metadataVQLPull.siteLastModifiedTimestamp,
            sitePINameMatching: data.metadataVQLPull.sitePINameMatching,
            enableVQLProtocolQuery: data.metadataVQLPull.enableVQLProtocolQuery,
            enableVQLSiteQuery: data.metadataVQLPull.enableVQLSiteQuery,
          };
        }

        let entityMatchRules:
          | Array<API.CustomerEntityMatchRuleInput>
          | undefined;
        if (data.entityMatchRules) {
          entityMatchRules = data.entityMatchRules.map(
            ({ type, value }: API.CustomerEntityMatchRuleInput) => ({
              type,
              value,
            }),
          );
        }

        return buildUpdateInputs<API.UpdateOrgIntegrationInput>({
          data: { ...data, metadataVQLPull, entityMatchRules },
          ...params,
        });
      },
      parseResponse: parseGetOneResponseFactory("updateOrgIntegration"),
    }),
    CREATE: declareResourceMapping<
      CreateParams,
      API.MutationCreateOrgIntegrationArgs,
      API.Mutation,
      CreateResult
    >({
      query: mutations.createOrgIntegration,
      getVariables: ({ data: { connectionValid: _, ...data }, ..._params }) => {
        if (data.metadataVQLPull) {
          // These fields should not be null, but React Admin might be setting them to be so.
          // Make sure they're empty strings.
          data.metadataVQLPull.protocolVQL ??= "";
          data.metadataVQLPull.siteVQL ??= "";
        }

        return {
          input: data,
        };
      },
      parseResponse: parseGetOneResponseFactory("createOrgIntegration"),
    }),
  },
  CustomerCTMSRecord: {
    GET_LIST: getUnpaginatedList("listCustomerCtmsRecords"),
    GET_MANY: declareGetMany<API.QueryListCustomerCtmsRecordsArgs, API.Query>({
      query: queries.listCustomerCtmsRecords,
      getVariables: getManyVariables,
      parseResponse: function (response) {
        if (response.data.listCustomerCTMSRecords) {
          const items = response.data.listCustomerCTMSRecords
            .items as RaRecord[];
          return {
            data: items,
            total: items.length,
          };
        } else {
          throw new Error("Failed to retrieve record");
        }
      },
    }),
    GET_MANY_REFERENCE: declareResourceMapping<
      GetManyReferenceParams,
      API.QueryListCustomerCtmsRecordsArgs,
      API.Query,
      GetManyReferenceResult
    >({
      query: queries.listCustomerCtmsRecords,
      getVariables: (params) => ({
        [params.target]: params.id.toString(),
        filter: params.filter,
      }),
      parseResponse: function (response) {
        if (response.data.listCustomerCTMSRecords) {
          const items = response.data.listCustomerCTMSRecords
            .items as RaRecord[];
          return {
            data: items,
            total: items.length,
          };
        } else {
          throw new Error("Failed to retrieve record");
        }
      },
    }),
    UPDATE_MANY: declareResourceMapping<
      UpdateManyParams,
      {
        [key: string]:
          | API.MutationUpdateCustomerCtmsRecordArgs
          | API.MutationUpdateSiteErrorArgs;
      },
      {
        [key: string]: API.Mutation;
      },
      UpdateManyResult
    >({
      getQuery: (params) => {
        const ids: string[] = (params.ids as string[]) || [];
        const mutationArguments = ids
          .map((id: string) => `$m${id}: UpdateCustomerCTMSRecordInput!`)
          .join(",");

        const idQuery = ids
          .map(
            (id: string) =>
              `m${id}: updateCustomerCTMSRecord(input: $m${id}) { id }`,
          )
          .join("\n");

        return gql`
          mutation UpdateManyCTMSRecords(${mutationArguments}, $siteErrorInput: UpdateSiteErrorInput!) {
            ${idQuery}

            updateSiteError(input: $siteErrorInput) {
              id
            }
          }
        `;
      },
      getVariables: (params) => {
        const generateVariable = (id: string): { [key: string]: any } => ({
          [`m${id}`]: {
            id,
            piFirstName: params.data.piFirstName,
            piLastName: params.data.piLastName,
            processed: params.data.processed,
          },
        });

        const baseVariables: { [key: string]: API.UpdateSiteErrorInput } = {
          siteErrorInput: {
            id: params.data.relatedRecordId,
            investigationStatus: "INVESTIGATED",
            newInvestigationNotes: "PI Name Changed in Customer CTMS Table",
          },
        };

        const ids: string[] = (params.ids as string[]) || [];
        return ids.reduce((vars: { [key: string]: unknown }, id: string) => {
          return { ...vars, ...generateVariable(id) };
        }, baseVariables);
      },

      parseResponse: (response: { data: any }) => {
        return {
          data: Object.values(response.data).map((o: any) => o.id),
        };
      },
    }),
  },
  ConnectionTest: {
    CREATE: declareResourceMapping<
      CreateParams,
      API.MutationCreateConnectionTestArgs,
      API.Mutation,
      CreateResult
    >({
      query: mutations.createConnectionTest,
      getVariables: ({ data, ..._params }) => ({
        input: data,
      }),
      parseResponse: (response) => {
        if (response.data["createConnectionTest"]) {
          return {
            data: {
              id: 123, // create method checks to see if an 'id' is present
              ...response.data["createConnectionTest"],
            },
          };
        } else {
          throw new Error("Failed to test connection");
        }
      },
    }),
  },
  SiteXref: {
    GET_ONE: getOne("getSiteXref"),
    GET_LIST: getPaginatedList("listSiteXrefs", (vars) => {
      if (vars?.filter?.lastScheduledDate) {
        vars.filter.lastScheduledDate = new Date(
          `${vars.filter.lastScheduledDate} 00:00:00`,
        ).toISOString();
      }
      return vars;
    }),
    CREATE: declareResourceMapping<
      CreateParams,
      API.MutationCreateSiteXrefArgs,
      API.Mutation,
      CreateResult
    >({
      query: mutations.createSiteXref,
      getVariables: ({ data, ..._params }) => ({
        input: data,
      }),
      parseResponse: parseGetOneResponseFactory("createSiteXref"),
    }),
  },
  ProXref: {
    GET_ONE: getOne("getProXref"),
    GET_LIST: getPaginatedList("listProXrefs", (vars) => {
      if (vars?.filter?.lastScheduledDate) {
        vars.filter.lastScheduledDate = new Date(
          `${vars.filter.lastScheduledDate} 00:00:00`,
        ).toISOString();
      }
      return vars;
    }),
    CREATE: declareResourceMapping<
      CreateParams,
      API.MutationCreateProXrefArgs,
      API.Mutation,
      CreateResult
    >({
      query: mutations.createProXref,
      getVariables: ({ data, ..._params }) => ({
        input: data,
      }),
      parseResponse: parseGetOneResponseFactory("createProXref"),
    }),
  },
  Permission: {
    GET_LIST: getPaginatedList("listPermissions"),
    GET_ONE: getOne("getPermission"),
    CREATE: declareResourceMapping<
      CreateParams,
      API.MutationCreateSiteXrefArgs,
      API.Mutation,
      CreateResult
    >({
      query: mutations.createPermission,
      getVariables: ({ data, ..._params }) => ({
        input: data,
      }),
      parseResponse: parseGetOneResponseFactory("createPermission"),
    }),
    DELETE: declareResourceMapping<
      DeleteParams,
      API.MutationDeletePermissionsArgs,
      API.Mutation,
      DeleteResult
    >({
      query: mutations.deletePermissions,
      getVariables: ({ id, ..._params }) => ({
        input: { ids: [id] as string[] },
      }),
      parseResponse: (response) => {
        if (response.data.deletePermissions.ids) {
          return {
            data: { id: response.data.deletePermissions.ids[0] },
          };
        } else {
          throw new Error("Failed to delete records");
        }
      },
    }),
    DELETE_MANY: declareResourceMapping<
      DeleteManyParams,
      API.MutationDeletePermissionsArgs,
      API.Mutation,
      DeleteManyResult
    >({
      query: mutations.deletePermissions,
      getVariables: ({ ids, ..._params }) => ({
        input: { ids: ids as string[] },
      }),
      parseResponse: (response) => {
        if (response.data.deletePermissions.ids) {
          return {
            data: response.data.deletePermissions.ids,
          };
        } else {
          throw new Error("Failed to delete records");
        }
      },
    }),
    UPDATE: declareResourceMapping<
      UpdateParams,
      API.MutationUpdatePermissionArgs,
      API.Mutation,
      UpdateResult
    >({
      query: mutations.updatePermission,
      getVariables: ({ data: { comments: _, ...data }, ...params }) =>
        buildUpdateInputs<API.UpdatePermissionInput>({ data, ...params }),
      parseResponse: parseGetOneResponseFactory("updatePermission"),
    }),
  },
  Role: {
    GET_LIST: getBareList("roles"),
    GET_MANY: declareResourceMapping({
      query: queries.roles,
      getVariables: () => ({}),
      parseResponse: function (response: { data: { roles: API.Role[] } }) {
        if (response.data.roles) {
          const items = response.data.roles;
          return {
            data: items,
            total: items.length,
          };
        } else {
          throw new Error("Failed to retrieve record");
        }
      },
    }),
  },
  Mapping: {
    CREATE: declareResourceMapping<
      CreateParams,
      API.MutationCreateMappingArgs,
      API.Mutation,
      CreateResult
    >({
      query: mutations.createMapping,
      getVariables: ({
        data: {
          description,
          integrationType,
          name,
          reference,
          schemas,
          customerIntegrationId,
        },
        ...params
      }) => {
        return {
          input: {
            name,
            description,
            reference,
            customerIntegrationId,
            schemas: schemas.map(cleanSchema),
            integrationType:
              integrationType === undefined
                ? undefined
                : integrationType.id
                  ? stripTypename(integrationType)
                  : null,
          },
          ...params,
        };
      },
      parseResponse: function (response: {
        data: API.Mutation;
      }): GetOneResult & { warning: string | null } {
        if (response.data.createMapping) {
          return {
            data: response.data.createMapping.mapping as RaRecord,
            warning: response.data.createMapping.warning || null,
          };
        } else {
          throw new Error("Failed to retrieve record");
        }
      },
    }),
    UPDATE: declareResourceMapping<
      UpdateParams,
      API.MutationUpdateMappingArgs,
      API.Mutation,
      UpdateResult
    >({
      query: mutations.updateMapping,
      getVariables: ({
        data: {
          changeComment,
          id,
          description,
          integrationType,
          name,
          reference,
          schemas,
        },
        ...params
      }) => {
        return buildUpdateInputs<API.UpdateMappingInput>({
          data: {
            id,
            changeComment,
            name,
            description,
            reference,
            schemas: schemas.map(cleanSchema),
            integrationType:
              integrationType === undefined
                ? undefined
                : integrationType.id
                  ? stripTypename(integrationType)
                  : null,
          },
          ...params,
        });
      },
      parseResponse: function (response: { data: API.Mutation }): GetOneResult {
        if (response.data.updateMapping) {
          return {
            data: response.data.updateMapping.mapping as RaRecord,
          };
        } else {
          throw new Error("Failed to retrieve record");
        }
      },
    }),
    GET_ONE: getOne("getCustomerMap"),
    GET_LIST: getPaginatedList("listCustomerMaps"),
    GET_MANY: declareGetMany<API.QueryListCustomerMapsArgs, API.Query>({
      query: queries.listCustomerMaps,
      getVariables: (parameters) => ({
        filter: { id: parameters.ids as string[] },
      }),
      parseResponse: parseGetListResponseFactory("listCustomerMaps"),
    }),
  },
  CountryMap: {
    GET_MANY: declareGetMany<API.QueryListCountryMapsArgs, API.Query>({
      query: queries.listCountryMaps,
      getVariables: (parameters) => ({
        filter: { id: parameters.ids as string[] },
      }),
      parseResponse: parseGetListResponseFactory("listCountryMaps"),
    }),
    GET_LIST: getPaginatedList("listCountryMaps"),
  },
  User: {
    GET_MANY: getBareList("listSupportUsers"),
    GET_LIST: getBareList("listSupportUsers"),
  },
} as Mapping;
