import React, { Component } from "react";
import { Loading } from "react-admin";
import { raDataProvider } from "ra-data-graphql";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import buildDataProvider from "./dataProvider";
import type { Client as GraphQLClient } from "./dataProvider/client";
import Admin from "./Admin";

type AppState = {
  dataProvider: typeof raDataProvider | null;
  graphqlClient: GraphQLClient | null;
};

type Props = unknown;

class App extends Component<Props, AppState> {
  constructor(props: Props) {
    super(props);
    this.state = { dataProvider: null, graphqlClient: null };
  }

  componentDidMount(): void {
    buildDataProvider()
      .then(({ dataProvider, client }) =>
        this.setState({ dataProvider, graphqlClient: client }),
      )
      .catch((error) => {
        this.setState({
          dataProvider: function () {
            throw error;
          },
        });
      });
  }

  render(): JSX.Element {
    const { dataProvider } = this.state;

    if (!dataProvider) {
      return (
        <Loading
          loadingPrimary="...loading"
          loadingSecondary="Calculating Inverse Probability Matrices..."
        />
      );
    }

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Admin dataProvider={dataProvider} />
      </LocalizationProvider>
    );
  }
}
export default App;
