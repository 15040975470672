import React, { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { green } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { useWatch } from "react-hook-form";
import { useDataProvider, useInput } from "react-admin";
import { hasAllInputs, getInputFields } from "./connectionDetails";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ConnectionCheckInput = ({}) => {
  const validate = (value: any) => {
    return value === "true" || value === undefined ? undefined : value;
  };
  const { field } = useInput({
    source: "connectionValid",
    validate,
  });

  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const classes = useStyles();

  const buttonClassname = success ? classes.buttonSuccess : "";

  const formValues = useWatch();

  const handleChange = useCallback(async () => {
    setLoading(true);
    try {
      const inputs = getInputFields(formValues.integrationTypeId);
      const parameters = {
        data: {
          integrationTypeId: formValues.integrationTypeId,
          password: formValues.password,
          ...Object.fromEntries(
            inputs.map((input) => [input, formValues[input]]),
          ),
        },
      };
      const result = await dataProvider.create("ConnectionTest", parameters);
      if (result.data.success) {
        field.onChange("true");
      } else {
        field.onChange(
          `Connection test failed: ${result.data.error || "Unknown cause"}`,
        );
      }
      setSuccess(result.data.success);
    } finally {
      setLoading(false);
    }
  }, [field, formValues, dataProvider]);

  const hasPasswordInput =
    formValues.password !== undefined && formValues.password !== "";

  return (
    <div className={classes.root}>
      <div
        className={classes.wrapper}
        title={
          !hasPasswordInput
            ? "You must enter the credential above to do a connection test, including the password/secret."
            : ""
        }
      >
        <input {...field} name="connectionValid" type="hidden" />
        {field.value !== "true" && field.value !== undefined && (
          <Typography color="error">{field.value}</Typography>
        )}
        <Button
          className={buttonClassname}
          variant="contained"
          onClick={handleChange}
          disabled={loading || !hasAllInputs(formValues) || !hasPasswordInput}
        >
          {success ? "Connection test successful" : "Test connection"}
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
};
export default ConnectionCheckInput;
