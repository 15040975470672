import * as API from "@advarra/connect-graphql/lib/types";

export default function cleanSchema(
  schema: API.OrgSchemaInput,
): API.OrgSchemaInput {
  return {
    name: schema.name,
    description: schema.description,
    precedence: schema.precedence,
    type: schema.type,
    fields: (schema.fields || [])
      .map(
        (field) =>
          field && {
            source: field.source,
            sourceType: field.sourceType,
            destinationFieldName: field.destinationFieldName,
          },
      )
      .filter((f) => f),
    rules: (schema.rules || [])
      .map(
        (rule) =>
          rule && {
            fieldName: rule.fieldName,
            comparisonOperator: rule.comparisonOperator,
            value: rule.value,
          },
      )
      .filter((f) => f),
  };
}
