import React, { FC } from "react";

import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles<Theme>((theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
}));

interface AddButtonProps {
  className: string;
  label: string;
  onClick: () => void;
}

const AddButton: FC<AddButtonProps> = (props) => {
  const classes = useStyles(props);
  return (
    <Button size="small" {...props} onClick={props.onClick}>
      <AddIcon className={classes.leftIcon} />
      {props.label && ` ${props.label}`}
    </Button>
  );
};

export default AddButton;
