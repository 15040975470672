import React, { FC } from "react";
import {
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceArrayInputProps,
} from "react-admin";
import * as API from "@advarra/connect-graphql/lib/types";

const CustomerInput: FC<ReferenceArrayInputProps> = ({ label, ...props }) => {
  return (
    <ReferenceArrayInput label={label} {...props}>
      <AutocompleteArrayInput
        label={label}
        optionText={(record: API.Organization) => {
          return record.name || "";
        }}
        filterToQuery={(searchText: string) => ({ name: searchText })}
      />
    </ReferenceArrayInput>
  );
};

export default CustomerInput;
