import React from "react";
import PropTypes from "prop-types";

import DifferencesList from "./DifferencesList";

interface Props {
  record?: { [key: string]: any };
  label?: string;
  dense?: boolean;
}

const DifferencesField = ({ record = {}, dense = false }: Props) => (
  <DifferencesList
    header={false}
    dense={dense}
    before={record.before}
    after={record.after}
  />
);

DifferencesField.propTypes = {
  record: PropTypes.object,
  dense: PropTypes.bool,
};

export default DifferencesField;
