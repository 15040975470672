import { BooleanField } from "ra-ui-materialui";
import { FC } from "react";
import {
  TextField,
  SelectInput,
  TextInput,
  DateInput,
  Datagrid,
  Filter,
  FunctionField,
  ListProps,
  usePermissions,
} from "react-admin";
import { StudyError } from "@advarra/connect-graphql/lib/types";
import DateField from "../../fields/DateField";
import List from "../List";
import { getPermissions, Resources, PermissionStatus } from "../../permissions";
import CustomerIntegrationInput from "../../common/CustomerIntegrationInput";
import ActiveStatusInput from "../../common/ActiveStatusInput";

export const ListFilter = (props: JSX.IntrinsicAttributes): JSX.Element => (
  <Filter {...props}>
    <SelectInput
      label="Investigation Status"
      source="investigationStatus"
      options={{ style: { minWidth: "12rem" } }}
      alwaysOn
      choices={[
        { id: "NEW", name: "New" },
        { id: "FIXED", name: "Fixed" },
        { id: "INVESTIGATED", name: "Investigated" },
        { id: "DO NOT FIX", name: "Do not fix" },
      ]}
    />
    <ActiveStatusInput
      label="Is Active"
      source="isActive"
      alwaysOn
      trueLabel="Active"
      falseLabel="Inactive"
    />
    <CustomerIntegrationInput
      label="Customer Integration"
      source="customerIntegration"
      reference="OrgIntegration"
    />
    <TextInput label="PRO_ID" source="proId" />
    <TextInput label="Customer Protocol Number" source="protocolNumber" />
    <TextInput source="submittingOrg" label="Protocol Submitting Org" />
    <TextInput source="projectState" label="IRB Project State" />
    <DateInput source="updated" label="Updated" />
    <SelectInput
      label="Found By Api"
      source="foundByApi"
      choices={[
        { id: "TRUE", name: "TRUE" },
        { id: "FALSE", name: "FALSE" },
        { id: "NULL", name: "NULL" },
      ]}
    />
  </Filter>
);

export const StudyErrorConfigurationList: FC<ListProps> = (props) => {
  const { permissions } = usePermissions();
  const { canUpdate } = getPermissions(
    permissions,
    Resources.UnconfiguredProtocol,
  );

  return (
    <List
      {...props}
      filters={<ListFilter />}
      sort={{ field: "created", order: "DESC" }}
      title="Unconfigured Protocols"
      filterDefaultValues={{
        investigationStatus: "NEW",
        isActive: true,
      }}
      aside={
        <PermissionStatus
          canCreate={true}
          canUpdate={canUpdate}
          resourceNamePlural="unconfigured protocols"
          resourceName="unconfigured protocol"
        />
      }
    >
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="proId" label="PRO_ID" sortable={false} />
        <BooleanField source="isActive" label="Is Active" />
        <TextField
          source="projectState"
          label="IRB Project State"
          sortable={true}
        />
        <FunctionField<StudyError>
          sortable={true}
          source="customerIntegration"
          label="Customer Integration"
          render={(record?: StudyError) =>
            record && record.customerIntegration ? (
              <span>
                {record.customerIntegration.name} (
                {record.customerIntegration.integrationTypeId})
              </span>
            ) : null
          }
        />
        <TextField
          label="Customer Protocol Number"
          source="protocolNumber"
          sortable={false}
        />
        <TextField
          source="submittingOrg"
          label="Protocol Submitting Org"
          sortable={true}
        />
        <TextField
          source="investigationStatus"
          sortable={false}
          label="Investigation Status"
        />
        <TextField source="reason" sortable={false} />
        <TextField source="environment" label="Environment" sortable={false} />

        <DateField source="created" sortable={false} />
        <DateField source="updated" sortable={true} />
      </Datagrid>
    </List>
  );
};

export default StudyErrorConfigurationList;
