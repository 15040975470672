import React, { FC } from "react";

import Alert from "@mui/material/Alert";

import { useController, ControllerRenderProps } from "react-hook-form";
import update from "immutability-helper";

import validateSchemaEditor from "./schemaValidation";
import { Schema, SchemaChildType } from "../types";
import { SchemaType } from "@advarra/connect-graphql/lib/types";
import EditorCore from "./EditorCore";

interface SchemasEditorFieldProps {
  name: string;
}

const SchemasEditorField: FC<SchemasEditorFieldProps> = (props) => {
  const { field } = useController({
    name: props.name,
    defaultValue: [],
  });

  const error = validateSchemaEditor(field.value || []);

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <SchemasEditorFieldInner input={field} {...props} />
      {error && <Alert severity="error">{error}</Alert>}
    </>
  );
};

export default SchemasEditorField;

type SchemasEditorFieldInnerProps = SchemasEditorFieldProps & {
  input: ControllerRenderProps;
};

export class SchemasEditorFieldInner extends React.PureComponent<SchemasEditorFieldInnerProps> {
  constructor(props: SchemasEditorFieldInnerProps) {
    super(props);

    this.handleAddSchema = this.handleAddSchema.bind(this);
    this.handleCloneSchema = this.handleCloneSchema.bind(this);
    this.handleRemoveSchema = this.handleRemoveSchema.bind(this);
    this.handleChangeSchema = this.handleChangeSchema.bind(this);
    this.handleAddElement = this.handleAddElement.bind(this);
    this.handleCloneElement = this.handleCloneElement.bind(this);
    this.handleChangeElement = this.handleChangeElement.bind(this);
    this.handleRemoveElement = this.handleRemoveElement.bind(this);
  }

  nextPrecedence(fromPrecedence?: number): number {
    const schemas: Schema[] = this.props.input.value || [];

    if (fromPrecedence !== undefined) {
      // If fromPrecedence provided, find the next highest precedence.
      const usedPrecedences = new Set(
        schemas.map((schema) => schema.precedence),
      );
      let x = fromPrecedence + 1;
      while (usedPrecedences.has(x)) {
        x++;
      }
      return x;
    }

    // otherwise, find the highest precedence and add one to it.
    //First schema to be added, Math.Max will result in -Infinity if schemas array is empty
    return (
      Math.max(0, ...schemas.map((schema: Schema) => schema.precedence || 0)) +
      1
    );
  }

  handleAddSchema(): void {
    const schemas: Schema[] = this.props.input.value;
    const newSchemas = schemas.concat([
      {
        __typename: "MappingSchema",
        id: generateRandomId(),
        created: new Date().toISOString(),
        name: "",
        description: "",
        precedence: this.nextPrecedence(),
        type: SchemaType.Include,
        fields: [],
        rules: [],
      },
    ]);
    newSchemas.sort((a, b) => a.precedence - b.precedence);
    this.props.input.onChange(newSchemas);
  }

  handleCloneSchema(index: number): void {
    const schemas: Schema[] = this.props.input.value;
    const copy = { ...schemas[index] };
    copy.precedence = this.nextPrecedence(schemas[index].precedence);
    copy.id = generateRandomId();

    const newSchemas = schemas.concat(copy);
    newSchemas.sort((a, b) => a.precedence - b.precedence);
    this.props.input.onChange(newSchemas);
  }

  handleRemoveSchema(index: number): void {
    const schemas: Schema[] = this.props.input.value;
    this.props.input.onChange(update(schemas, { $splice: [[index, 1]] }));
  }

  handleChangeSchema(
    index: number,
    fieldName: string,
    fieldValue: null | string | number,
  ): void {
    const schemas: Schema[] = this.props.input.value;
    const newSchemas = update(schemas, {
      [index]: { [fieldName]: { $set: fieldValue } },
    });
    newSchemas.sort((a, b) => a.precedence - b.precedence);
    this.props.input.onChange(newSchemas);
  }

  handleAddElement(schemaIndex: number, type: SchemaChildType): void {
    const schemas: Schema[] = this.props.input.value;

    this.props.input.onChange(
      update(schemas, {
        [schemaIndex]: {
          [type === "field" ? "fields" : "rules"]: {
            $push: [
              type === "field"
                ? {
                    __typename: "SchemaField",
                    id: generateRandomId(),
                    source: "",
                    destinationFieldName: "",
                    sourceType: "ATTRIBUTE",
                  }
                : {
                    __typename: "SchemaRule",
                    id: generateRandomId(),
                    fieldName: "",
                    comparisonOperator: "",
                    value: "",
                  },
            ],
          },
        },
      }),
    );
  }

  handleCloneElement(
    schemaIndex: number,
    type: SchemaChildType,
    index: number,
  ): void {
    const schemas: Schema[] = this.props.input.value;

    const childType = type === "field" ? "fields" : "rules";
    const clonedValue = schemas[schemaIndex]![childType]![index];
    clonedValue.id = generateRandomId();

    this.props.input.onChange(
      update(schemas, {
        [schemaIndex]: {
          [childType]: {
            $push: [clonedValue],
          },
        },
      }),
    );
  }

  handleChangeElement(
    schemaIndex: number,
    type: SchemaChildType,
    index: number,
    fieldName: string,
    value: null | string | number,
  ): void {
    const schemas: Schema[] = this.props.input.value;
    const childType = type === "field" ? "fields" : "rules";

    this.props.input.onChange(
      update(schemas, {
        [schemaIndex]: {
          [childType]: { [index]: { [fieldName]: { $set: value } } },
        },
      }),
    );
  }

  handleRemoveElement(
    schemaIndex: number,
    type: SchemaChildType,
    index: number,
  ): void {
    const schemas: Schema[] = this.props.input.value || [];
    const childType = type === "field" ? "fields" : "rules";

    this.props.input.onChange(
      update(schemas, {
        [schemaIndex]: {
          [childType]: { $splice: [[index, 1]] },
        },
      }),
    );
  }

  render(): JSX.Element {
    const schemas: Schema[] = this.props.input.value || [];

    return (
      <EditorCore
        schemas={schemas}
        onAddSchema={this.handleAddSchema}
        onCloneSchema={this.handleCloneSchema}
        onRemoveSchema={this.handleRemoveSchema}
        onChangeSchema={this.handleChangeSchema}
        onAddElement={this.handleAddElement}
        onCloneElement={this.handleCloneElement}
        onChangeElement={this.handleChangeElement}
        onRemoveElement={this.handleRemoveElement}
      />
    );
  }
}

function generateRandomId(): string {
  return (Math.random() * -100000).toString();
}
