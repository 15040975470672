import AuditEvent from "./AuditEvent";

const sortByDate = (a: string, b: string): number =>
  new Date(b).valueOf() - new Date(a).valueOf();

const getDayForEvent = (event: AuditEvent): string => {
  const date = new Date(event.created);

  date.setHours(0, 0, 0, 0);

  return date.toISOString();
};

const groupByDay = (
  events: AuditEvent[],
): {
  days: string[];
  eventsByDay: {
    [day: string]: AuditEvent[];
  };
} => {
  const groups = events.reduce<{ [day: string]: AuditEvent[] }>(
    (days, event) => {
      const day = getDayForEvent(event);

      if (!days[day]) {
        days[day] = [];
      }
      days[day].unshift(event);
      return days;
    },
    {},
  );
  return {
    days: Object.keys(groups).sort(sortByDate),
    eventsByDay: groups,
  };
};

export default groupByDay;
