import React from "react";
import { Edit as ReactAdminEdit } from "react-admin";
import EditActions from "./EditActions";

const Edit: any = ReactAdminEdit;

Edit.defaultProps = {
  actions: <EditActions />,
};

export default Edit;
