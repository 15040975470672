import React, { FC } from "react";
import {
  Show,
  TextField,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  ShowProps,
  ShowActionsProps,
  useGetIdentity,
  BooleanField,
  useRecordContext,
} from "react-admin";
import { Button } from "@mui/material";

import DateField from "../../fields/DateField";
import { exportArtifact } from "./exportArtifact";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AvatarField from "../../fields/AvatarField";
import CustomersField from "./CustomersField";
import AttributesViewer from "./AttributesViewer";

const MappingShowActions: FC<ShowActionsProps> = (props: any) => {
  const { identity, isLoading: identityLoading } = useGetIdentity();
  const record = useRecordContext();

  const handleExportClick = (): void => {
    if (!record) {
      return;
    }
    const artifact = new Blob(
      [exportArtifact(record.schemas, identity?.username || "<unknown>")],
      {
        type: "text/html",
        endings: "native",
      },
    );
    const url = URL.createObjectURL(artifact);
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", "export.html");
    tempLink.click();
  };

  return (
    <TopToolbar className={props.className}>
      <ListButton />
      {!identityLoading && (
        <Button
          variant="contained"
          title="Export attribute mapping to HTML"
          startIcon={<CloudDownloadIcon />}
          onClick={handleExportClick}
        >
          Export
        </Button>
      )}
    </TopToolbar>
  );
};

const MappingShow: FC<ShowProps> = (props) => (
  <Show {...props} actions={<MappingShowActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <BooleanField source="reference" />
      <BooleanField source="hasActiveIntegrations" />

      <TextField label="Integration Type" source="integrationType.name" />

      <CustomersField label="Customers" sortable={false} />

      <DateField source="created" />

      <DateField source="updated" />

      <AvatarField label="Last updated by" source="lastUpdatedBy" />

      <AttributesViewer />
    </SimpleShowLayout>
  </Show>
);

export default MappingShow;
