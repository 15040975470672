import React, { FC, Fragment } from "react";
import { Create, SimpleForm, useRedirect, useNotify } from "react-admin";
import { Typography } from "@mui/material";
import FormContents from "./FormContents";
import ChangeCommentToolbar from "../../common/ChangeCommentToolbar";

export const SectionTitle = ({ label }: { label: string }) => {
  return (
    <Fragment>
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>
    </Fragment>
  );
};

const OrgIntegrationCreate: FC<any> = (props) => {
  const redirect = useRedirect();
  const notify = useNotify();

  const onSuccess = (data: any): void => {
    notify("Customer integration created", { type: "info", smart_count: 1 });
    redirect("edit", "/OrgIntegration", data.id, data);
  };

  return (
    <Create
      {...props}
      title="Create Customer Integration"
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm
        toolbar={
          <ChangeCommentToolbar create={true} label="Customer Integration" />
        }
        warnWhenUnsavedChanges
      >
        <FormContents create={true} />
      </SimpleForm>
    </Create>
  );
};

export default OrgIntegrationCreate;
