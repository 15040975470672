import React, { FC } from "react";
import {
  Datagrid,
  FilterProps,
  ListProps,
  Filter,
  DateInput,
  FunctionField,
  TextInput,
  TextField,
} from "react-admin";
import DateField from "../../../fields/DateField";
import List from "../../List";

import DifferencesField from "../DifferencesField";
import AvatarField from "../../../fields/AvatarField";
import CustomerIntegrationInput from "../../../common/CustomerIntegrationInput";

const ListFilter = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <DateInput label="Created" source="created" />

    <TextInput label="User" source="username" />

    <TextInput label="Record Type" source="sourceKey" />
    <CustomerIntegrationInput
      label="Customer Integration"
      source="customerIntegration"
      reference="OrgIntegration"
    />

    <TextInput label="PRO_ID" source="proId" />

    <TextInput label="SSU_ID" source="ssuId" />

    <TextInput label="Change Comment" source="changeComment" />
  </Filter>
);

const XrefAuditTrailList: FC<ListProps> = (props) => (
  <List
    {...props}
    filters={<ListFilter />}
    sort={{ field: "created", order: "DESC" }}
    title="Xref Audit Trail"
  >
    <Datagrid bulkActionButtons={false}>
      <FunctionField
        source="customerIntegration"
        label="Name"
        render={(record: any) =>
          record && record.customerIntegration ? (
            <span>
              {record.customerIntegration.name} (
              {record.customerIntegration.integrationTypeId})
            </span>
          ) : null
        }
      />

      <TextField label="Record Type" source="__typename" />

      <TextField label="PRO_ID" source="proId" />

      <TextField label="SSU_ID" source="ssuId" />
      <TextField label="Change Comment" source="changeComment" />
      <AvatarField label="User" source="user" sortBy="username" />
      <DateField label="created" source="created" />
      <DifferencesField label="Changes" dense={true} />
    </Datagrid>
  </List>
);

export default XrefAuditTrailList;
