import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";

export default new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: "UNION",
          name: "AuditTrail",
          possibleTypes: [
            {
              name: "CustomerCTMSRecordAuditTrail",
            },
            {
              name: "ProtocolXrefAuditTrail",
            },
            {
              name: "SiteErrorAuditTrail",
            },
            {
              name: "SiteXrefAuditTrail",
            },
            {
              name: "StudyErrorAuditTrail",
            },
          ],
        },
        {
          kind: "INTERFACE",
          name: "AuditTrailBase",
          possibleTypes: [
            {
              name: "CustomerCTMSRecordAuditTrail",
            },
            {
              name: "ProtocolXrefAuditTrail",
            },
            {
              name: "SiteErrorAuditTrail",
            },
            {
              name: "SiteXrefAuditTrail",
            },
            {
              name: "StudyErrorAuditTrail",
            },
          ],
        },
      ],
    },
  },
});
