"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SOURCES = exports.METADATA_AUTOMATION_TYPES = exports.MetadataAutomationTypes = exports.InvestigationStatus = exports.CONFIGURABLE_PROJECT_STATES = exports.ERROR_STATUS = exports.HOLD_STATUS = exports.INCOMPLETE_STATUS = exports.COMPLETED_STATUS = exports.PENDING_STATUS = exports.TransactionStatus = exports.LONGBOAT_SOURCE_NAME = exports.CIRBI_SOURCE_NAME = exports.DocumentTransactionEventSource = exports.DocumentTransactionEventDetailType = exports.DocumentTransactionEventStatus = exports.ENVIRONMENT_DETAILS = exports.Environments = void 0;
exports.Environments = {
    PRODUCTION: {
        name: "prod",
        upperEnvironmentName: null,
        options: {
            notifications: "all",
            enablePostgresReplication: true,
            scheduledJobs: true,
            useConnectorVpc: true,
            dashboardSubdomain: "support",
            apiSubdomain: "api",
            requireApprovalForDeploy: "both",
            includeMockServices: false,
            removalPolicy: "retain",
        },
    },
    STAGE: {
        name: "stage",
        upperEnvironmentName: "prod",
        options: {
            notifications: "all",
            enablePostgresReplication: true,
            scheduledJobs: false,
            useConnectorVpc: true,
            dashboardSubdomain: "stage-support",
            apiSubdomain: "stage-api",
            requireApprovalForDeploy: "single",
            includeMockServices: true,
            removalPolicy: "retain",
        },
    },
    DEMO: {
        name: "demo",
        upperEnvironmentName: null,
        options: {
            notifications: "all",
            enablePostgresReplication: false,
            scheduledJobs: false,
            useConnectorVpc: true,
            requireApprovalForDeploy: "single",
            includeMockServices: false,
            removalPolicy: "retain",
        },
    },
    QA: {
        name: "qa",
        upperEnvironmentName: "stage",
        options: {
            notifications: "dev",
            enablePostgresReplication: true,
            scheduledJobs: true,
            useConnectorVpc: false,
            requireApprovalForDeploy: "none",
            includeMockServices: false,
            removalPolicy: "destroy",
        },
    },
    DEV: {
        name: "dev",
        upperEnvironmentName: "qa",
        options: {
            notifications: "dev",
            enablePostgresReplication: true,
            scheduledJobs: false,
            useConnectorVpc: false,
            requireApprovalForDeploy: "none",
            includeMockServices: true,
            removalPolicy: "destroy",
        },
    },
    UNIT_TEST: {
        name: "unittest",
        upperEnvironmentName: "dev",
        options: {
            notifications: "all",
            enablePostgresReplication: true,
            scheduledJobs: true,
            useConnectorVpc: true,
            requireApprovalForDeploy: "none",
            includeMockServices: false,
            removalPolicy: "destroy",
        },
    },
};
exports.ENVIRONMENT_DETAILS = {
    DEV: {
        connectEnvironment: exports.Environments.DEV,
        vpcId: "vpc-02a405bfb2b4cba53",
        subnetSelection: {},
        defaultSecurityGroupId: "sg-09a375a3933365541",
        sftpCSVBucketName: "dev-metadata-csvs",
        cirbiInputBucketName: "dev-cirbi-export",
        cirbiArchiveBucketName: "dev-cirbi-archive",
        documentHoldingBucketName: "dev-cirbi-documents",
        env: {
            account: "162321063225",
            region: "us-east-1",
        },
        tenantServicesClientId: "dev",
        tenantServicesUrl: "https://u9sfl8g2d7.execute-api.us-east-1.amazonaws.com/prod/",
        longboatUrl: "https://u9sfl8g2d7.execute-api.us-east-1.amazonaws.com/prod/",
        longboatAuthTokenPath: `/irb-connect/${exports.Environments.DEV.name}/longboatAuthToken`,
        longboatCustomerMapName: "Longboat Document Exchange Attribute Map",
        eregCustomerMapName: "eReg Document Exchange Attribute Map",
    },
    QA: {
        connectEnvironment: exports.Environments.QA,
        vpcId: "vpc-0a88d3efe73b50081",
        subnetSelection: {},
        defaultSecurityGroupId: "sg-08624dc7d7c7ecdba",
        sftpCSVBucketName: "qa-metadata-csvs",
        cirbiInputBucketName: "qa-cirbi-export",
        cirbiArchiveBucketName: "qa-cirbi-archive",
        documentHoldingBucketName: "qa-cirbi-documents",
        env: {
            account: "378742897179",
            region: "us-east-1",
        },
        tenantServicesClientId: "1a645531-a2ec-419e-b928-6b5335c63b38",
        tenantServicesUrl: "https://tenant-service.advarraeng.com/",
        longboatUrl: "https://docex-qa-lb.longboat.qa.advarraeng.com/",
        longboatAuthTokenPath: `/irb-connect/${exports.Environments.QA.name}/longboatAuthToken`,
        longboatCustomerMapName: "Longboat Document Exchange Attribute Map",
        eregCustomerMapName: "eReg Document Exchange Attribute Map",
    },
    STAGE: {
        connectEnvironment: exports.Environments.STAGE,
        vpcId: "vpc-bdf04eda",
        subnetSelection: {
            subnetGroupName: "Private",
        },
        defaultSecurityGroupId: "sg-259bd55e",
        sftpCSVBucketName: "irbconnect-sftpgateway-test",
        cirbiInputBucketName: "cirb.cdwbkt.test",
        cirbiArchiveBucketName: "cirb.archivebkt.test",
        documentHoldingBucketName: "cirb.docholdbkt.test",
        env: {
            account: "162583199459",
            region: "us-east-1",
        },
        tenantServicesClientId: "f2c4d119-a842-4707-a2ae-1bf227bf85c7",
        tenantServicesUrl: "https://tenant-service.advarracloud.com/",
        longboatUrl: "https://sw-test.longboat.com/",
        longboatAuthTokenPath: `/irb-connect/${exports.Environments.STAGE.name}/longboatAuthToken`,
        longboatCustomerMapName: "Longboat Document Exchange Attribute Map",
        eregCustomerMapName: "eReg Document Exchange Attribute Map",
    },
    PRODUCTION: {
        connectEnvironment: exports.Environments.PRODUCTION,
        vpcId: "vpc-bdf04eda",
        subnetSelection: {
            subnetGroupName: "Private",
        },
        defaultSecurityGroupId: "sg-259bd55e",
        sftpCSVBucketName: "irbconnect-sftp-prod",
        cirbiInputBucketName: "cirb.cdwbkt.prod",
        cirbiArchiveBucketName: "cirb.archivebkt.prod",
        documentHoldingBucketName: "cirb.docholdbkt.prod",
        env: {
            account: "162583199459",
            region: "us-east-1",
        },
        tenantServicesClientId: "d7c39da2-0354-471f-ae4b-bcb9072cbc01",
        tenantServicesUrl: "https://tenant-service.advarracloud.com/",
        longboatUrl: "https://app.longboat.com/",
        longboatAuthTokenPath: `/irb-connect/${exports.Environments.PRODUCTION.name}/longboatAuthToken`,
        longboatCustomerMapName: "Longboat Document Exchange Attribute Map",
        eregCustomerMapName: "eReg Document Exchange Attribute Map",
    },
};
var DocumentTransactionEventStatus;
(function (DocumentTransactionEventStatus) {
    DocumentTransactionEventStatus["QUEUED"] = "queued";
    DocumentTransactionEventStatus["DELIVERED"] = "delivered";
    DocumentTransactionEventStatus["TEMPORARY_FAILURE"] = "temporary failure";
    DocumentTransactionEventStatus["PERMANENT_FAILURE"] = "failure";
})(DocumentTransactionEventStatus = exports.DocumentTransactionEventStatus || (exports.DocumentTransactionEventStatus = {}));
exports.DocumentTransactionEventDetailType = "document transition";
exports.DocumentTransactionEventSource = "com.advarra.connect";
exports.CIRBI_SOURCE_NAME = "CIRBI";
exports.LONGBOAT_SOURCE_NAME = "Longboat";
var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["PENDING"] = "Pending";
    TransactionStatus["COMPLETED"] = "Completed";
    TransactionStatus["INCOMPLETE"] = "Incomplete";
    TransactionStatus["HOLD"] = "HOLD";
    TransactionStatus["ERROR"] = "Error";
})(TransactionStatus = exports.TransactionStatus || (exports.TransactionStatus = {}));
exports.PENDING_STATUS = TransactionStatus.PENDING;
exports.COMPLETED_STATUS = TransactionStatus.COMPLETED;
exports.INCOMPLETE_STATUS = TransactionStatus.INCOMPLETE;
exports.HOLD_STATUS = TransactionStatus.HOLD;
exports.ERROR_STATUS = TransactionStatus.ERROR;
exports.CONFIGURABLE_PROJECT_STATES = [
    "Approved With No Continuing Review Required",
    "Disapproved",
    "Approved",
    "Approved - Information Needed",
    "Suspended",
    "Terminated",
    "Assigned To Meeting",
    "Board Assignment",
    "Correspondence Review",
    "Designee Review",
    "Post Review",
];
var InvestigationStatus;
(function (InvestigationStatus) {
    InvestigationStatus["New"] = "NEW";
    InvestigationStatus["Investigated"] = "INVESTIGATED";
    InvestigationStatus["Fixed"] = "FIXED";
    InvestigationStatus["DoNotFix"] = "DO NOT FIX";
})(InvestigationStatus = exports.InvestigationStatus || (exports.InvestigationStatus = {}));
var MetadataAutomationTypes;
(function (MetadataAutomationTypes) {
    MetadataAutomationTypes["ActivatePull"] = "ac-pull";
    MetadataAutomationTypes["CSVPush"] = "csv-push";
    MetadataAutomationTypes["ERegPull"] = "er-pull";
    MetadataAutomationTypes["TrialInteractivePull"] = "ti-pull";
    MetadataAutomationTypes["Manual"] = "manual";
    MetadataAutomationTypes["VaultQueryLanguagePull"] = "vql-pull";
})(MetadataAutomationTypes = exports.MetadataAutomationTypes || (exports.MetadataAutomationTypes = {}));
exports.METADATA_AUTOMATION_TYPES = [
    { id: MetadataAutomationTypes.Manual, name: "Manual" },
    { id: MetadataAutomationTypes.ActivatePull, name: "Activate (pull)" },
    { id: MetadataAutomationTypes.ERegPull, name: "eReg (pull)" },
    {
        id: MetadataAutomationTypes.TrialInteractivePull,
        name: "Trial Interactive (pull)",
    },
    { id: MetadataAutomationTypes.CSVPush, name: "Customer pushes CSV via SFTP" },
    {
        id: MetadataAutomationTypes.VaultQueryLanguagePull,
        name: "Connect pulls from Vault via VQL",
    },
];
exports.SOURCES = [
    { id: 1, name: exports.CIRBI_SOURCE_NAME },
    { id: 2, name: exports.LONGBOAT_SOURCE_NAME },
];
