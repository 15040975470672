import { FunctionField, Link } from "react-admin";

import * as API from "@advarra/connect-graphql/lib/types";

const ZENDESK_DOMAIN = "forteresearch.zendesk.com";

export default function TicketLink(props: any) {
  return (
    <FunctionField<API.Error>
      {...props}
      render={(record) =>
        record && record.ticketNumber ? (
          <Link
            target="_blank"
            to={`https://${ZENDESK_DOMAIN}/agent/tickets/${record.ticketNumber}`}
          >
            {record.ticketNumber}
          </Link>
        ) : null
      }
    />
  );
}
