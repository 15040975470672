import React, { FC, useCallback, useState } from "react";

import { ExpandRowButton, PaginationActions } from "react-admin";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";

import { Schema, SchemaChildType } from "../types";
import AddButton from "../../../common/AddButton";
import useStyles from "./useStyles";
import RuleEditor from "./RuleEditor";
import FieldEditor from "./FieldEditor";
import SchemaDescriptionEditor from "./SchemaDescriptionEditor";
import { ClassNameMap } from "@mui/styles";

interface EditorCoreProps {
  schemas: Schema[];

  onAddSchema: () => void;
  onCloneSchema: (index: number) => void;
  onRemoveSchema: (index: number) => void;
  onChangeSchema: (
    index: number,
    fieldName: string,
    value: null | string | number,
  ) => void;

  onAddElement: (schemaIndex: number, type: SchemaChildType) => void;
  onCloneElement: (
    schemaIndex: number,
    type: SchemaChildType,
    index: number,
  ) => void;
  onChangeElement: (
    schemaIndex: number,
    type: SchemaChildType,
    index: number,
    fieldName: string,
    value: null | string | number,
  ) => void;
  onRemoveElement: (
    schemaIndex: number,
    type: SchemaChildType,
    index: number,
  ) => void;
}

const EditorCore: FC<EditorCoreProps> = ({
  schemas = [],
  onCloneElement,
  onChangeElement,
  onRemoveElement,
  onAddElement,
  onCloneSchema,
  onRemoveSchema,
  onChangeSchema,
  ...props
}) => {
  const [expanded, updateExpanded] = useState<{ [key: string]: boolean }>({});

  const handleExpanded = useCallback(
    (id: string) => {
      updateExpanded((e) => ({ ...e, [id]: !e[id] }));
    },
    [updateExpanded],
  );

  const handleExpandAll = useCallback(() => {
    updateExpanded((e) => {
      const u = { ...e };
      (schemas || []).forEach(({ id }) => {
        u[id] = true;
      });
      return u;
    });
  }, [updateExpanded, schemas]);

  const handleCollapseAll = useCallback(() => {
    updateExpanded(() => ({}));
  }, [updateExpanded]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1);

  const start = page * rowsPerPage;
  const end = (page + 1) * rowsPerPage;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddSchema = () => {
    const pagesCount = (schemas.length + 1) / rowsPerPage;
    props.onAddSchema();
    setPage(pagesCount - 1);
  };

  return (
    <EditorCoreInnerWrapper
      {...{
        schemas: schemas || [],
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        start,
        end,
        expanded,
        onCloneElement,
        onChangeElement,
        onRemoveElement,
        onAddElement,
        onCloneSchema,
        onRemoveSchema,
        onChangeSchema,
        handleAddSchema,
        handleExpanded,
        handleExpandAll,
        handleCollapseAll,
      }}
    />
  );
};

export default EditorCore;

interface EditorCoreInnerProps {
  schemas: Schema[];
  page: number;
  rowsPerPage: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => void;
  start: number;
  end: number;
  expanded: Record<string, boolean>;
  onCloneElement: (
    schemaIndex: number,
    type: SchemaChildType,
    index: number,
  ) => void;
  onChangeElement: (
    schemaIndex: number,
    type: SchemaChildType,
    index: number,
    fieldName: string,
    value: null | string | number,
  ) => void;
  onRemoveElement: (
    schemaIndex: number,
    type: SchemaChildType,
    index: number,
  ) => void;
  onAddElement: (schemaIndex: number, type: SchemaChildType) => void;
  onCloneSchema: (index: number) => void;
  onRemoveSchema: (index: number) => void;
  onChangeSchema: (
    index: number,
    fieldName: string,
    value: null | string | number,
  ) => void;
  handleAddSchema: () => void;
  handleExpanded: (index: string) => void;
  handleExpandAll: () => void;
  handleCollapseAll: () => void;
}

export function EditorCoreInnerWrapper(
  props: JSX.IntrinsicAttributes & EditorCoreInnerProps,
) {
  const classes = useStyles(props);

  return <EditorCoreInner {...props} classes={classes} />;
}

export function EditorCoreInner({
  classes,
  schemas,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleAddSchema,
  start,
  end,
  expanded,
  onCloneElement,
  onChangeElement,
  onRemoveElement,
  onAddElement,
  onCloneSchema,
  onRemoveSchema,
  onChangeSchema,
  handleExpanded,
  handleExpandAll,
  handleCollapseAll,
}: EditorCoreInnerProps & { classes: ClassNameMap }): React.ReactElement<
  any,
  any
> | null {
  return (
    <>
      <div className={classes.root} data-testid="schemaEditor">
        <div className={classes.schemasPagination}>
          <Typography variant="h5" gutterBottom>
            Schemas{" "}
            <Button color="secondary" onClick={handleCollapseAll}>
              collapse all
            </Button>
            <Button color="secondary" onClick={handleExpandAll}>
              expand all
            </Button>
          </Typography>

          <TablePagination
            component="div"
            count={schemas.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions as any}
            rowsPerPageOptions={[1, 3, 5, 10]}
          />
        </div>

        {schemas.slice(start, end).map((schema, index) => (
          <IndividualSchemaEditor
            key={schema.id}
            expanded={expanded[schema.id]}
            schema={schema}
            index={index + start}
            onCloneElement={onCloneElement}
            onChangeElement={onChangeElement}
            onRemoveElement={onRemoveElement}
            onAddElement={onAddElement}
            onClone={onCloneSchema}
            onRemove={onRemoveSchema}
            onChange={onChangeSchema}
            handleExpanded={handleExpanded}
          />
        ))}

        <AddButton
          className={classes.addButton}
          label="Schema"
          onClick={handleAddSchema}
        />
      </div>
    </>
  );
}

interface IndividualSchemaEditorProps {
  schema: Schema;
  expanded: boolean;
  index: number;
  onAddElement: (schemaIndex: number, type: SchemaChildType) => void;
  onCloneElement: (
    schemaIndex: number,
    type: SchemaChildType,
    index: number,
  ) => void;
  onChangeElement: (
    schemaIndex: number,
    type: SchemaChildType,
    index: number,
    fieldName: string,
    value: string,
  ) => void;
  onRemoveElement: (
    schemaIndex: number,
    type: SchemaChildType,
    index: number,
  ) => void;

  onClone: (index: number) => void;
  onRemove: (index: number) => void;
  onChange: (
    index: number,
    fieldName: string,
    value: null | string | number,
  ) => void;
  handleExpanded: (index: string) => void;
}

const IndividualSchemaEditor: FC<IndividualSchemaEditorProps> = React.memo(
  ({
    schema,
    expanded,
    handleExpanded,
    index: schemaIndex,
    onChange,
    onClone,
    onRemove,
    ...props
  }) => {
    const classes = useStyles(props);

    const isExclude = schema.type === "Exclude";

    return (
      <Card>
        <CardContent>
          <SchemaDescriptionEditor
            precedence={schema.precedence}
            name={schema.name}
            description={schema.description || ""}
            type={schema.type}
            index={schemaIndex}
            onChange={onChange}
            onClone={onClone}
            onRemove={onRemove}
          />
          <ExpandRowButton
            classes={classes}
            expanded={expanded}
            onClick={() => handleExpanded(schema.id)}
            expandContentId={`${schemaIndex}-expand`}
          />
          {!expanded && (
            <span onClick={() => handleExpanded(schema.id)}>
              Rules and Fields
            </span>
          )}

          {expanded && (
            <div className={classes.schemaContents}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Rules
                  </Typography>
                  {schema.rules!.map((rule, ruleIndex) => (
                    <RuleEditor
                      key={`rule-${rule.id}`}
                      rule={rule}
                      schemaIndex={schemaIndex}
                      index={ruleIndex}
                      onChange={props.onChangeElement}
                      onClone={props.onCloneElement}
                      onRemove={props.onRemoveElement}
                    />
                  ))}
                  <AddButton
                    className={classes.addButton}
                    label="Rule"
                    onClick={(): void =>
                      props.onAddElement(schemaIndex, "rule")
                    }
                  />
                </CardContent>
              </Card>

              {!isExclude && (
                <Card>
                  <CardContent>
                    <Typography variant="h5" gutterBottom>
                      Fields
                    </Typography>
                    {schema.fields!.map((field, fieldIndex) => (
                      <FieldEditor
                        key={`field-${field.id}`}
                        schemaIndex={schemaIndex}
                        field={field}
                        index={fieldIndex}
                        onChange={props.onChangeElement}
                        onClone={props.onCloneElement}
                        onRemove={props.onRemoveElement}
                      />
                    ))}
                    <AddButton
                      className={classes.addButton}
                      label="Field"
                      onClick={(): void =>
                        props.onAddElement(schemaIndex, "field")
                      }
                    />
                  </CardContent>
                </Card>
              )}
            </div>
          )}
        </CardContent>
      </Card>
    );
  },
);
